import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import useWebSocket from '../../hooks/useWebSocket';
import ParagraphInput from '../forms/ParagraphInput';
import Grid from '../pipelines/grid/DynamicGrid';
import DynamicTable from './table/DynamicTable';
import DynamicCard from '../pipelines/grid/DynamicCard';
import Selector from '../pipelines/Selector';
import GenerateButton from '../pipelines/GenerateButton';
import { useGetUIComponentsByBlockQuery } from '../../slices/pipelines/uiComponentSlice';

const isEmptyArray = (arr) => {
  if (!Array.isArray(arr)) return false;
  for (let item of arr) {
      if (Array.isArray(item) && isEmptyArray(item)) continue;
      if (item !== undefined && item !== null) return false;
  }
  return true;
};

const removeEmptyArrays = (value) => {
  if (Array.isArray(value)) {
      return value
          .map(item => removeEmptyArrays(item))
          .filter(item => !(Array.isArray(item) && isEmptyArray(item)) && (item !== undefined && item !== null));
  }
  if (typeof value === 'object' && value !== null) {
      const newObj = {};
      for (let key in value) {
          const newValue = removeEmptyArrays(value[key]);
          if (newValue !== undefined && newValue !== null && !(Array.isArray(newValue) && isEmptyArray(newValue))) {
              newObj[key] = newValue;
          }
      }
      return newObj;
  }
  return value;
};

const DynamicSection = ({ pipelineInstance, models, block, handleUpdate }) => {
  const socket = useWebSocket();
  const { data: components } = useGetUIComponentsByBlockQuery(block._id);
  const [isLoadingExecuteJobs, setIsLoadingExecuteJobs] = useState(false);
  const [disableExecuteJobs, setDisableExecuteJobs] = useState(false);

  // Create hooks for this and all other view logic
  const createNewItemFromModel = (model) => {
    const newItem = {};
    Object.keys(model.value).forEach(key => {
      if (Boolean(model.value[key].required || model.value[key].default)) {
        newItem[key] = model.value[key].default || '';
      }
    });
    return newItem;
  };

  const parseOptions = (optionsString) => {
    return optionsString?.split(',').map(option => {
      const [label, key, type] = option.split(':');
      return { label, key, type };
    });
  };

  const renderComponent = (component) => {
    const getKey = (key) => {
      return component.dataKeys.find(item => item.key === key)?.value;
    };

    const getValue = (key) => {
      return pipelineInstance.dataStore[getKey(key)]?.value;
    };

    const getOptions = (key, isPrefix = false) => {
      if (isPrefix) {
        return component.options    
          .filter(option => option.key.startsWith(key))
          .map(option => ({ key: option.key.slice(key.length), value: pipelineInstance.dataStore[option.value]?.value || option.value}));
      }
      return component.options.find(option => option.key === key)?.value;
    };

    const handleItemAdd = () => {
      const existingItems = [...(pipelineInstance.dataStore[getKey("items")]?.value || [])];
      const newItem = createNewItemFromModel(models.find(m => m.key === getKey("items")));
      handleUpdate(getKey("items"), [...existingItems, newItem], 'structured');
    };
  
    const handleItemChange = (index, value) => {
      const updatedItems = [...pipelineInstance.dataStore[getKey("items")]?.value];
      updatedItems[index] = value;
      handleUpdate(getKey("items"), updatedItems, "structured");
    };
  
    const handleItemDelete = (index) => {
      const filteredItems = pipelineInstance.dataStore[getKey("items")]?.value.filter((_, i) => i !== index);
      handleUpdate(getKey("items"), filteredItems, 'structured');
    };

    const disabled = component.options?.find(option => option.key === 'disableInteraction')?.value == "true";

    switch (component.type) {
      case 'paragraph':
        return (
          <ParagraphInput
            value={getValue("text")}
            placeholder={getOptions('placeholder')}
            onChange={(e) => handleUpdate(getKey("text"), e.target.value)}
          />
        );
      case 'grid':
        return (
          <Grid
            items={getValue("items")}
            list={getOptions('list:', true)}
            onAdd={handleItemAdd}
            onChange={handleItemChange}
            onDelete={handleItemDelete}
            CardComponent={DynamicCard}
            options={component.options}
            disabled={disabled}
            parseOptions={parseOptions}
          />
        );
      case 'table':
        return <DynamicTable
          items={getValue("items")}
          setItems={(items) => handleUpdate(getKey("items"), items, 'structured')}
          columns={parseOptions(getOptions('columns'))}
          subsections={parseOptions(getOptions('subsections')) ?? []}
          options={component.options}
          filters={parseOptions(getOptions('filters')) ?? []}
          onAdd={handleItemAdd}
          disabled={disabled}
          onChange={handleItemChange}
          onDelete={handleItemDelete}
          parseOptions={parseOptions}
          getDataValue={(key) => pipelineInstance.dataStore[key]?.value}
          subsectionTypes={pipelineInstance.dataStore['subsection_types']?.value}
          instanceId={pipelineInstance._id}
        />;
      case 'selector':
        const isMulti = getOptions('isMulti') === 'true';
        return (
          <Selector
            label={component.name}
            options = {[
              ...(getOptions('options')?.split(',') ?? []),
              ...(pipelineInstance.dataStore[getOptions('options_key')]?.value ?? [])
            ].map(option => ({
              label: option,
              value: option
            }))}
            value={isMulti ? getValue("value")?.map(item => ({ value: item, label: item })) ?? [] : { "value": getValue("value"), "label": getValue("value")}}
            onChange={(value) => {
              if (isMulti) {
                // Remove any undefined values
                value = removeEmptyArrays(value).map(item => item.value);
                handleUpdate(getKey("value"), value);
              } else {
                handleUpdate(getKey("value"), value["value"]);
              }
            }}
            placeholder={getOptions('placeholder')}
            isMulti={isMulti}
          />
        );
      case 'lineBarChart':
        // TODO: Implement dynamic line bar chart
      case 'flowChart':
        // TODO: Implement dynamic flow chart
      default:
        return null;
    }
  };

  const onClickExecuteJobs = async () => {
    if (disableExecuteJobs) {
      return;
    }

    if (!socket) {
      toast.error('Socket not connected');
      return;
    }
    
    setDisableExecuteJobs(true);
    setIsLoadingExecuteJobs(true);

    socket.emit('executeBlock', { pipelineInstanceId: pipelineInstance._id, blockId: block._id });
  }

  const handleSocketResponse = useCallback((data) => {
    console.log('Data received from server:', data);
    handleUpdate('dataStore', data, 'structured');
    setIsLoadingExecuteJobs(false);
    setDisableExecuteJobs(false);
  }, [handleUpdate]);

  const handleSocketError = useCallback((error) => {
    console.error('WebSocket error:', error);
    toast.error("Failed to reload. If the problem persists, contact support.");
    setIsLoadingExecuteJobs(false);
    setDisableExecuteJobs(false);
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('queryResponse', handleSocketResponse);
      socket.on('queryError', handleSocketError);

      return () => {
        socket.off('queryResponse', handleSocketResponse);
        socket.off('queryError', handleSocketError);
      };
    }
  }, [socket, handleSocketResponse, handleSocketError]);

  return (
    <>
      <div className='project-section-title d-flex align-items-center justify-content-between'>
        <div>
          <h3>{block.name}</h3>
          <h6>{block.description}</h6>
        </div>
        {block.jobs.length > 0 &&
          <GenerateButton
            onClick={onClickExecuteJobs}
            isDisabled={disableExecuteJobs}
            isLoading={isLoadingExecuteJobs}
            defaultText='Reload'
            loadingText='Reloading...'
          />
        }
      </div>
      <div className="input-container">
        {components?.map((component, index) => (
          <div 
            className="input-container" 
            key={`${component._id}-${index}`}
          >
            {renderComponent(component)}
          </div>
        ))}
      </div>
    </>
  );
};

export default DynamicSection;

import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import Loader from '../../utility/Loader';
import DynamicCell from './DynamicCell';
import { useGetRowDataByDistinctMutation } from '../../../slices/pipelines/pipelineInstanceSlice';
import { toast } from 'react-toastify';

const ITEMS_PER_PAGE = 50;

const DynamicTable = ({
  isLoading,
  items: initialItems,
  columns,
  subsections,
  options,
  filters,
  onAdd,
  disabled,
  onChange,
  onDelete,
  parseOptions,
  getDataValue,
  subsectionTypes,
  instanceId,
}) => {
  const [items, setItems] = useState(initialItems);
  const [enabledSubsections, setEnabledSubsections] = useState(() => {
    const initial = {};
    subsectionTypes && subsectionTypes.forEach((type) => {
      initial[type] = true;
    });
    return initial;
  });
  const [loadingRowIndex, setLoadingRowIndex] = useState(null); // Track loading state for each row

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);

  const filteredItems = items && items.filter(item => {
    if (filters.length === 0) return true; // If no filters are set, don't filter
    return filters.every(filter => {
      const value = getDataValue(filter.key);
      if (!value || value.length === 0) return true; // If the filter is not set, don't filter

      if (filter.type === 'in') {
        return value.includes(item[filter.label]);
      } else if (filter.type === 'not in') {
        return !value.includes(item[filter.label]);
      } else if (filter.type === 'equal') {
        return value === item[filter.label];
      } else if (filter.type === 'not equal') {
        return value !== item[filter.label];
      }

      return filter.value.includes(value);
    });
  });

  const totalPages = filteredItems ? Math.max(Math.ceil(filteredItems.length / ITEMS_PER_PAGE), 1) : 1;
  const paginatedItems = filteredItems ? filteredItems.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE) : [];

  const [openRowIndex, setOpenRowIndex] = useState(null);
  const [selectedDemographics, setSelectedDemographics] = useState([]);
  const [distinctValue, setDistinctValue] = useState(null);

  const [getRowDataByDistinct, { data: rowData, error, isLoading: isRowDataLoading }] = useGetRowDataByDistinctMutation();

  useEffect(() => {
    if (error) {
      setLoadingRowIndex(null); // Stop loading when error occurs
      toast.error('Failed to load row data');
    }
    if (rowData) {
      const index = items.findIndex(item => item[options.find(option => option.key === 'rowDistinct')?.value] === distinctValue);
      const filteredIndex = filteredItems.findIndex(item => item[options.find(option => option.key === 'rowDistinct')?.value] === distinctValue);
      if (index !== -1) {
        const updatedItems = [...items];
        updatedItems[index] = { ...items[index], ...rowData, 'isLoaded': true };
        setItems(updatedItems);
      }
      if (!isRowDataLoading && !error) {
        setLoadingRowIndex(null); // Stop loading when data is loaded
        setOpenRowIndex(filteredIndex);
      }
    }
  }, [rowData, error, isRowDataLoading]);

  useEffect(() => {
    if (distinctValue) {
      getRowDataByDistinct({ instanceId, distinctValue });
    }
  }, [distinctValue, instanceId, getRowDataByDistinct]);
  
  useEffect(() => {
    setCurrentPage(1);
    setOpenRowIndex(null);
    setLoadingRowIndex(null);
  }, [filters]);

  const toggleSubsection = (key) => {
    setEnabledSubsections(prev => ({ ...prev, [key]: !prev[key] }));
  };

  const handleRowToggle = (index) => {
    if (openRowIndex === index) {
      setOpenRowIndex(null);
      setLoadingRowIndex(null);
    } else if (filteredItems[index].isLoaded) {
      setOpenRowIndex(index);
      setLoadingRowIndex(null);
      // setLoadingRowIndex(index);
    } else {
      setLoadingRowIndex(index);
      const rowDistinct = options.find(option => option.key === 'rowDistinct')?.value || '';
      const distinctValue = filteredItems[index][rowDistinct];
      setDistinctValue(distinctValue);
    }
  };

  const handleDemographicsChange = (selectedOptions) => {
    setSelectedDemographics(selectedOptions);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      setOpenRowIndex(null);
      setLoadingRowIndex(null);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setOpenRowIndex(null);
      setLoadingRowIndex(null);
    }
  };

  return (
    <div className="input-container">
      <div className='input-background responsive-padding'>
        {isLoading ? <Loader /> : paginatedItems && columns && (
          <Table bordered responsive className='dynamic-table no-action-table'>
            <thead>
              <tr>
                {columns.map((column, index) => (
                  <React.Fragment key={index}>
                    <th>{column.label.trim()}</th>
                    {index === 0 && <th className='min-width-column'></th>}{/* Placeholder for the dropdown arrow */}
                  </React.Fragment>
                ))}
                {!disabled && <th className='min-width-column'></th>}{/* Empty column for the delete button */}
              </tr>
            </thead>
            <tbody>
              {paginatedItems.length > 0 && paginatedItems.map((item, index) => {
                const actualIndex = (currentPage - 1) * ITEMS_PER_PAGE + index;
                return (<DynamicCell
                  key={`table-row-cell-${actualIndex}`}
                  index={actualIndex}
                  item={item}
                  columns={columns}
                  subsections={subsections}
                  options={options}
                  disabled={disabled}
                  onChange={onChange}
                  onDelete={onDelete}
                  parseOptions={parseOptions}
                  enabledSubsections={enabledSubsections}
                  toggleSubsection={toggleSubsection}
                  isOpen={openRowIndex === actualIndex}
                  onRowToggle={() => handleRowToggle(actualIndex)}
                  selectedDemographics={selectedDemographics}
                  handleDemographicsChange={handleDemographicsChange}
                  isRowDataLoading={loadingRowIndex === actualIndex} // Pass the loading state
                />);
              })}
            </tbody>
          </Table>
        )}
        {!disabled && <button className='subtle-button' style={{ padding: '0px 0px 25px 25px' }} type="button" onClick={onAdd}>+ Add new</button>}
        <div className='d-flex justify-content-center align-items-center pb-4'>
          <button className='subsection-selector mx-2' onClick={handlePrevPage} disabled={currentPage === 1}>Previous</button>
          <span>Page {currentPage} of {totalPages}</span>
          <button  className='subsection-selector mx-2' onClick={handleNextPage} disabled={currentPage === totalPages}>Next</button>
        </div>
      </div>
    </div>
  );
};

export default DynamicTable;

import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Message from '../../components/utility/Message';
import Loader from '../../components/utility/Loader';
import Delete from '../../components/utility/Delete';
import {
  useGetPromptsQuery,
  useAddPromptMutation,
  useDeletePromptMutation,
} from '../../slices/openAiApiSlice';

const PromptListScreen = () => {
  const navigate = useNavigate();

  const { data: prompts, refetch, isLoading, error } = useGetPromptsQuery();
  const [deletePrompt] = useDeletePromptMutation();
  const [addPrompt] = useAddPromptMutation();

  useEffect(() => {
    document.title = "Prompts";
  }, []);

  const deleteHandler = async (id) => {
    try {
      await deletePrompt(id);
      refetch();
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };

  const handleNew = async () => {
    try {
      const response = await addPrompt();
      const promptId = response.data._id;
      navigate(`/admin/prompts/${promptId}/edit`);
    } catch (error) {
      toast.error(error?.data?.message || error.error || 'An error occurred while creating the prompt');
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>Prompts</h1>
        <button className="action-button" onClick={handleNew} >
          + New
        </button>
      </div>
        {isLoading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>
            {error?.data?.message || error.error}
            </Message>
        ) : (
            <Table bordered hover responsive className='table-sm'>
            <thead>
                <tr>
                <th>ID</th>
                <th>NAME</th>
                <th>TYPE</th>
                <th></th>
                </tr>
            </thead>
            <tbody>
                {prompts?.map((prompt) => (
                <tr key={prompt._id} onClick={() => navigate(`/admin/prompts/${prompt._id}/edit`)}>
                    <td>{prompt._id}</td>
                    <td>{prompt.name}</td>
                    <td>{prompt.type}</td>
                    <td><Delete onDelete={() => deleteHandler(prompt._id)} /></td>
                </tr>
                ))}
            </tbody>
            </Table>
        )}
    </div>
  );
};

export default PromptListScreen;

import React, { useState, useEffect, useRef } from 'react';
import Delete from '../../utility/Delete';
import ShortInput from '../../forms/ShortInput';
import MultiLineInput from '../../forms/MultiLineInput';

const FeatureCard = ({ item, onChange, onDelete, autoFocus }) => {
    const [name, setName] = useState((item && item.name) || '');
    const [description, setDescription] = useState((item && item.description) || '');

    const nameInputRef = useRef(null);

    const handleNameChange = (e) => {
        setName(e.target.value);
        onChange(item._id, 'name', e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
        onChange(item._id, 'description', e.target.value);
    };

    return (
        <div className="input-background grid-card">
            <ShortInput
                placeholder='Feature Name'
                className="item-name"
                value={name}
                onChange={handleNameChange}
                ref={nameInputRef}
            />
            <MultiLineInput
                placeholder='Description'
                className="item-description flex-grow-1"
                value={description}
                onChange={handleDescriptionChange}
            />
            <div style={{ margin:'0px 0px 5px 5px' }}>
                <Delete onDelete={ () => onDelete(item._id) } />
            </div>
        </div>
    );
};

export default FeatureCard;
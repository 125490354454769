import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell, LabelList, Legend, CartesianGrid } from 'recharts';

const StackedBarChartVisualization = ({ distribution }) => {
  // Convert the distribution object into an array of objects suitable for recharts
  const data = Object.entries(distribution).map(([key, value]) => {
    const entry = { name: value.label, gap: -6 };
    
    if (typeof value.value === 'object') {
      Object.entries(value.value).forEach(([subKey, subValue]) => {
        entry[subKey] = subValue;
      });
    } else {
      entry.value = value.value;
    }

    return entry;
  });

  // Extract all unique categories dynamically
  const categories = [...new Set(data.flatMap(Object.keys).filter(key => key !== 'name' && key !== 'gap'))];

  // Calculate the total for percentage calculations
  const total = data.reduce((sum, entry) =>
    sum + categories.reduce((subSum, category) => subSum + (entry[category] || 0), 0), 0);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const totalValue = payload.reduce((sum, entry) => sum + entry.value, 0);
      const percentage = ((totalValue / total) * 100).toFixed(2);
      return (
        <div className="custom-tooltip">
          <p className="tooltip-label"><strong>{label}</strong></p>
          {payload.map((entry, index) => (
            entry.name !== 'gap' &&
            <p key={`value-${index}`} className="tooltip-value" style={{ color: entry.fill }}>
              {`${entry.name}: ${entry.value} (${((entry.value / total) * 100).toFixed(2)}%)`}
            </p>
          ))}
          <p className="tooltip-total">{`Total: ${totalValue} (${percentage}%)`}</p>
        </div>
      );
    }
    return null;
  };

  const calculateMinWidth = (numColumns) => {
    const refWidth = 550;
    const refColumns = 20;
    return (refWidth / (refColumns)) * (numColumns);
  };

  const minWidth = calculateMinWidth(data.length);

  return (
    <ResponsiveContainer width="100%" height={400} minWidth={minWidth}>
      <BarChart
        layout="horizontal"
        data={data}
        margin={{ top: 5, right: 10, left: 0, bottom: 50 }}
      >
        <Legend layout="horizontal" verticalAlign="top" align="right" />
        <XAxis
          dataKey="name"
          minTickGap={-200}
          type="category"
          tick={{ fontSize: '14px', opacity: '0.8', fill: 'white' }}
          angle={-45} // Slant to the x-axis labels
          textAnchor="end" // Adjust text anchor for slanted labels
        />
        <YAxis
          type="number"
          tick={{ fontSize: '14px', opacity: '0.8', fill: 'white' }}
        />
        <Tooltip content={<CustomTooltip />} cursor={false} />
        {categories.map((category, index) => (
          <React.Fragment key={category}>
            <Bar
              dataKey={category}
              stackId="a"
              fill={index % 2 === 0 ? "var(--primary-color)" : "#c72860"}
              radius={[3, 3, 0, 0]}
            >
              {data.map((entry, idx) => (
                <Cell key={`cell-${category}-${idx}`} className="bar-cell" />
              ))}
              <LabelList
                dataKey={category}
                position="insideTop"
                formatter={(value) => value}
                style={{ fill: 'white', fontSize: '10px', whiteSpace: 'nowrap' }}
              />
            </Bar>
            <Bar
              key={`${category}-gap`}
              dataKey="gap"
              stackId="a"
              fill="transparent"
            />
          </React.Fragment>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default StackedBarChartVisualization;

import { apiSlice } from './../apiSlice';
import { BLOCKS_URL } from '../../constants';

export const blocksApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBlocks: builder.query({
      query: () => `${BLOCKS_URL}`,
      providesTags: ['Block'],
    }),
    getBlocksByPipeline: builder.query({
      query: (pipelineId) => `${BLOCKS_URL}/pipeline/${pipelineId}`,
      providesTags: ['Block'],
    }),
    getBlockById: builder.query({
      query: (id) => `${BLOCKS_URL}/${id}`,
      providesTags: ['Block'],
    }),
    createBlock: builder.mutation({
      query: (blockData) => ({
        url: BLOCKS_URL,
        method: 'POST',
        body: blockData,
      }),
      invalidatesTags: ['Block'],
    }),
    updateBlock: builder.mutation({
      query: ({ id, ...updateData }) => ({
        url: `${BLOCKS_URL}/${id}`,
        method: 'PUT',
        body: updateData,
      }),
      invalidatesTags: ['Block'],
    }),
    deleteBlock: builder.mutation({
      query: (id) => ({
        url: `${BLOCKS_URL}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Block'],
    }),
  }),
});

export const {
  useGetBlocksQuery,
  useGetBlocksByPipelineQuery,
  useGetBlockByIdQuery,
  useCreateBlockMutation,
  useUpdateBlockMutation,
  useDeleteBlockMutation,
} = blocksApiSlice;
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Form, Button, InputGroup, FormControl } from 'react-bootstrap';
import Message from '../../../components/utility/Message';
import Loader from '../../../components/utility/Loader';
import FloatingLabelInput from '../../../components/forms/FloatingLabelInput';
import SelectionDropdown from '../../../components/forms/SelectionDropdown';
import { toast } from 'react-toastify';
import {
  useGetJobByIdQuery,
  useUpdateJobMutation,
  useGetJobActionsQuery,
} from '../../../slices/pipelines/jobSlice';
import { useGetPromptsQuery } from '../../../slices/openAiApiSlice';

const JobEditScreen = () => {
  const { id: jobId } = useParams();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [action, setAction] = useState('');
  const [prompt, setPrompt] = useState('');
  const [parameters, setParameters] = useState({});
  const [outputs, setOutputs] = useState({});

  const {
    data: job,
    isLoading,
    error,
    refetch,
  } = useGetJobByIdQuery(jobId);

  const { data: jobActions } = useGetJobActionsQuery();
  const { data: prompts } = useGetPromptsQuery();
  const [updateJob, { isLoading: isUpdating }] = useUpdateJobMutation();

  useEffect(() => {
    if (job) {
      setName(job.name);
      setDescription(job.description);
      setAction(job.action);
      setPrompt(job.prompt);
      setParameters(job.parameters || {});
      setOutputs(job.outputs || {});
    }
  }, [job]);

  const handleParameterChange = (key, value) => {
    setParameters({ ...parameters, [key]: value });
  };

  const addParameter = () => {
    setParameters({ ...parameters, '': '' });
  };

  const removeParameter = (keyToRemove) => {
    const updatedParameters = { ...parameters };
    delete updatedParameters[keyToRemove];
    setParameters(updatedParameters);
  };

  const handleOutputChange = (key, value) => {
    setOutputs({ ...outputs, [key]: value });
  };

  const addOutput = () => {
    setOutputs({ ...outputs, '': '' });
  };

  const removeOutput = (keyToRemove) => {
    const updatedOutputs = { ...outputs };
    delete updatedOutputs[keyToRemove];
    setOutputs(updatedOutputs);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await updateJob({
        id: jobId,
        name,
        description,
        action,
        prompt,
        parameters,
        outputs,
      });
      toast.success('Job updated successfully');
      refetch();
    } catch (error) {
      toast.error(error?.data?.message || error.message);
    }
  };

  return (
    <div>
      <Link to='/admin/jobs' className='link-button'>
        ← Go Back
      </Link>
      <div className='text-center'>
        <h1>Edit Job</h1>
        {isUpdating && <Loader />}
        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error?.data?.message || error.message}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <FloatingLabelInput
              id='name'
              type='text'
              label='Name'
              value={name}
              onChange={setName}
              placeholder='Name'
            />
            <FloatingLabelInput
              id='description'
              type='text'
              label='Description'
              value={description}
              onChange={setDescription}
              placeholder='Description'
            />
            {!isLoading && jobActions && 
              <SelectionDropdown
                label="Action"
                value={action}
                options={jobActions.map(action => ({ label: action, value: action }))}
                onChange={(e) => setAction(e.target.value)}
              />
            }
            {!isLoading && prompts && 
              <SelectionDropdown
                label="Prompt"
                value={prompt}
                options={prompts.map(prompt => ({ label: prompt.name, value: prompt._id }))}
                onChange={(e) => setPrompt(e.target.value)}
                optional
              />
            }
            <h5>Parameters</h5>
            {Object.entries(parameters).map(([key, value], index) => (
             
             <InputGroup className="floating-label-group" key={index}>
             <FormControl
               placeholder="Key"
               aria-label="Parameter key"
               className='floating-input'
               style={{ 'padding':15 }}
               value={key}
               onChange={(e) => {
                 const newParameters = { ...parameters };
                 delete newParameters[key];
                 newParameters[e.target.value] = value;
                 setParameters(newParameters);
               }}
             />
             <FormControl
               placeholder="Value"
               aria-label="Parameter value"
               className='floating-input'
               style={{ 'padding':15 }}
               value={value}
               onChange={(e) => handleParameterChange(key, e.target.value)}
             />
             <Button variant="outline-danger" onClick={() => removeParameter(key)}>
               Remove
             </Button>
           </InputGroup>
         ))}
         <Button onClick={addParameter} className="action-button mb-3">
           Add Parameter
         </Button>

         <h5>Outputs</h5>
         {Object.entries(outputs).map(([key, value], index) => (
           <InputGroup className="floating-label-group" key={index}>
             <FormControl
               placeholder="Key"
               aria-label="Output key"
               className='floating-input'
               style={{ 'padding':15 }}
               value={key}
               onChange={(e) => {
                 const newOutputs = { ...outputs };
                 delete newOutputs[key];
                 newOutputs[e.target.value] = value;
                 setOutputs(newOutputs);
               }}
             />
             <FormControl
               placeholder="Value"
               aria-label="Output value"
               value={value}
               className='floating-input'
               style={{ 'padding':15 }}
               onChange={(e) => handleOutputChange(key, e.target.value)}
             />
             <Button variant="outline-danger" onClick={() => removeOutput(key)}>
               Remove
             </Button>
           </InputGroup>
         ))}
         <Button onClick={addOutput} className="action-button mb-3">
           Add Output
         </Button>
          
         <div>
            <Button variant="primary" type="submit" className="action-button mt-3">
              Update
            </Button>
         </div>
       </Form>
     )}
   </div>
 </div>
);
};

export default JobEditScreen;

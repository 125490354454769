import React, { useState, useEffect } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Button } from "react-bootstrap";
import NewGridCard from "../../../../components/pipelines/grid/NewCard";
import JobComponent from "./JobComponent";
import UIComponent from "./UIComponent";
import FloatingLabelInput from "../../../../components/forms/FloatingLabelInput";
import Delete from "../../../../components/utility/Delete";
import useBlockSetup from "../../../../hooks/pipeline-setup/useBlockSetup";
import useJobSetup from "../../../../hooks/pipeline-setup/useJobSetup";
import useUIComponentSetup from "../../../../hooks/pipeline-setup/useUIComponentSetup";

const BlockSection = React.memo(({
  blocks,
  setBlocks,
}) => {
  const { addBlock, handleBlockChange, moveBlock, removeBlock } = useBlockSetup(blocks, setBlocks);
  const { addJob } = useJobSetup(blocks, handleBlockChange);
  const { addUIComponent } = useUIComponentSetup(blocks, handleBlockChange);

  const [isBlockCollapsed, setIsBlockCollapsed] = useState(true);
  const [isJobsCollapsed, setIsJobsCollapsed] = useState({});
  const [isUIComponentsCollapsed, setIsUIComponentsCollapsed] = useState({});

  useEffect(() => {
    setIsJobsCollapsed(prevJobsCollapsed => {
      const updatedJobsCollapsed = { ...prevJobsCollapsed };
      blocks.forEach(block => {
        if (updatedJobsCollapsed[block._id || block.tempId] === undefined) {
          updatedJobsCollapsed[block._id || block.tempId] = true;
        }
      });
      return updatedJobsCollapsed;
    });

    setIsUIComponentsCollapsed(prevUIComponentsCollapsed => {
      const updatedUIComponentsCollapsed = { ...prevUIComponentsCollapsed };
      blocks.forEach(block => {
        if (updatedUIComponentsCollapsed[block._id || block.tempId] === undefined) {
          updatedUIComponentsCollapsed[block._id || block.tempId] = true;
        }
      });
      return updatedUIComponentsCollapsed;
    });
  }, [blocks]);

  const toggleBlockCollapse = () => setIsBlockCollapsed(!isBlockCollapsed);

  const toggleJobsCollapse = (blockId) => {
    setIsJobsCollapsed((prevState) => ({
      ...prevState,
      [blockId]: !prevState[blockId],
    }));
  };

  const toggleUIComponentsCollapse = (blockId) => {
    setIsUIComponentsCollapsed((prevState) => ({
      ...prevState,
      [blockId]: !prevState[blockId],
    }));
  };

  return (
    <div className='my-3'>
      <div className='d-flex justify-content-between align-items-center mb-3'>
        <div>
          <h2>Blocks</h2>
          <h6>The components of the pipeline.</h6>
        </div>
        <div onClick={toggleBlockCollapse} style={{ cursor: 'pointer' }}>
          {isBlockCollapsed ? <FaChevronUp /> : <FaChevronDown />}
        </div>
      </div>
      {!isBlockCollapsed && (
        <>
          {blocks.map((block, blockIndex) => (
            <div className="input-background table-container mb-3" key={`${block._id || block.tempId}-block`}>
              {/* Basic information */}
              <div className="d-flex justify-content-between">
                <div style={{width:"80%"}}>
                  <FloatingLabelInput
                    placeholder="Block Name"
                    className="item-name"
                    value={block.name}
                    onChange={(newValue) => handleBlockChange(block._id || block.tempId, 'name', newValue)}
                  />
                  <FloatingLabelInput
                    placeholder="Block Description"
                    className="item-description"
                    value={block.description}
                    onChange={(newValue) => handleBlockChange(block._id || block.tempId, 'description', newValue)}
                  />
                </div>
                <div className="mt-1">
                  <Button className={'m-1'} variant='dark' onClick={() => moveBlock(blockIndex, -1)}>↑</Button>
                  <Button className={'m-1'}variant='dark' onClick={() => moveBlock(blockIndex, 1)}>↓</Button>
                </div>
              </div>
              
              {/* Jobs */}
              <div>
                <div className='d-flex justify-content-between align-items-center mx-3 my-2'>
                  <h3>Jobs</h3>
                  <div onClick={() => toggleJobsCollapse(block._id || block.tempId)} style={{ cursor: 'pointer' }}>
                    {isJobsCollapsed[block._id || block.tempId] ? <FaChevronUp /> : <FaChevronDown />}
                  </div>
                </div>
                {!isJobsCollapsed[block._id || block.tempId] && (
                  <>
                    {block.jobs.filter(job => !job.isDeleted).map((job, jobIndex) => (
                      <JobComponent 
                        key={`${block._id || block.tempId}-job-${job._id || job.tempId}`}
                        job={job}
                        jobIndex={jobIndex}
                        block={block}
                        handleBlockChange={handleBlockChange}
                      />
                    ))}
                    <NewGridCard onAdd={() => addJob(block, handleBlockChange)} isWide/>
                  </>
                )}
              </div>
              
              {/* UI Components */}
              <div className='mb-2'>
                <div className='d-flex justify-content-between align-items-center mx-3 my-2'>
                  <h3>UI Components</h3>
                  <div onClick={() => toggleUIComponentsCollapse(block._id || block.tempId)} style={{ cursor: 'pointer' }}>
                    {isUIComponentsCollapsed[block._id || block.tempId] ? <FaChevronUp /> : <FaChevronDown />}
                  </div>
                </div>
                {!isUIComponentsCollapsed[block._id || block.tempId] && (
                  <>
                    {block.uiComponents.filter(component => !component.isDeleted).map(component => (
                      <UIComponent 
                        key={`${block._id || block.tempId}-ui-${component._id || component.tempId}`}
                        component={component} 
                        block={block}
                        handleBlockChange={handleBlockChange}
                      />
                    ))}
                    <NewGridCard onAdd={() => addUIComponent(block, handleBlockChange)} isWide />
                  </>
                )}
              </div>

              <Delete onDelete={() => removeBlock(block._id || block.tempId)} />
            </div>
          ))}
          <NewGridCard onAdd={addBlock} isWide/>
        </>
      )}
    </div>
  );
});

export default BlockSection;
import React from 'react';

const SelectionDropdown = ({ label, value, options, onChange, multiple, optional }) => {
  const isOptionString = Array.isArray(options) && options.length > 0 && typeof options[0] === 'string';

  const modifiedOptions = optional
    ? [{ value: '', label: 'None' }, ...options]
    : options;

  return (
    <div className='selection-dropdown'>
      <div className='selection-dropdown-label'>{label}</div>
      {modifiedOptions && (
        <select 
          className='selection-dropdown-item' 
          value={value} 
          onChange={onChange} 
          multiple={multiple || false}
        >
          {isOptionString ? (
            modifiedOptions?.map((option, index) => (
              <option key={index} value={option}>
                {option.length > 0 ? option.charAt(0).toUpperCase() + option.slice(1) : "Invalid option"}
              </option>
            ))
          ) : (
            modifiedOptions?.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))
          )}
        </select>
      )}
    </div>
  );
};

export default SelectionDropdown;
import React, { useState, useEffect, useRef } from 'react';
import Delete from '../../utility/Delete';
import ShortInput from '../../forms/ShortInput';
import MultiLineInput from '../../forms/MultiLineInput';
import DynamicList from './DynamicList';

const DynamicCard = ({ 
  index,
  item,
  list,
  onChange,
  onDelete,
  autoFocus,
  options,
  disabled,
  parseOptions
}) => {
  const [data, setData] = useState(item || {});
  const nameInputRef = useRef(null);
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);

  const titleKey = options?.find(option => option.key === 'title')?.value;
  const bodyKey = options?.find(option => option.key === 'body')?.value;
  const listKey = options?.find(option => option.key === 'list:data')?.value;
  const statistics = parseOptions(options?.find(option => option.key === 'statistics')?.value);

  useEffect(() => {
    if (autoFocus && nameInputRef.current) {
      nameInputRef.current.focus();
      nameInputRef.current.select();
    }
  }, [autoFocus]);

  useEffect(() => {
    setData(item || {});
  }, [item]);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleInputChange = (key, value) => {
    const updatedData = { ...data, [key]: value };
    setData(updatedData);
    if (onChange) {
      onChange(index, updatedData);
    }
  };

  const isNumber = (value) => {
    return !isNaN(value) || value === '';
  }

  return (
    <div className="input-background grid-card" ref={containerRef}>
      <ShortInput
        ref={nameInputRef}
        placeholder="Name"
        className="item-name"
        value={data[titleKey] || ''}
        disabled={disabled}
        onChange={(e) => handleInputChange(titleKey, e.target.value)}
      />
      <MultiLineInput
        placeholder="Description"
        className="item-description"
        value={data[bodyKey] || ''}
        disabled={disabled}
        onChange={(e) => handleInputChange(bodyKey, e.target.value)}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: containerWidth > 50 ? 'row' : 'column',
          flexWrap: 'wrap',
          alignItems: 'center',
        }}
      >
        {statistics?.map((statistic) => (
          <div key={statistic.key} style={{ fontStyle: 'normal', marginRight: '5px' }}>
            <h6 style={{ fontStyle: 'normal', marginLeft: '10px' }}>
              {statistic.label}
            </h6>
            {isNumber(data[statistic.key]) ? (
              <ShortInput
                type="number"
                placeholder={statistic.label}
                style={{ width: '150px' }}
                value={data[statistic.key] || ''}
                disabled={disabled}
                onChange={(e) => handleInputChange(statistic.key, e.target.value)}
              />
            ) : (
              <MultiLineInput
                placeholder={statistic.label}
                value={data[statistic.key] || ''}
                disabled={disabled}
                onChange={(e) => handleInputChange(statistic.key, e.target.value)}
              />
            )}
          </div>
        ))}
      </div>
      <DynamicList
        list={list}
        data={data}
        titleKey={titleKey}
        onAddItem={item => handleInputChange(listKey, item)}
        onDeleteItem={item => handleInputChange(listKey, item)}
        formatOptions={{ size: 'compact', spend: 'money' }}
      />
      {!disabled &&
        <div style={{ margin: '0px 0px 5px 5px' }}>
          <Delete onDelete={() => onDelete(index)} /> 
        </div>
      }
    </div>
  );
};

export default DynamicCard;
import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/utility/Loader';
import FloatingLabelInput from '../components/forms/FloatingLabelInput';
import ReCAPTCHA from "react-google-recaptcha";
import { useLoginMutation } from '../slices/usersApiSlice';
import { setCredentials } from '../slices/authSlice';
import { toast } from 'react-toastify';

const LoginScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [formErrors, setFormErrors] = useState({ email: '', password: '' });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);
  const [login, { isLoading }] = useLoginMutation();
  const recaptchaRef = useRef();
  const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const redirect = sp.get('redirect') || '/';

  useEffect(() => {
    document.title = "Login | Startr";
    if (userInfo) navigate(redirect);
  }, [navigate, redirect, userInfo]);

  const validateEmail = (email) => {
    const emailRegex = /^\S+@\S+\.\S+$/;
    return emailRegex.test(email) ? '' : 'Invalid email format';
  };

  const handleEmailChange = (value) => {
    setEmail(value);
    setFormErrors({ ...formErrors, email: validateEmail(value) });
  };

  const handlePasswordChange = (value) => {
    setPassword(value);
    setFormErrors({ ...formErrors, password: value ? '' : 'Password is required' });
  };

  const submitHandler = async (e) => {
    recaptchaRef.current.reset();
    e.preventDefault();

    const emailError = validateEmail(email);
    const passwordError = password ? '' : 'Password is required';
    const hasRecaptchaToken = recaptchaToken;

    setFormErrors({ email: emailError, password: passwordError });

    if (emailError || passwordError || !hasRecaptchaToken) {
      if (!hasRecaptchaToken) toast.error('Please complete the reCAPTCHA challenge.');
      return;
    }
  
    try {
      const res = await login({ email, password, recaptchaToken }).unwrap();
      dispatch(setCredentials({ ...res }));
      navigate(redirect);
    } catch (error) {
      toast.error(error?.data?.message || 'Login failed. Please try again.');
    }
  };  

  const onRecaptchaChange = (value) => {
    setRecaptchaToken(value);
  };

  return (
    <div className='text-center'>
      <h1>Log In</h1>
      <p>Enter your email and password to continue.</p>

      <Form onSubmit={submitHandler}>
        <FloatingLabelInput
          id='email'
          type='email'
          value={email}
          onChange={handleEmailChange}
          placeholder='Email'
          isExternallyValid={!formErrors.email}
          errorMessage={formErrors.email}
        />
        <FloatingLabelInput
          id='password'
          type='password'
          value={password}
          onChange={handlePasswordChange}
          placeholder="Password"
          isExternallyValid={!formErrors.password}
          errorMessage={formErrors.password}
        />
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={RECAPTCHA_SITE_KEY}
          onChange={onRecaptchaChange}
          className='d-flex justify-content-center mt-4'
        />
        <button className="action-button" disabled={isLoading} type='submit'>
          Log In
        </button>
        {isLoading && <Loader />}
      </Form>

      <div className='py-3'>
        Don't have an account?{' '}
        <Link className='link-button' to={`/register?redirect=${redirect}`}>
          Sign up
        </Link>
        {' '}to join the waitlist.
      </div>
    </div>
  );
};

export default LoginScreen;

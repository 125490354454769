import React, { useState, useEffect, useRef } from 'react';

const ActionDropdown = ({ label, padding=5, actions, enableHover = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const openDropdown = () => {
    setIsOpen(true);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    if (!enableHover) {
      document.addEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [enableHover]); // eslint-disable-line react-hooks/exhaustive-deps

  const hoverProps = enableHover ? {
    onMouseEnter: openDropdown,
    onMouseLeave: closeDropdown
  } : {};

  return (
    <div 
      onClick={(e) => {
        e.stopPropagation();
        setIsOpen(!isOpen);
      }}
      ref={dropdownRef}
      {...hoverProps}
    >
      <div style={{ padding:`${padding}px` }} className="action-dropdown-label input">{label}</div>

      {isOpen && (
        <div className='action-dropdown-menu'>
          {actions.map(([name, action], index) => (
            <div
              key={index}
              onClick={(e) => {
                e.stopPropagation();
                action();
                closeDropdown();
              }}
              className='action-dropdown-item'
            >
              {name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ActionDropdown;
import { apiSlice } from './../apiSlice';
import { JOBS_URL } from '../../constants';

export const jobsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getJobs: builder.query({
      query: () => `${JOBS_URL}`,
      providesTags: ['Job'],
    }),
    getJobsByBlock: builder.query({
      query: (blockId) => `${JOBS_URL}/block/${blockId}`,
      providesTags: ['Job'],
    }),
    getJobById: builder.query({
      query: (id) => `${JOBS_URL}/${id}`,
      providesTags: ['Job'],
    }),
    createJob: builder.mutation({
      query: (jobData) => ({
        url: JOBS_URL,
        method: 'POST',
        body: jobData,
      }),
      invalidatesTags: ['Job'],
    }),
    updateJob: builder.mutation({
      query: ({ id, ...updateData }) => ({
        url: `${JOBS_URL}/${id}`,
        method: 'PUT',
        body: updateData,
      }),
      invalidatesTags: ['Job'],
    }),
    deleteJob: builder.mutation({
      query: (id) => ({
        url: `${JOBS_URL}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Job'],
    }),
    getJobActions: builder.query({
      query: () => `${JOBS_URL}/actions`,
    }),
  }),
});

export const {
  useGetJobsQuery,
  useGetJobsByBlockQuery,
  useGetJobByIdQuery,
  useCreateJobMutation,
  useUpdateJobMutation,
  useDeleteJobMutation,
  useGetJobActionsQuery
} = jobsApiSlice;
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import Message from '../../../components/utility/Message';
import Loader from '../../../components/utility/Loader';
import usePipelineSetup from '../../../hooks/pipeline-setup/usePipelineSetup';
import DetailSection from './components/DetailSection';
import ModelSection from './components/ModelSection';
import BlockSection from './components/BlockSection';

const PipelineEditScreen = () => {
  const { id: pipelineId } = useParams();
  const {
    name,
    setName, description, setDescription, singleInstancePerAssociation,
    setSingleInstancePerAssociation, version, setVersion, status, setStatus, isPublic, 
    setIsPublic, allowEmoji, setAllowEmoji, disableInteraction, setDisableInteraction, models, 
    setModels, blocks, setBlocks, statusOptions, isLoadingPipeline, pipelineError, 
    handleCheckboxChange, submitHandler,
  } = usePipelineSetup(pipelineId);

  if (isLoadingPipeline) return <Loader />;

  if (pipelineError) return <Message variant='danger'>{pipelineError?.data?.message || pipelineError.message}</Message>;

  return (
    <div>
      <div>
        <Link to='/admin/pipelines' className="link-button">Pipelines</Link>
        <span style={{ fontSize: '14px' }}> / {name} </span>
      </div>
      <Form onSubmit={submitHandler}>
      <div style={{ position: 'sticky', top: 0, zIndex: 1001, background: 'var(--global-background-color)', paddingTop: '2rem' }}>
          <div className='d-flex align-items-center justify-content-between'>
            <h2 style={{ marginBottom:0 }}>Edit Pipeline</h2>
            <button className='action-button' type='submit'>
              Update
            </button>
          </div>
          <hr />
        </div>
        <DetailSection 
          name={name} 
          setName={setName} 
          description={description}
          setDescription={setDescription}
          version={version}
          setVersion={setVersion}
          status={status}
          setStatus={setStatus}
          statusOptions={statusOptions}
          singleInstancePerAssociation={singleInstancePerAssociation}
          setSingleInstancePerAssociation={setSingleInstancePerAssociation}
          isPublic={isPublic}
          setIsPublic={setIsPublic}
          allowEmoji={allowEmoji}
          setAllowEmoji={setAllowEmoji}
          disableInteraction={disableInteraction}
          setDisableInteraction={setDisableInteraction}
          handleCheckboxChange={handleCheckboxChange}
        />
        <hr />
        <ModelSection 
          models={models}
          setModels={setModels}
        />
        <hr />
        <BlockSection
          blocks={blocks}
          setBlocks={setBlocks}
        />
        <hr />
      </Form>
    </div>
  );
};

export default PipelineEditScreen;
import React, { useEffect, useRef, useState, useCallback, forwardRef } from 'react';
import { useGetSpecificLimitsQuery } from '../../slices/limitsSlice';

// Custom Hook for tracking element width
function useElementWidth(ref) {
    const [width, setWidth] = useState(0);

    const updateWidth = useCallback(() => {
        if (ref.current) {
            setWidth(ref.current.getBoundingClientRect().width);
        }
    }, [ref]);

    useEffect(() => {
        if (!ref.current) return;

        updateWidth(); // Initial update

        const resizeObserver = new ResizeObserver(() => {
            updateWidth();
        });
        resizeObserver.observe(ref.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, [ref, updateWidth]);

    return width;
}

const MultiLineInput = forwardRef(({
    id,
    className,
    value,
    disabled,
    style,
    onFocus,
    onBlur,
    onChange,
    placeholder,
}, ref) => {
    const { data: limits } = useGetSpecificLimitsQuery(['MAX_MULTILINE_INPUT_LENGTH']);
    const [inputValue, setInputValue] = useState(value || '');
    const [showLimitReached, setShowLimitReached] = useState(false);
    const textAreaRef = useRef(null);

    // Use the custom hook to track the width of the textarea
    const textareaWidth = useElementWidth(textAreaRef);

    const adjustHeight = () => {
        const textarea = textAreaRef.current;
        if (textarea) {
            textarea.style.height = 'inherit'; // Reset to default or minimum height
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    };

    useEffect(() => {
        adjustHeight();
    }, [inputValue, textareaWidth]); // Adjust height when inputValue or textareaWidth changes

    const handleChange = (e) => {
        const newValue = e.target.value;
        if (newValue.length > limits?.MAX_MULTILINE_INPUT_LENGTH) {
            setShowLimitReached(true);
        } else {
            setInputValue(newValue);
            setShowLimitReached(false);
            if (onChange) {
                onChange(e);
            }
        }
    };

    const handleBlur = (e) => {
        setShowLimitReached(false);
        if (onBlur) {
            onBlur(e);
        }
    };

    useEffect(() => {
        setInputValue(value || '');
        adjustHeight();
    }, [value]);

    return (
        <div style={{ flexGrow: 1 }}>
            <textarea
                ref={textAreaRef}
                id={id}
                className={`${className} ${showLimitReached ? 'limit-exceeded' : ''}${disabled ? 'disable-hover' : ''}`}
                value={inputValue}
                disabled={disabled}
                style={{ ...style, overflowY: 'hidden', resize: 'none' }}
                onFocus={onFocus}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={placeholder}
            />
            {showLimitReached && (
                <p className="error-message">
                    You cannot exceed {limits?.MAX_MULTILINE_INPUT_LENGTH} characters.
                </p>
            )}
        </div>
    );
});

export default MultiLineInput;

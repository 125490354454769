import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaChevronLeft, FaChevronRight, FaGripLinesVertical, FaPlus } from 'react-icons/fa';
import { BsStars } from "react-icons/bs";
import { MdAdminPanelSettings } from "react-icons/md";
import { FaCaretDown, FaCaretRight, FaFolder } from 'react-icons/fa';
import { TiFlowChildren } from "react-icons/ti";
import ActionDropdown from '../forms/ActionDropdown';
import { useAddProjectMutation } from '../../slices/projectsApiSlice'; // To be deprecated
import { useGetUserAssociationsQuery } from '../../slices/userOrganizationAssociationApiSlice';
import { useGetPipelinesQuery } from '../../slices/pipelines/pipelineSlice';
import { 
  useGetPipelineInstancesQuery,
  useCreatePipelineInstanceMutation,
} from '../../slices/pipelines/pipelineInstanceSlice';

const Sidebar = ({ isExpanded, toggleSidebar }) => {
  // User
  const [name, setName] = useState('');
  const [organizations, setOrganizations] = useState([]);

  // State
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [isHovered, setIsHovered] = useState(false);
  
  // Hooks and state management
  const navigate = useNavigate();
  const location = useLocation();
  const { userInfo } = useSelector((state) => state.auth);
  const { data: data } = useGetPipelineInstancesQuery({ page: 1, limit: 20 });
  const { data: userOrgAssociations } = useGetUserAssociationsQuery(userInfo._id, { skip: !userInfo._id });
  const { data: pipelines } = useGetPipelinesQuery();
  const [createPipelineInstance] = useCreatePipelineInstanceMutation();
  const [addProject] = useAddProjectMutation();

  useEffect(() => {
    setName(userInfo.name);
  }, [userInfo]);

  useEffect(() => {
    if (userOrgAssociations) {
      const associatedOrgNames = userOrgAssociations.map(assoc => assoc.organization.name);
      setOrganizations(associatedOrgNames || []);
    }
  }, [userOrgAssociations]);

  const toggleDropdown = (projectId) => {
    setOpenDropdowns(prevOpenDropdowns => ({
      ...prevOpenDropdowns,
      [projectId]: !prevOpenDropdowns[projectId]
    }));
  };

  // Derived state
  const userInitials = name.split(' ').map(n => n[0]).join('');
  const userOrganizations = organizations.join(' | ');

  const handleNewBusinessIdea = async () => {
    try {
      const response = await addProject();

      if (response.error) {
        toast.error(response.error.data.message || 'An error occurred');
        return;
      }
  
      if (response.data && response.data._id) {
        navigate(`/projects/${response.data._id}/edit`);
      } else {
        toast.error('Unable to load new project, please try again.');
      }
    } catch (error) {
      toast.error(error.message || 'An error occurred');
    }
  };

  const handleNewPipelineInstance = async (pipeline) => {
    try {
      const response = await createPipelineInstance({ pipeline: pipeline._id });

      if (response.error) {
        toast.error(response.error.data.message || 'An error occurred');
        return;
      }

      if (response.data && response.data._id) {
        navigateToPipelineInstance(response.data);
      }
    } catch (error) {
      toast.error(error.message || 'An error occurred');
    }
  };

  const navigateToPipelineInstance = (instance) => {
    navigate(`/pipelines/${instance.pipeline._id || instance.pipeline}/instance/${instance._id}/edit`);
  };

  const isInstanceSelected = (instance) => {
    return location.pathname.includes(`/instance/${instance._id}`);
  };

  const sidebarPipelines = pipelines?.filter(pipeline => pipeline.singleInstancePerAssociation);
  const dropdownPipelines = pipelines?.filter(pipeline => !pipeline.singleInstancePerAssociation);

  return (
    <div className={`sidebar ${isExpanded ? '' : 'collapsed' }`}>
      <div className={`content ${isExpanded ? '' : 'collapsed'}`}>
        <Link to='/' className='sidebar-section sidebar-link'>
          <div className="sidebar-logo" />
          <h3 style={{ margin: "0" }}>Startr</h3>
        </Link>

        <Link to='/profile' className='sidebar-section sidebar-link'>
          <div className="user-circle">{userInitials}</div>
          <div className="user-info">
            <h3>{name}</h3>
            {userOrganizations && <p className="account-type">{userOrganizations}</p>}
          </div>
        </Link>

        {userInfo.isAdmin && (
          <Link to='/admin/pipelines' className='sidebar-section sidebar-link'>
            <div className="user-circle"><MdAdminPanelSettings /></div>
            <div className="user-info">
              <h3>Admin</h3>
            </div>
          </Link>
        )}

        {/* TODO: Handle non-interactive pipelines */}
        {sidebarPipelines?.map(pipeline => (
          pipeline.instances.map(instance => (
            <Link 
              key={`${pipeline._id}-${instance._id}`}
              to={`/pipelines/${pipeline._id}/instance/${instance._id}/edit`} 
              className='sidebar-section sidebar-link'
            >
              <div className="ideas-icon"><BsStars /></div>
              <div className="ideas-info">
                <h3>{pipeline.name}</h3>
                <p className="coming-soon">{instance.connection}</p>
              </div>
            </Link>
          ))
        ))}

        <Link to='/pipelines' className='sidebar-section sidebar-link' style={{'marginBottom':10}}>
          <div className="user-circle"><TiFlowChildren /></div>
          <div className="user-info">
            <h3>Pipelines</h3>
          </div>
        </Link>
      
        <ActionDropdown
          label={
            <div className='sidebar-section sidebar-link button'>
              <FaPlus className='icon' />
              <h4>New</h4>
            </div>
          }
          padding={0}
          enableHover={false}
          actions={[
            ['Project (Deprecated)', handleNewBusinessIdea],
            ...(dropdownPipelines?.map(pipeline => [pipeline.name, () => handleNewPipelineInstance(pipeline)]) ?? [])
          ]}
        />

        <div className="project-list-container" style={{'marginTop':10}}>
          {data?.instances?.map((instance) => (
            <div key={instance._id} className="project-container">
              <div className={`sidebar-link ${isInstanceSelected(instance) ? 'selected' : ''}`}>
                <div 
                  className="icon" 
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleDropdown(instance._id, e);
                  }}
                >
                  {openDropdowns[instance._id] ? <FaCaretDown /> : <FaCaretRight />}
                </div>
                <h4 onClick={() => navigateToPipelineInstance(instance)}>{instance.name}</h4>
              </div>
              {openDropdowns[instance._id] && (
                <div className="project-options">
                  <Link to={`pipelines/${instance.pipeline._id}/instance/${instance._id}/edit`} className="sidebar-sublink">• Edit</Link>
                </div>
              )}
            </div>
          ))}
        </div>

        <Link to='/projects' className='sidebar-section sidebar-link'>
          <div className="user-circle"><FaFolder /></div>
          <div className="user-info">
            <h3>Projects</h3>
            <p className="coming-soon">Deprecated</p>
          </div>
        </Link>
      </div>

      <button 
        className="collapse-button"
        onClick={toggleSidebar}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <FaGripLinesVertical className={`collapse-button-icon ${isHovered ? 'hidden' : ''}`} />
        <FaChevronRight className={`collapse-button-icon ${!isHovered || isExpanded ? 'hidden' : ''}`} />
        <FaChevronLeft className={`collapse-button-icon ${!isHovered || !isExpanded ? 'hidden' : ''}`} />
      </button>
    </div>
  );
};

export default Sidebar;

const legendColor = '#65AFFFA9';

export const homeData = [
    { 
        name: 'Innovators', 
        spending: 150, 
        people: 1200, 
        description: 'Offer a dynamic idea validation tool to help innovators quickly test and refine their concepts.' 
    },
    { 
        name: 'Entrepreneurs', 
        spending: 200, 
        people: 2200, 
        description: 'Provide a comprehensive business planning toolkit for entrepreneurs to streamline startup processes.' 
    },
    { 
        name: 'Strategists', 
        spending: 250, 
        people: 800, 
        description: 'Deliver advanced market analytics software to assist strategists in competitive analysis and trend forecasting.' 
    },
    { 
        name: 'Developers', 
        spending: 100, 
        people: 1500, 
        description: 'Provide an interactive platform for developers to visualize and design product flows, aiding in seamless feature integration and user experience enhancement.' 
    },
    { 
        name: 'Marketers', 
        spending: 180, 
        people: 1400, 
        description: 'Provide real-time market trend data and user engagement analytics for marketers to refine strategies.' 
    },
    { 
        name: 'Consultants', 
        spending: 220, 
        people: 700, 
        description: 'Equip consultants with collaboration and workshop tools to facilitate effective client sessions.' 
    },
];

export const audiencesChartLegendPayload = [
    {
        value: 'People', 
        type: 'square', 
        id: 'people', 
        color: legendColor
    },
    { 
        value: 'Similar spending ($/month)',
        type: 'line',
        id: 'spending',
        color: legendColor
    }
];
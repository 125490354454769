const useModelSetup = (models, setModels) => {
  const handleModelKeyChange = (oldKey, newKey) => {
      const updatedModels = models.map((model) => {
        if (model.key === oldKey) {
          return { ...model, key: newKey };
        }
        return model;
      });
      setModels(updatedModels);
    };
    
    const handleModelValueChange = (key, newValue) => {
      const newModels = models.map((model) => {
        if (model.key === key) {
          return { ...model, value: newValue };
        }
        return model;
      });
      setModels(newModels);
    };
    
    const addModel = (key) => {
      const newKey = key || `Model ${models.length + 1}`;
      setModels([...models, { key: newKey, value: "" }]);
    };
    
    const removeModel = (modelKey) => {
      const updatedModels = models.filter(model => model.key !== modelKey);
      setModels(updatedModels);
    };

    return {
      handleModelKeyChange,
      handleModelValueChange,
      addModel,
      removeModel,
  };
};

export default useModelSetup;
import React, { useState, useEffect, useRef } from 'react';
import Delete from '../../utility/Delete';
import ShortInput from '../../forms/ShortInput';
import { FaCaretDown, FaCaretUp, FaSpinner } from 'react-icons/fa';
import MultiLineInput from '../../forms/MultiLineInput';
import NestedTable from './subsections/NestedTable';
import Demographics from './subsections/Demographics';
import TextSection from './subsections/TextSection';
import Select from 'react-select';

const COLUMN_PADDING = 30;

const calculateTextWidth = (text, font) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font;
  return context.measureText(text).width;
};

const calculateColumnWidths = (data, columns, tableWidth, font = '18px Arial') => {
  const columnWidths = columns.map((column) => {
    const maxWidth = data.reduce((max, item) => {
      const text = item[column.key] || '';
      const textWidth = calculateTextWidth(text, font) + COLUMN_PADDING;
      return Math.max(max, textWidth);
    }, 0);
    return Math.max(maxWidth, 50); // min width
  });

  const totalWidth = columnWidths.reduce((sum, width) => sum + width, 0);
  if (totalWidth < tableWidth) {
    const extraWidth = tableWidth - totalWidth;
    const equalExtraWidth = extraWidth / columns.length;
    return columnWidths.map(width => width + equalExtraWidth);
  }

  return columnWidths;
};

const DynamicCell = ({
    index,
    item,
    columns,
    subsections,
    disabled,
    onChange,
    onDelete,
    autoFocus,
    enabledSubsections,
    toggleSubsection,
    isOpen,
    onRowToggle,
    selectedDemographics,
    handleDemographicsChange,
    isRowDataLoading
}) => {
    const [data, setData] = useState(item || {});
    const firstInputRef = useRef(null);

    useEffect(() => {
      if (autoFocus && firstInputRef.current) {
        firstInputRef.current.focus();
        firstInputRef.current.select();
      }
    }, [autoFocus]);

    useEffect(() => {
      setData(item || {});
    }, [item]);
    
    const averageWidths = calculateColumnWidths([data], columns);

    const handleInputChange = (key, value) => {
      const updatedData = { ...data, [key]: value };
      setData(updatedData);
      if (onChange) {
        onChange(index, updatedData);
      }
    };

    const renderSubsections = (subsection) => {
      if (!enabledSubsections[subsection.title]) return null;
      switch (subsection.type) {
        case 'table':
          return (
            <div className='detail-background'>
              <NestedTable key={subsection.title} items={subsection.data} sectionName={subsection.title} />
            </div>
          );
        case 'demographics':
            const dataEntries = Object.entries(subsection.data).map(([key, value]) => ({
              key,
              label: value.label || key, // Assuming there's a `label` property or use the key as a fallback
              ...value // Spread the remaining properties of the value object
            }));
          
            return (dataEntries.length > 0 && (
              <div>
                <Select
                  isMulti
                  options={dataEntries.map(item => ({ value: item.key, label: item.label }))}
                  value={selectedDemographics}
                  onChange={handleDemographicsChange}
                  placeholder="Filter demographic tables..."
                  styles={darkThemeStyles}
                  closeMenuOnSelect={false}
                />
                <Demographics 
                  key={subsection.title} 
                  items={
                    selectedDemographics.length === 0 
                      ? dataEntries 
                      : dataEntries.filter(item => selectedDemographics.some(demo => demo.value === item.key))
                  } 
                />
              </div>
            ));
        case 'text':
          return <TextSection key={subsection.title} items={subsection.data} />;
        case 'cells':
          return (
            <div className="grid" key={subsection.title}>
              {subsection.data?.map(item => (
                <div className="d-flex detail-background" key={item.key}>
                  <strong 
                    className='item-name' 
                    style={{ padding:'10px 10px 5px 10px' }}
                  >
                    {item.label}
                  </strong>
                  <MultiLineInput
                    placeholder={item.label}
                    className="item-description flex-grow-1"
                    value={item.value || ''}
                    onChange={(e) => {
                      handleInputChange(item.key, e.target.value);
                    }}
                  />
                </div>
              ))}
            </div>
          );
        default:
          return null;
      }
    };

    return (
      <>
        <tr>
          {columns.map((column, index) => (
            <React.Fragment key={index}>
              <td style={{ width: `${averageWidths[index]}px` }}>
                <ShortInput
                  type={column.type || 'text'}
                  placeholder={column.label}
                  value={data[column.key] || ''}
                  disabled={disabled}
                  onChange={(e) => handleInputChange(column.key, e.target.value)}
                />
              </td>
              {index === 0 && (
                <td className='min-width-column'>
                  {enabledSubsections && (
                    <button className='fade-btn' type='button' onClick={onRowToggle} >
                      {isRowDataLoading ? <FaSpinner className="spinner" /> : (isOpen ? <FaCaretUp /> : <FaCaretDown />)}
                    </button>
                  )}
                </td>
              )}
            </React.Fragment>
          ))}
          {!disabled && <td className='min-width-column'><Delete onDelete={() => onDelete(index)} /></td>}
        </tr>
        {isOpen && data.components && data.components.length > 0 && (
            <tr>
                <td colSpan={columns.length + 2}>
                    <div className="subsection-selectors">
                        {data.components && data.components.map((subsection) => (
                            <div
                                key={subsection.title}
                                className={`subsection-selector ${enabledSubsections[subsection.title] ? 'enabled' : ''}`}
                                onClick={() => toggleSubsection(subsection.title)}
                            >
                                {subsection.title}
                            </div>
                        ))}
                    </div>
                    <div>
                        {data.components && data.components.map((subsection) => (
                          <div key={subsection.title}>
                            <div className='input-container'>
                              {renderSubsections(subsection)}
                            </div>
                          </div>
                        ))}
                    </div>
                </td>
            </tr>
        )}
      </>
    );
};

const darkThemeStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
    borderColor: '#444',
    color: '#fff',
    borderRadius: '20px',
    padding: '5px',
    marginBottom: '20px',
    '&:hover': {
      borderColor: state.isFocused ? 'var(--primary-color)' : '#666', // change border color on hover
    }
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#2e2e2e',
    borderRadius: '10px',
  }),
  menuList: (provided) => ({
    ...provided,
    backgroundColor: '#2e2e2e',
    borderRadius: '10px',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#fff',
  }),
  input: (provided) => ({
    ...provided,
    color: '#fff',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#aaa',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#444' : '#2e2e2e',
    color: state.isSelected ? '#fff' : '#aaa',
    '&:hover': {
      backgroundColor: '#555',
      color: '#fff',
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#555',
    borderRadius: '10px',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: '#fff',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: '#fff',
    borderRadius: '10px',
    ':hover': {
      backgroundColor: '#444',
      color: '#fff',
    },
  }),
};

export default DynamicCell;

import { toast } from "react-toastify";

const useBlockSetup = (blocks, setBlocks) => {
  const addBlock = () => {
    const tempId = `temp-${Date.now()}`;
    const newBlock = {
      tempId,
      name: '',
      description: '',
      jobs: [],
      uiComponents: []
    };
    setBlocks([...blocks, newBlock]);
  };

  const handleBlockChange = (blockId, attribute, newValue) => {
    if (!blockId) {
      toast.error('Block not found');
    }
    const updatedBlocks = blocks.map(block =>
      (block._id === blockId || block.tempId === blockId) ? { ...block, [attribute]: newValue } : block
    );
    setBlocks(updatedBlocks);
  };

  const moveBlock = (blockIndex, direction) => {
    const newPosition = blockIndex + direction;
    if (newPosition < 0 || newPosition >= blocks.length) return;

    const newBlocks = [...blocks];
    const temp = newBlocks[blockIndex];
    newBlocks[blockIndex] = newBlocks[newPosition];
    newBlocks[newPosition] = temp;

    setBlocks(newBlocks);
  };

  const removeBlock = (blockId) => {
    const updatedBlocks = blocks.map(block => {
      // For persistent blocks, mark as deleted. For temp blocks, remove them.
      if ((block._id && block._id === blockId) || (block.tempId && block.tempId === blockId)) {
        return block._id ? { ...block, isDeleted: true } : null;
      }
      return block;
    }).filter(block => block !== null);  // Remove null entries (temp blocks)
  
    setBlocks(updatedBlocks);
  };

  return { 
    addBlock, 
    handleBlockChange, 
    moveBlock, 
    removeBlock,
  };
};

export default useBlockSetup;
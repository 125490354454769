import {
    useGetProjectQuery, 
    useUpdateProjectMutation,
} from '../slices/projectsApiSlice';
import { toast } from 'react-toastify';
  
export const useProjectApi = (projectId) => {
  const { data: project, isLoading, error } = useGetProjectQuery(projectId);
  const [updateProjectMutation, { isLoading: isUpdating, error: updateError }] = useUpdateProjectMutation();

  const updateProject = async (projectUpdates) => {
    try {
      const updateResponse = await updateProjectMutation({ id: projectId, ...projectUpdates }).unwrap();
      return updateResponse;
    } catch (err) {
      toast.error(err.message || 'Failed to update project');
      throw err;
    }
  };

  return {
    project,
    isLoading,
    error,
    updateProject,
    isUpdating,
    updateError,
  };
};
import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Message from '../../../components/utility/Message';
import Loader from '../../../components/utility/Loader';
import Delete from '../../../components/utility/Delete';
import {
  useDeletePipelineMutation,
  useGetAllPipelinesQuery,
  useCreatePipelineMutation,
} from '../../../slices/pipelines/pipelineSlice';

const PipelineListScreen = () => {
  const navigate = useNavigate();

  const { data: pipelines, refetch, isLoading, error } = useGetAllPipelinesQuery();
  const [deletePipeline] = useDeletePipelineMutation();
  const [createPipeline] = useCreatePipelineMutation();

  const deletePipelineHandler = async (id) => {
    try {
      await deletePipeline(id).unwrap();
      refetch();
    } catch (error) {
      toast.error(error?.data?.message || error.message);
    }
  };

  const handleNewPipeline = async () => {
    try {
      const response = await createPipeline().unwrap();
      navigate(`/admin/pipelines/${response._id}/edit`);
    } catch (error) {
      toast.error(error?.data?.message || error.message || 'An error occurred while creating the pipeline');
    }
  };

  useEffect(() => {
    document.title = "Pipelines";
  }, []);

  return (
    <div>
      <div className="mb-3 d-flex align-items-center justify-content-between">
        <h2>Pipelines</h2>
        <button className="action-button" onClick={handleNewPipeline}>
          + New
        </button>
      </div>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>
          {error?.data?.message || error.message}
        </Message>
      ) : (
        <Table bordered hover responsive className='table-sm'>
          <thead>
            <tr>
              <th>ID</th>
              <th>NAME</th>
              <th>DESCRIPTION</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {pipelines.map((pipeline) => (
              <tr key={pipeline._id} onClick={() => navigate(`/admin/pipelines/${pipeline._id}/edit`)}>
                <td>{pipeline._id}</td>
                <td>{pipeline.name}</td>
                <td>{pipeline.description}</td>
                <td><Delete onDelete={() => deletePipelineHandler(pipeline._id)} /></td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

    </div>
  );
};

export default PipelineListScreen;

import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { toast } from 'react-toastify';
import DynamicSection from '../../components/pipelines/DynamicSection';
import EmojiPickerComponent from '../../components/pipelines/EmojiPickerComponent';
import Loader from '../../components/utility/Loader';
import ShortInput from '../../components/forms/ShortInput';
import {
    useGetPipelineInstanceByIdQuery,
    useUpdatePipelineInstanceMutation,
} from '../../slices/pipelines/pipelineInstanceSlice';
import { useGetPipelineByIdQuery } from '../../slices/pipelines/pipelineSlice';

const PipelineInstanceScreen = () => {
  const { pipelineId, instanceId } = useParams();
  const {
    data: fetchedPipelineInstance,
    isLoading: isLoadingInstance,
    isError: isErrorInstance,
  } = useGetPipelineInstanceByIdQuery(instanceId);
  const {
    data: pipeline,
    isLoading: isLoadingPipeline,
    isError: isErrorPipeline,
  } = useGetPipelineByIdQuery(pipelineId);
  const [updatePipelineInstance] = useUpdatePipelineInstanceMutation();

  const [pipelineInstance, setPipelineInstance] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [pendingUpdates, setPendingUpdates] = useState({});

  const nameRef = useRef(null);

  const handleUpdate = (key, value, type = 'text', isDataStore = true) => {
    // TODO: will probably change category (currently used for versioning the same data item)
    const newValue = isDataStore ? { valueType: type, value: value, category: key } : value;
    setPipelineInstance(prev => {
      if (isDataStore) {
        return {
          ...prev,
          dataStore: { ...prev.dataStore, [key]: newValue },
        };
      } else {
        return { ...prev, [key]: newValue };
      }
    });
    setPendingUpdates(prev => {
      if (isDataStore) {
        return {
          ...prev,
          dataStore: { ...prev.dataStore, [key]: newValue },
        };
      } else {
        return { ...prev, [key]: newValue };
      }
    });
  };

  const sendUpdates = async () => {
    try {
      setPendingUpdates({});
      await updatePipelineInstance({
        id: instanceId,
        ...pendingUpdates
      });
    } catch (error) {
      toast.error(`Error updating pipeline instance: ${error.message}`);
    } finally {
      setIsSaving(false);
    }
  };  

  useEffect(() => {
    const timer = setTimeout(() => {
      if (Object.keys(pendingUpdates).length > 0) {
        setIsSaving(true);
        sendUpdates();
      }
    }, 500);
    
    return () => clearTimeout(timer);
  }, [pendingUpdates]);

  useEffect(() => {
    if (fetchedPipelineInstance && Object.keys(pendingUpdates).length === 0 && !isLoadingInstance && !isSaving) {
      setPipelineInstance(fetchedPipelineInstance);
    }
  }, [fetchedPipelineInstance, pendingUpdates]);  // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoadingInstance || isLoadingPipeline || !pipelineInstance) {
    return <Loader />;
  }

  if (isErrorInstance || isErrorPipeline) {
    return <Container><p>There was an error loading the pipeline data.</p></Container>;
  }

  return (
    <div className='project-layout'>
      {/* Navigation */}
      <div className='project-section-title'>
        <Link to='/pipelines' className="link-button">Pipelines</Link>
        <span style={{ fontSize: '14px' }}> / {pipelineInstance.name} </span>
        {isSaving ? <span className='save-text'> Saving changes</span> : <></>}
      </div>

      {/* Emoji */}
      <div>
        {pipeline.options.allowEmoji && 
          <EmojiPickerComponent
            onEmojiSelect={(emoji) => handleUpdate('emoji', emoji)}
            triggerClass='emoji-lg'
            selectedEmoji={pipelineInstance.dataStore['emoji']?.value}
          />
        }
      </div>

      {/* Name */}
      <div className='d-flex align-items-center justify-content-between'>
        <ShortInput 
          ref={nameRef}
          className='project-title'
          value={pipelineInstance.name}
          disabled={pipeline.options?.disableInteraction || false}
          style={{ margin: '5px 0px'}}
          onChange={(e) => handleUpdate('name', e.target.value, 'text', false)}
          placeholder='Name'
        />
      </div>

      {/* Blocks */}
      <div>
        {pipeline?.blocks.map((block, index) => (
          <DynamicSection
            key={`${block._id}-${index}`}
            pipelineInstance={pipelineInstance}
            models={pipeline.models}
            block={block}
            handleUpdate={handleUpdate}
          />
        ))}
      </div>
    </div>
  );
};

export default PipelineInstanceScreen;

import React from 'react';
import Navbar from '../components/layout/Navbar';
import Footer from '../components/layout/Footer';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';

const DefaultLayout = () => {
  return (
    <div className='general-layout'>
      <Navbar />
      <main className='general-content'>
        <Container>
          <Outlet />
        </Container>
      </main>
      <Footer />
    </div>
  );
};

export default DefaultLayout;
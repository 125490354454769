import { Types } from 'mongoose';
import { toast } from "react-toastify";

const useDictionaryHandlers = () => {
  const addDictionaryEntry = (dictionary, setDictionary) => {
    const newEntry = {
      id: new Types.ObjectId(),
      key: '',
      value: ''
    };
    const updatedDictionary = [...dictionary, newEntry];
    setDictionary(updatedDictionary);
  };

  const editDictionaryKey = (dictionary, setDictionary, entryId, newKey) => {
    if (!newKey.trim() || dictionary.some(entry => entry.key === newKey)) {
      toast.warn("Duplicate or empty key. Operation cancelled.");
      return;
    }

    const updatedDictionary = dictionary.map(entry => {
      if (entry.id === entryId) {
        return { ...entry, key: newKey };
      }
      return entry;
    });

    setDictionary(updatedDictionary);
  };

  const editDictionaryValue = (dictionary, setDictionary, entryId, newValue) => {
    const updatedDictionary = dictionary.map(entry => {
      if (entry.id === entryId) {
        return { ...entry, value: newValue };
      }
      return entry;
    });

    setDictionary(updatedDictionary);
  };

  const removeDictionaryEntry = (dictionary, setDictionary, entryId) => {
    const updatedDictionary = dictionary.filter(entry => entry.id !== entryId);
    setDictionary(updatedDictionary);
  };

  return {
    addDictionaryEntry,
    editDictionaryKey,
    editDictionaryValue,
    removeDictionaryEntry,
  };
};

export default useDictionaryHandlers;
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import FloatingLabelInput from '../components/forms/FloatingLabelInput';

import { toast } from 'react-toastify';
import Loader from '../components/utility/Loader';
import { useProfileMutation } from '../slices/usersApiSlice';
import { setCredentials } from '../slices/authSlice';

const ProfileScreen = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const { userInfo } = useSelector((state) => state.auth);

  const [updateProfile, { isLoading: loadingUpdateProfile }] = useProfileMutation();

  useEffect(() => {
    document.title = "Profile";
  }, []);

  useEffect(() => {
    setName(userInfo.name);
    setEmail(userInfo.email);
    setPhone(userInfo.phone)
  }, [userInfo.phone, userInfo.email, userInfo.name]);

  const dispatch = useDispatch();
  const submitHandler = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
    } else {
      try {
        const res = await updateProfile({
          _id: userInfo._id,
          name,
          email,
          phone,
          password,
        }).unwrap();
        dispatch(setCredentials({ ...res }));
        toast.success('Profile updated successfully');
      } catch (error) {
        toast.error(error?.data?.message || error.error);
      }
    }
  };

  return (
    <Form className='text-center' onSubmit={submitHandler}>
      <h1>Profile</h1>
      
      <div>
        <FloatingLabelInput
          id='name'
          type='text'
          value={name}
          onChange={setName}
          placeholder='Name'
        />
      </div>

      <div>
        <FloatingLabelInput
          id='email'
          type='email'
          disabled={true}
          value={email}
          onChange={setEmail}
          placeholder='Email'
        />
      </div>

      <div>
        <FloatingLabelInput
          id='phone'
          type='phone'
          value={phone}
          onChange={setPhone}
          placeholder='Phone'
        />
      </div>

      <div>
        <FloatingLabelInput
          id='new_password'
          type='password'
          value={password}
          onChange={setPassword}
          placeholder='New password'
        />
      </div>

      <div>
        <FloatingLabelInput
          id='confirm_new_password'
          type='password'
          value={confirmPassword}
          onChange={setConfirmPassword}
          placeholder='Confirm new password'
        />
      </div>

      <button className='action-button' type='submit' variant='primary'>
        Update
      </button>

      {loadingUpdateProfile && <Loader />}
    </Form>
  );
};

export default ProfileScreen;

import React from 'react';
import BarChartVisualization from './visualizations/BarChart';
import StackedBarChartVisualization from './visualizations/StackedBarChart';
import PieChartVisualization from './visualizations/PieChart';
import GroupedBarChartVisualization from './visualizations/GroupedBarChart';

const Demographics = ({ items }) => {
  const renderStatistics = (statistics) => {
    if (!statistics) {
      return <div>No statistics available</div>;
    }
    return (
      <div className="statistics-container">
        {Object.entries(statistics).map(([key, value]) => (
          <div key={key} className="statistic-item">
            <div className="statistic-value">{value['value']}</div>
            <div className="statistic-name">{key}</div>
          </div>
        ))}
      </div>
    );
  };

  const renderVisualization = (visualization, distribution) => {
    if (visualization === 'bar') {
      return <BarChartVisualization distribution={distribution} />;
    }
    else if (visualization === 'stacked bar') {
      return <StackedBarChartVisualization distribution={distribution} />;
    }
    else if (visualization === 'pie') {
      return <PieChartVisualization distribution={distribution} />;
    }
    else if (visualization === 'grouped bar') {
      return <GroupedBarChartVisualization distribution={distribution} />;
    }
    return null;
  };

  return (
    <div className="grid-container">
      {items.map((item, index) => (
        <div className="visualization-grid-item" key={index}>
          <div className="detail-background">
          <div className="grid-card">
            <h3 className="section-name">{item.label}</h3>
            <div>
              {renderStatistics(item.statistics)}
            </div>
            <div>
              <h3 className="section-name">Distribution</h3>
              {renderVisualization(item.visualization, item.distribution)}
            </div>
          </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Demographics;

import { useEffect, useState } from 'react';
import io from 'socket.io-client';

const useWebSocket = () => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_WEBSOCKET_SERVER_URL);
    setSocket(newSocket);

    return () => newSocket.disconnect();
  }, []);

  return socket;
};

export default useWebSocket;

import React, { useEffect, useState, forwardRef } from 'react';
import { useGetSpecificLimitsQuery } from '../../slices/limitsSlice';
import { formatValue } from '../../formatter';

const ShortInput = forwardRef(({
    id,
    className,
    type = 'text',
    value,
    disabled,
    style,
    onFocus,
    onBlur,
    onChange,
    placeholder,
}, ref) => {
    // API Hooks
    const { data: limits, error, isLoading } = useGetSpecificLimitsQuery(['MAX_SHORT_INPUT_LENGTH']);

    // States
    const [inputValue, setInputValue] = useState(value || '');
    const [isFocused, setIsFocused] = useState(false);
    const [showLimitReached, setShowLimitReached] = useState(false);

    const parseInputValue = (val) => {
        switch (type) {
            case 'percent':
                return val.replace('%', '');
            case 'money':
                return val.replace(/[$BMK,]/g, '');
            case 'number':
                return val.replace(/,/g, '');
            default:
                return val;
        }
    };

    const inputIsValid = (input) => {
        const numRegex = /^[0-9]*\.?[0-9]*$/;
        return type === 'text' ||
                (type === 'number' && numRegex.test(input)) || 
                (type === 'percent' && input >= 0 && input <= 100) ||
                (type === 'money' && numRegex.test(parseInputValue(input)));
    };

    const handleChange = (event) => {
        const newValue = parseInputValue(event.target.value);
        if (newValue.length > limits?.MAX_SHORT_INPUT_LENGTH) {
            setShowLimitReached(true);
        } else if (inputIsValid(newValue)) {
            setInputValue(newValue);
            setShowLimitReached(false);
            onChange({...event, target: {...event.target, value: newValue}});
        }
    };

    const handleFocus = (e) => {
        setIsFocused(true);
        if (onFocus) {
            onFocus(e);
        }
    };

    const handleBlur = (e) => {
        setIsFocused(false);
        setShowLimitReached(false);
        if (onBlur) {
            onBlur(e);
        }
    };

    useEffect(() => {
        setInputValue(value || '');
    }, [value]);

    return (
        <input
            ref={ref}
            id={id}
            className={`${className} ${showLimitReached ? 'limit-exceeded' : ''}${disabled ? 'disable-hover' : ''}`}
            type={'text'}
            value={isFocused ? inputValue : formatValue(inputValue, type)}
            disabled={disabled}
            style={style}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={placeholder}
        />
    );
});

export default ShortInput;
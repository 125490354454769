import React, { useState, useEffect } from 'react';
import Select from 'react-select';

const Selector = ({
    id,
    options,
    value,
    onFocus,
    onBlur,
    onChange,
    label,
    placeholder,
    isMulti=false
}) => {
    const handleBlur = (e) => {
        if (onBlur) {
            onBlur(e);
        }
    };

    return (
        <div className="input-background selector-container">
            <label htmlFor={id} style={{paddingRight:"25px"}}>
                {label}
            </label>
            <div>
                <Select
                    isMulti={isMulti}
                    id={id}
                    placeholder={placeholder}
                    value={value}
                    options={options}
                    onFocus={onFocus}
                    onBlur={handleBlur}
                    onChange={onChange}
                    styles={darkThemeStyles}
                    closeMenuOnSelect={!isMulti}
                />
            </div>
        </div>
    );
};

const darkThemeStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
    borderColor: '#444',
    color: '#fff',
    borderRadius: '20px',
    padding: '5px',
    minWidth: '300px',
    '&:hover': {
      borderColor: state.isFocused ? 'var(--primary-color)' : '#666', // change border color on hover
    }
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#2e2e2e',
    borderRadius: '10px',
  }),
  menuList: (provided) => ({
    ...provided,
    backgroundColor: '#2e2e2e',
    borderRadius: '10px',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#fff',
  }),
  input: (provided) => ({
    ...provided,
    color: '#fff',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#aaa',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#444' : '#2e2e2e',
    color: state.isSelected ? '#fff' : '#aaa',
    '&:hover': {
      backgroundColor: '#555',
      color: '#fff',
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#555',
    borderRadius: '10px',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: '#fff',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: '#fff',
    borderRadius: '10px',
    ':hover': {
      backgroundColor: '#444',
      color: '#fff',
    },
  }),
};

export default Selector;

import { apiSlice } from './apiSlice';
import { OPENAI_URL } from '../constants';

export const openAiApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPrompts: builder.query({
      query: () => `${OPENAI_URL}/`,
      providesTags: ['Project'],
    }),
    getPrompt: builder.query({
      query: (promptId) => `${OPENAI_URL}/${promptId}`,
      providesTags: ['Prompt'],
    }),
    addPrompt: builder.mutation({
      query: (promptData) => ({
        url: `${OPENAI_URL}/`,
        method: 'POST',
        body: promptData,
      }),
      invalidatesTags: ['Prompt'],
    }),
    updatePrompt: builder.mutation({
      query: ({ id, ...promptData }) => ({
        url: `${OPENAI_URL}/${id}`,
        method: 'PUT',
        body: promptData,
      }),
      invalidatesTags: ['Prompt'],
    }),
    deletePrompt: builder.mutation({
      query: (promptId) => ({
        url: `${OPENAI_URL}/${promptId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Prompt'],
    }),
    queryOpenAI: builder.mutation({
      query: (promptData) => ({
        url: `${OPENAI_URL}/query`,
        method: 'POST',
        body: promptData,
      }),
    }),
    formattedPrompt: builder.mutation({
      query: ({ id, ...projectData }) => ({
        url: `${OPENAI_URL}/${id}/formatted`,
        method: 'POST',
        body: projectData,
      }),
    }),
  }),
});

export const {
  useGetPromptsQuery,
  useGetPromptQuery,
  useAddPromptMutation,
  useUpdatePromptMutation,
  useDeletePromptMutation,
  useQueryOpenAIMutation,
  useFormattedPromptMutation,
} = openAiApiSlice;

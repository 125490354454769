import { Spinner } from 'react-bootstrap';

const Loader = ({ isLarge=true }) => {
  return (
    <Spinner
      animation='border'
      role='status'
      style={{
        width: isLarge ? '100px' : '20px',
        height: isLarge ? '100px' : '20px',
        margin: isLarge ? 'auto' : '5px 12px 5px 0px',
        display: 'block',
      }}
    />
  );
};

export default Loader;

// BlockEditScreen.js

import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import Message from '../../../components/utility/Message';
import Loader from '../../../components/utility/Loader';
import FloatingLabelInput from '../../../components/forms/FloatingLabelInput';
import DraggableList from '../../../components/forms/DraggableList';
import { toast } from 'react-toastify';
import {
  useGetBlockByIdQuery,
  useUpdateBlockMutation,
} from '../../../slices/pipelines/blockSlice';
import { useGetJobsQuery } from '../../../slices/pipelines/jobSlice';
import { useGetUIComponentsQuery } from '../../../slices/pipelines/uiComponentSlice';

const BlockEditScreen = () => {
  const { id: blockId } = useParams();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [selectedUIComponents, setSelectedUIComponents] = useState([]);

  // Available options for jobs and UI components
  const [jobs, setJobs] = useState([]);
  const [uiComponents, setUIComponents] = useState([]);

  const {
    data: block,
    isLoading: isBlockLoading,
    error,
    refetch,
  } = useGetBlockByIdQuery(blockId);

  const [updateBlock, { isLoading: isUpdating }] = useUpdateBlockMutation();
  const { data: availableJobs } = useGetJobsQuery();
  const { data: availableUIComponents } = useGetUIComponentsQuery();

  useEffect(() => {
    if (block) {
      setName(block.name);
      setDescription(block.description);
      setSelectedJobs(availableJobs?.filter(job => block.jobs.includes(job._id)) || []);
      setSelectedUIComponents(availableUIComponents?.filter(component => block.uiComponents.includes(component._id)) || []);
    }
  }, [block, availableJobs, availableUIComponents]);

  useEffect(() => {
    if (availableJobs) {
      setJobs(availableJobs);
    }
  }, [availableJobs]);

  useEffect(() => {
    if (availableUIComponents) {
      setUIComponents(availableUIComponents);
    }
  }, [availableUIComponents]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await updateBlock({ 
        id: blockId, 
        name, 
        description, 
        jobs: selectedJobs.map(job => job._id),
        uiComponents: selectedUIComponents.map(component => component._id),
      });
      toast.success('Block updated successfully');
      refetch();
    } catch (error) {
      toast.error(error?.data?.message || error.message);
    }
  };

  return (
    <div>
      <Link to='/admin/blocks' className='link-button'>
        ← Go Back
      </Link>
      <div className='text-center'>
        <h1>Edit Block</h1>
        {isUpdating || isBlockLoading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error?.data?.message || error.message}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <FloatingLabelInput
              id='name'
              type='text'
              label='Name'
              value={name}
              onChange={setName}
              placeholder='Name'
            />
            <FloatingLabelInput
              id='description'
              type='text'
              label='Description'
              value={description}
              onChange={setDescription}
              placeholder='Description'
            />

            <h5>Jobs</h5>
            <DraggableList 
              items={jobs} 
              selectedItems={selectedJobs} 
              setSelectedItems={setSelectedJobs} 
            />

            <h5>UI Components</h5>
            <DraggableList 
              items={uiComponents} 
              selectedItems={selectedUIComponents} 
              setSelectedItems={setSelectedUIComponents} 
            />

            <button className='action-button' type='submit'>
              Update
            </button>
          </Form>
        )}
      </div>
    </div>
  );
};

export default BlockEditScreen;

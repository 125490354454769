import React from 'react';

const NestedTable = ({ items, sectionName }) => {
  const getArrowIcon = (percentChange) => {
    if (percentChange > 0) {
      return <span className="arrow">&#9650;</span>; // ▲
    } else if (percentChange < 0) {
      return <span className="arrow">&#9660;</span>; // ▼
    } else {
      return <span className="arrow">|</span>;
    }
  };

  const shouldExpand = (value) => {
    if (typeof value === 'string' && value.length > 100) {
      return true;
    }
    return false;
  };

  return (
    <div className="grid-card">
      <h3 className="section-name">{sectionName}</h3>
      <div className="nested-grid">
        {items.map((item, index) => {
          const value = item.value;
          const expand = shouldExpand(value);

          if (
            typeof value === 'object' && 
            value !== null && 
            'current' in value && value.current !== null &&
            'percent_change' in value && value.percent_change !== null &&
            'prior' in value && value.prior !== null
          ) {
            return (
              <div className={`grid-item ${expand ? 'expand' : ''}`} key={index}>
                <div className="grid-title">{item.label}</div>
                <div className="grid-value">
                  <div className="current-value">{value.current.toLocaleString()}</div>
                  <div className="prior-section">
                    <div className={`${value.percent_change > 0 ? "positive" : value.percent_change < 0 ? "negative" : "neutral"}`}>
                      {getArrowIcon(value.percent_change)}
                      {value.percent_change}%
                    </div>
                    <div className='neutral'>&nbsp;from {value.prior.toLocaleString()}</div>
                  </div>
                </div>
              </div>
            );
          } else if (typeof value === 'object' && value !== null && 'current' in value && value.current !== null) {
            return (
              <div className={`grid-item ${expand ? 'expand' : ''}`} key={index}>
                <div className="grid-title">{item.label}</div>
                <div className="grid-value">
                  <div className="current-value">{value.current.toLocaleString()}</div>
                </div>
              </div>
            );
          } else {
            return (
              <div className={`grid-item ${expand ? 'expand' : ''}`} key={index}>
                <div className="grid-title">{item.label}</div>
                <div className="grid-value">{typeof value === 'object' ? JSON.stringify(value) : value}</div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default NestedTable;

import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell, LabelList } from 'recharts';

const BarChartVisualization = ({ distribution }) => {
  // Convert the distribution object into an array of objects suitable for recharts
  const data = Object.entries(distribution).map(([key, value]) => ({
    name: value.label,
    value: value.value,
  }));

  // Total for % calculations
  const total = data.reduce((sum, entry) => sum + entry.value, 0);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const { value } = payload[0];
      const percentage = ((value / total) * 100).toFixed(2);
      return (
        <div className="custom-tooltip">
          <p className="tooltip-label"><strong>{label}</strong></p>
          <p className="tooltip-value">{`${value} (${percentage}%)`}</p>
        </div>
      );
    }
    return null;
  };

  const calculateMinWidth = (numColumns) => {
    const refWidth = 550;
    const refColumns = 20;
    return (refWidth / (refColumns)) * (numColumns);
  };

  const minWidth = calculateMinWidth(data.length);

  return (
    <ResponsiveContainer width="100%" height={400} minWidth={minWidth}>
      <BarChart
        layout="horizontal"
        data={data}
        margin={{ top: 5, right: 10, left: 0, bottom: 50 }}
      >
        <XAxis
          dataKey="name"
          type="category"
          tick={{ fontSize: '14px', opacity: '0.8', fill: 'white' }}
          angle={-45} // Slant for x-axis labels
          minTickGap={-200}
          textAnchor="end" // Adjust text anchor for slanted labels
        />
        <YAxis
          type="number"
          tick={{ fontSize: '14px', opacity: '0.8', fill: 'white' }}
        />
        <Tooltip content={<CustomTooltip />} cursor={false} />
        <Bar dataKey="value" fill="var(--primary-color)" radius={[3, 3, 0, 0]}>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} className="bar-cell" />
          ))}
          <LabelList
            dataKey="value"
            position="insideTop"
            formatter={(value) => value}
            style={{ fill: 'white', fontSize: '12px', whiteSpace: 'nowrap' }}
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartVisualization;

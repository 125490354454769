import { apiSlice } from './../apiSlice';
import { PIPELINE_INSTANCES_URL } from '../../constants';

export const pipelineInstancesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPipelineInstances: builder.query({
      query: ({ page = 1, limit = 20, sortField = 'dateUpdated', sortOrder = 'desc', search = '' }) => {
        const queryString = new URLSearchParams({
          page,
          limit,
          sortField,
          sortOrder,
          search
        }).toString();
    
        return `${PIPELINE_INSTANCES_URL}?${queryString}`;
      },
      providesTags: ['PipelineInstance'],
    }),
    getPipelineInstanceById: builder.query({
      query: (id) => `${PIPELINE_INSTANCES_URL}/${id}`,
      providesTags: ['PipelineInstance'],
    }),
    getRowDataByDistinct: builder.mutation({
      query: ({ instanceId, distinctValue }) => ({
        url: `${PIPELINE_INSTANCES_URL}/${instanceId}/row`,
        method: 'POST',
        body: { distinctValue },
      }),
    }),
    createPipelineInstance: builder.mutation({
      query: (instanceData) => ({
        url: PIPELINE_INSTANCES_URL,
        method: 'POST',
        body: instanceData,
      }),
      invalidatesTags: ['PipelineInstance'],
    }),
    updatePipelineInstance: builder.mutation({
      query: ({ id, ...updateData }) => ({
        url: `${PIPELINE_INSTANCES_URL}/${id}`,
        method: 'PUT',
        body: updateData,
      }),
      invalidatesTags: ['PipelineInstance'],
    }),
    duplicatePipelineInstance: builder.mutation({
      query: (id) => ({
        url: `${PIPELINE_INSTANCES_URL}/${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['PipelineInstance'],
    }),
    deletePipelineInstance: builder.mutation({
      query: (id) => ({
        url: `${PIPELINE_INSTANCES_URL}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PipelineInstance'],
    }),
  }),
});

export const {
  useGetPipelineInstancesQuery,
  useGetPipelineInstanceByIdQuery,
  useGetRowDataByDistinctMutation,
  useCreatePipelineInstanceMutation,
  useUpdatePipelineInstanceMutation,
  useDuplicatePipelineInstanceMutation,
  useDeletePipelineInstanceMutation,
} = pipelineInstancesApiSlice;
import React, { useState } from 'react';
import { FaTrash, FaCheck, FaTimes } from 'react-icons/fa';

const Delete = ({ onDelete }) => {
    const [iconState, setIconState] = useState('trash');

    const handleClick = (event, action) => {
        event.stopPropagation();
        action();
    };
    
    const handleDelete = () => {
        onDelete();
        setIconState('trash');
    };

    return (
        <div className="d-flex" style={{ width:'40px' }}>
            {iconState === 'trash' && ( 
                <button 
                    className='fade-btn'
                    type='button'
                    onClick={(e) => handleClick(e, () => setIconState('options'))}
                >
                    <FaTrash />
                </button>
            )}
            {iconState === 'options' && ( 
                <>
                    <button 
                        className='fade-btn'
                        type='button'
                        onClick={(e) => handleClick(e, () => setIconState('trash'))}
                    >
                        <FaTimes />
                    </button>
                    <button 
                        className='fade-btn'
                        type='button'
                        onClick={(e) => handleClick(e, handleDelete)}
                    >
                        <FaCheck />
                    </button>
                </>
            )}
        </div>
    );
};

export default Delete;

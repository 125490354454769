







// TODO: Remove this component







import React from 'react';
import { Button, ListGroup } from 'react-bootstrap';

const DraggableList = ({ items, selectedItems, setSelectedItems }) => {
  const moveItem = (direction, index) => {
    const newSelectedItems = [...selectedItems];
    if (direction === 'up' && index > 0) {
      [newSelectedItems[index], newSelectedItems[index - 1]] = [newSelectedItems[index - 1], newSelectedItems[index]];
    } else if (direction === 'down' && index < newSelectedItems.length - 1) {
      [newSelectedItems[index], newSelectedItems[index + 1]] = [newSelectedItems[index + 1], newSelectedItems[index]];
    }
    setSelectedItems(newSelectedItems);
  };

  const removeSelection = (index) => {
    const newSelectedItems = [...selectedItems];
    newSelectedItems.splice(index, 1);
    setSelectedItems(newSelectedItems);
  };

  const toggleSelection = (item) => {
    const updatedSelectedItems = [...selectedItems, item];
    setSelectedItems(updatedSelectedItems);
  };
  
  const isItemSelected = (item) => selectedItems.some(selectedItem => selectedItem._id === item._id);

  return (
    <div className='floating-label-group'>
      <h5>Selected</h5>
      <ListGroup as="ul">
        {selectedItems.map((item, index) => (
          <ListGroup.Item as="li" key={item._id} className="d-flex justify-content-between align-items-center floating-input border-list-group-item mb-3" style={{ "padding":18 }}>
            {item.name}
            <div>
              <Button variant="light" size="sm" onClick={() => moveItem('up', index)} className="m-1">↑</Button>
              <Button variant="light" size="sm" onClick={() => moveItem('down', index)} className="m-1">↓</Button>
              <Button variant="danger" size="sm" onClick={() => removeSelection(index)} className="m-1">−</Button>
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>

      <h5>Available</h5>
      <ListGroup as="ul" className="mt-3">
        {items.filter(item => !isItemSelected(item)).map((item) => (
          <ListGroup.Item as="li" key={item._id} onClick={() => toggleSelection(item)} className="floating-input mb-3" style={{ "padding":18 }}>
            {item.name}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

export default DraggableList;

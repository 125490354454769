import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  useGetCompetitorsQuery,
  useAddCompetitorMutation,
  useUpdateCompetitorMutation,
  useDeleteCompetitorMutation,
} from '../slices/projectsApiSlice';

export const useCompetitors = (projectId) => {
  const { data: competitors, error, isLoading, refetch } = useGetCompetitorsQuery(projectId);
  const [addCompetitor] = useAddCompetitorMutation();
  const [updateCompetitor] = useUpdateCompetitorMutation();
  const [deleteCompetitor] = useDeleteCompetitorMutation();

  const [localCompetitors, setLocalCompetitors] = useState([]);
  const [focusedCompetitorId, setFocusedCompetitorId] = useState(null);

  useEffect(() => {
    if (competitors) {
      setLocalCompetitors(competitors);
    }
  }, [competitors]);

  const handleAddCompetitor = async (competitor) => {
    const tempCompetitorId = Date.now().toString();
    const tempCompetitor = {
      name: 'New competitor',
      _id: tempCompetitorId,
      isNew: true
    };
  
    setLocalCompetitors(prev => [...prev, tempCompetitor]);
    setFocusedCompetitorId(tempCompetitorId);
  
    try {
      const response = await addCompetitor({ projectId, ...tempCompetitor }).unwrap();
      setLocalCompetitors(prev => prev.map(comp => 
        comp._id === tempCompetitorId ? { ...response, isNew: true } : comp
      ));
      setFocusedCompetitorId(response._id);
    } catch (error) {
      setLocalCompetitors(prev => prev.filter(comp => comp._id !== tempCompetitorId));
      toast.error(error?.data?.message || error.error || 'An error occurred');
    }
  };

  const handleUpdateCompetitor = async (competitorId, field, value) => {
    const updatedCompetitors = localCompetitors.map(competitor => {
        if (competitor._id === competitorId) {
          const updatedCompetitor = { ...competitor, [field]: value, isNew: undefined };
          delete updatedCompetitor.isNew;
          return updatedCompetitor;
        }
        return competitor;
      });
      
      setLocalCompetitors(updatedCompetitors);
    
      try {
        const updatedCompetitorData = updatedCompetitors.find(c => c._id === competitorId);
        await updateCompetitor({ projectId, competitorId, ...updatedCompetitorData }).unwrap();
      } catch (error) {
        toast.error(error?.data?.message || error.error || 'An error occurred');
      }
  };

  const handleDeleteCompetitor = async (competitorId) => {
    setLocalCompetitors(prev => prev.filter(c => c._id !== competitorId));
    try {
      await deleteCompetitor({ projectId, competitorId }).unwrap();
    } catch (error) {
      toast.error(error?.data?.message || error.error || 'An error occurred');
    }
  };

  return {
    localCompetitors,
    focusedCompetitorId,
    setLocalCompetitors,
    setFocusedCompetitorId,
    handleAddCompetitor,
    handleUpdateCompetitor,
    handleDeleteCompetitor,
    error,
    isLoading,
    refetch
  };
};

import React, { useCallback } from "react";
import { Button, InputGroup, FormControl } from 'react-bootstrap';
import Delete from "../../../../components/utility/Delete";
import SelectionDropdown from "../../../../components/forms/SelectionDropdown";
import FloatingLabelInput from "../../../../components/forms/FloatingLabelInput";
import useUIComponentSetup from "../../../../hooks/pipeline-setup/useUIComponentSetup";
import useDictionaryHandlers from "../../../../hooks/pipeline-setup/useDictionaryHandlers";

const UIComponent = React.memo(({
  component,
  block,
  handleBlockChange,
}) => {
  const { 
    handleUIComponentChange,
    moveUIComponent,
    removeUIComponent,
    uiComponentTypes
  } = useUIComponentSetup(component, block, handleBlockChange);

  const { 
    addDictionaryEntry,
    editDictionaryKey,
    editDictionaryValue,
    removeDictionaryEntry
  } = useDictionaryHandlers();

  const setDataKeys = useCallback((newDataKeys) => handleUIComponentChange('dataKeys', newDataKeys), [handleUIComponentChange]);
  const setOptions = useCallback((newOptions) => handleUIComponentChange('options', newOptions), [handleUIComponentChange]);

  const onAddDataKey = useCallback(() => addDictionaryEntry(component.dataKeys, setDataKeys), [component.dataKeys, setDataKeys, addDictionaryEntry]);
  const onAddOption = useCallback(() => addDictionaryEntry(component.options, setOptions), [component.options, setOptions, addDictionaryEntry]);

  return (
    <div className='detail-background table-container mb-3'>
      <div className='d-flex justify-content-between'>
        <div style={{width:"80%"}}>
          <FloatingLabelInput
            placeholder={"Component Name"} 
            value={component.name} 
            onChange={(newValue) => handleUIComponentChange('name', newValue)}
          />
          {uiComponentTypes && (
            <SelectionDropdown 
              label="Component Type" 
              value={component.type} 
              options={uiComponentTypes.map(type => ({ label: type, value: type }))}
              onChange={(e) => handleUIComponentChange('type', e.target.value)}
            />
          )}
        </div>
        <div>
          <Button className="m-1" variant="dark" onClick={() => moveUIComponent(-1)}>↑</Button>
          <Button className="m-1" variant="dark" onClick={() => moveUIComponent(1)}>↓</Button>
        </div>
      </div>
      <div>
        <div className="d-flex align-items-center justify-content-between my-3">
          <h3 className="m-2">Data Keys</h3>
          <Button className="action-button" onClick={onAddDataKey}>Add new</Button>
        </div>
        {component.dataKeys.map((entry, _) => (
          <InputGroup key={`${component._id || component.tempId}-datakey-${entry.id}`} className="mb-2">
            <FormControl
              placeholder="Key"
              defaultValue={entry.key}
              className='floating-input'
              style={{ 'padding': 15 }} 
              onChange={(e) => editDictionaryKey(component.dataKeys, setDataKeys, entry.id, e.target.value)}
            />
            <FormControl
              placeholder="Value"
              defaultValue={entry.value}
              className='floating-input'
              style={{ 'padding': 15 }} 
              onChange={(e) => editDictionaryValue(component.dataKeys, setDataKeys, entry.id, e.target.value)}
            />
            <Button variant="outline-danger" onClick={() => removeDictionaryEntry(component.dataKeys, setDataKeys, entry.id)}>Remove</Button>
          </InputGroup>
        ))}
        <div className="d-flex align-items-center justify-content-between my-3">
          <h3 className="m-2">Options</h3>
          <Button className="action-button" onClick={onAddOption}>
            Add new
          </Button>
        </div>
        {component.options.map((entry, _) => (
          <InputGroup key={`${component._id || component.tempId}-option-${entry.id}`} className="mb-2">
            <FormControl
              placeholder="Option Key"
              defaultValue={entry.key}
              className='floating-input'
              style={{ 'padding':15 }} 
              onChange={(e) => editDictionaryKey(component.options, setOptions, entry.id, e.target.value)}
            />
            <FormControl
              placeholder="Option Value"
              defaultValue={entry.value}
              className='floating-input'
              style={{ 'padding':15 }} 
              onChange={(e) => editDictionaryValue(component.options, setOptions, entry.id, e.target.value)}
            />
            <Button variant="outline-danger" onClick={() => removeDictionaryEntry(component.options, setOptions, entry.id)}>Remove</Button>
          </InputGroup>
        ))}
      </div>

      <Delete onDelete={() => removeUIComponent()} />
    </div>
  );
});

export default UIComponent;
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  useGetFeaturesQuery,
  useAddFeatureMutation,
  useUpdateFeatureMutation,
  useDeleteFeatureMutation,
} from '../slices/projectsApiSlice';

export const useFeatures = (projectId) => {
  const { data: features, error, isLoading, refetch } = useGetFeaturesQuery(projectId);
  const [addFeature] = useAddFeatureMutation();
  const [updateFeature] = useUpdateFeatureMutation();
  const [deleteFeature] = useDeleteFeatureMutation();

  const [localFeatures, setLocalFeatures] = useState([]);
  const [focusedFeatureId, setFocusedFeatureId] = useState(null);

  useEffect(() => {
    if (features) {
      setLocalFeatures(features);
    }
  }, [features]);

  const handleAddFeature = async (feature) => {
    const tempFeatureId = Date.now().toString();
    const tempFeature = {
      name: 'New feature',
      _id: tempFeatureId,
      isNew: true
    };
  
    setLocalFeatures(prev => [...prev, tempFeature]);
    setFocusedFeatureId(tempFeatureId);
  
    try {
      const response = await addFeature({ projectId, ...tempFeature }).unwrap();
      setLocalFeatures(prev => prev.map(feat => 
        feat._id === tempFeatureId ? { ...response, isNew: true } : feat
      ));
      setFocusedFeatureId(response._id);
    } catch (error) {
      setLocalFeatures(prev => prev.filter(feat => feat._id !== tempFeatureId));
      toast.error(error?.data?.message || error.error || 'An error occurred');
    }
  };

  const handleUpdateFeature = async (featureId, field, value) => {
    const updatedFeatures = localFeatures.map(feature => {
        if (feature._id === featureId) {
          const updatedFeature = { ...feature, [field]: value, isNew: undefined };
          delete updatedFeature.isNew;
          return updatedFeature;
        }
        return feature;
      });
    
      setLocalFeatures(updatedFeatures);
    
      try {
        const updatedFeatureData = updatedFeatures.find(f => f._id === featureId);
        await updateFeature({ projectId, featureId, ...updatedFeatureData }).unwrap();
      } catch (error) {
        toast.error(error?.data?.message || error.error || 'An error occurred');
      }
  };

  const handleDeleteFeature = async (featureId) => {
    setLocalFeatures(prev => prev.filter(f => f._id !== featureId));
    try {
      await deleteFeature({ projectId, featureId }).unwrap();
    } catch (error) {
      toast.error(error?.data?.message || error.error || 'An error occurred');
    }
  };

  return {
    localFeatures,
    focusedFeatureId,
    setLocalFeatures,
    setFocusedFeatureId,
    handleAddFeature,
    handleUpdateFeature,
    handleDeleteFeature,
    error,
    isLoading,
    refetch
  };
};

import { BsStars } from "react-icons/bs";
import Loader from "../utility/Loader";

const GenerateButton = ({ onClick, isDisabled, isLoading, defaultText = 'Generate', loadingText = 'Generating...' }) => {
    return (
        <button 
            className="action-button" 
            type="button"
            style={{ margin: '0' }}
            onClick={onClick}
            disabled={isDisabled}
          >
            <div className='d-flex align-items-center'>
                {isLoading ? <Loader margin={'5px'} isLarge={false}/> : <BsStars style={{ marginRight:'5px' }} />} 
                <h3 style={{ margin:'0' }}>{isLoading ? loadingText : defaultText} </h3>
            </div>
        </button>
    );
};

export default GenerateButton;
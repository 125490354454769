import React from 'react';
import { PieChart, Pie, Tooltip, ResponsiveContainer, Cell, Label } from 'recharts';

const PieChartVisualization = ({ distribution }) => {
  // Convert the distribution object into an array of objects suitable for recharts
  let data = Object.entries(distribution).map(([key, value]) => ({
    name: value.label,
    value: value.value,
  }));

  // Total for % calculations
  const total = data.reduce((sum, entry) => sum + entry.value, 0);

  // Sort data by value in descending order
  data.sort((a, b) => b.value - a.value);

  // Group categories into "Other" if there are more than 5 categories
  const MAX_SLICES = 4;
  if (data.length > MAX_SLICES-1) {
    const others = data.slice(MAX_SLICES-1);
    const othersValue = others.reduce((sum, entry) => sum + entry.value, 0);
    data = data.slice(0, MAX_SLICES-1);
    data.push({
      name: 'Other',
      value: othersValue,
      children: others,
    });
  }

  const COLORS = [
    "#3965A6", // Darkest variation
    "#4F83C9", // Darker variation
    "var(--primary-color)",
    "#7CCBFF", // Lighter variation
    "#93E8FF", // Even lighter variation
    "#AAF7FF"  // Lightest variation
  ];  

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, value, children } = payload[0].payload;
      const percentage = ((value / total) * 100).toFixed(2);
      return (
        <div className="custom-tooltip" style={{ maxWidth: '250px' }}>
          <p className="tooltip-label"><strong>{name}</strong></p>
          <p className="tooltip-value">{`${value} (${percentage}%)`}</p>
          {children && (
            <ul style={{ paddingLeft: '15px', fontSize: '12px' }}>
              {children.map((child, index) => {
                const childPercentage = ((child.value / total) * 100).toFixed(2);
                return (
                  <li key={`child-${index}`}>
                    {`${child.name}: ${child.value} (${childPercentage}%)`}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      );
    }
    return null;
  };

  const renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent, index, name
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        style={{ fontSize: '14px' }}
      >
        {name}
      </text>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={400} minWidth={400}>
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={true}
          label={renderCustomizedLabel}
          outerRadius={125}
          fill="var(--primary-color)"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartVisualization;

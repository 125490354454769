import React, { useState, useRef, useEffect } from 'react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import Delete from '../utility/Delete';
import ShortInput from '../forms/ShortInput';
import MultiLineInput from '../forms/MultiLineInput';

const CompetitorCell = ({ competitor, onChange, onDelete, autoFocus }) => {
    const [name, setCompanyName] = useState(competitor.name || '');
    
    // Raw attributes
    const [marketShare, setMarketShare] = useState(competitor.marketShare || '');
    const [revenue, setRevenue] = useState(competitor.revenue || '');
    const [headCount, setHeadCount] = useState(competitor.headCount || '');
    const [valuation, setValuation] = useState(competitor.valuation || '');
    const [headCountGrowth, setHeadCountGrowth] = useState(competitor.headCountGrowth || '');

    // Detail Fields
    const [businessModel, setBusinessModel] = useState(competitor.businessModel || '');
    const [features, setFeatures] = useState(competitor.features || '');
    const [customerExperience, setCustomerExperience] = useState(competitor.customerExperience || '');
    const [marketingSuccessFactors, setMarketingSuccessFactors] = useState(competitor.marketingSuccessFactors || '');
    const [positiveFeedback, setPositiveFeedback] = useState(competitor.positiveFeedback || '');
    const [negativeFeedback, setNegativeFeedback] = useState(competitor.negativeFeedback || '');

    const [showDetails, setShowDetails] = useState(false);
    const nameInputRef = useRef(null);

    const toggleDetails = () => {
        setShowDetails(!showDetails);
    };

    const handleChange = (field, setter, value) => {
        setter(value);
        onChange(competitor._id, field, value);
    };

    useEffect(() => {
        if (autoFocus && nameInputRef.current) {
            nameInputRef.current.focus();
            nameInputRef.current.select();
        }
    }, [autoFocus]);

    const detailFields = [
        {
            id: 'businessModel',
            name: 'Business Model',
            value: businessModel,
            setter: setBusinessModel
        },
        {
            id: 'features',
            name: 'Features',
            value: features,
            setter: setFeatures
        },
        {
            id: 'customerExperience',
            name: 'Customer Experience',
            value: customerExperience,
            setter: setCustomerExperience
        },
        {
            id: 'marketingSuccessFactors',
            name: 'Marketing Success Factors',
            value: marketingSuccessFactors,
            setter: setMarketingSuccessFactors
        },
        {
            id: 'positiveFeedback',
            name: 'Positive Feedback',
            value: positiveFeedback,
            setter: setPositiveFeedback
        },
        {
            id: 'negativeFeedback',
            name: 'Negative Feedback',
            value: negativeFeedback,
            setter: setNegativeFeedback
        }
    ];    

    return (
        <>
            <tr>
                <td>
                    <ShortInput
                        ref={nameInputRef}
                        value={name}
                        onChange={(e) => handleChange('name', setCompanyName, e.target.value)}
                        placeholder='Name'
                    />
                </td>
                <td>
                    <button 
                        className='fade-btn'
                        type='button'
                        onClick={toggleDetails}
                    >
                        {showDetails ? <FaCaretUp /> : <FaCaretDown />}
                    </button>
                </td>
                <td>
                    <ShortInput
                        type='percent'
                        placeholder='Market share'
                        value={marketShare}
                        onChange={(e) => handleChange('marketShare', setMarketShare, e.target.value)}
                    />
                </td>
                <td>
                    <ShortInput
                        type='money'
                        placeholder='Revenue'
                        value={revenue}
                        onChange={(e) => handleChange('revenue', setRevenue, e.target.value)}
                    />
                </td>
                <td>
                    <ShortInput
                        type='number'
                        placeholder='Head count'
                        value={headCount}
                        onChange={(e) => handleChange('headCount', setHeadCount, e.target.value)}
                    />
                </td>
                <td>
                    <ShortInput
                        type='money'
                        placeholder='Valuation'
                        value={valuation}
                        onChange={(e) => handleChange('valuation', setValuation, e.target.value)}
                    />
                </td>
                <td>
                    <Delete onDelete={ () => onDelete(competitor._id) } />
                </td>
            </tr>
            {showDetails && (
                <tr>
                    <td colSpan={7}>
                        <div className="grid">
                            {detailFields.map(field => (
                                <div className="d-flex detail-background" key={field.name}>
                                    <strong 
                                        className='item-name' 
                                        style={{ padding:'10px 10px 5px 10px' }}
                                    >
                                        {field.name}
                                    </strong>
                                    <MultiLineInput
                                        placeholder={field.name}
                                        className="item-description flex-grow-1"
                                        value={field.value}
                                        onChange={(e) => {
                                            handleChange(field.id, field.setter, e.target.value);
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </td>
                </tr>
            )}
        </>
    );
    };

    export default CompetitorCell;

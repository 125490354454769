import React, { useCallback, useEffect, useMemo } from 'react';
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  MarkerType
} from 'reactflow';
import 'reactflow/dist/style.css';
import styled from 'styled-components';
import FeatureNode from './FeatureNode';
import FeatureEdge from './FeatureEdge';

const MiniMapStyled = styled(MiniMap)`
  background-color: #262626;

  .react-flow__minimap-mask {
    fill: rgba(0, 0, 0, 0.25);
  }

  .react-flow__minimap-node {
    fill: gray;
    stroke: none;
  }
`;

const ControlsStyled = styled(Controls)`
  button {
    background-color: #262626;
    color: white;
    border-bottom: 1px solid black;

    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }

    path {
      fill: currentColor;
    }
  }
`;

const calculateDepthOfFeature = (startFeatureId, targetFeatureId, edges) => {
  if (startFeatureId === targetFeatureId) {
    return 0; // Depth is 0 if the target feature is the starting node
  }

  const queue = [{ id: startFeatureId, depth: 0 }];
  const visited = new Set([startFeatureId]);

  while (queue.length > 0) {
    const { id, depth } = queue.shift();

    // Find children from edges
    const children = edges.filter(edge => edge.source === id).map(edge => edge.target);

    for (const childId of children) {
      if (childId === targetFeatureId) {
        return depth + 1; // Return the depth when the target feature is found
      }

      if (!visited.has(childId)) {
        visited.add(childId);
        queue.push({ id: childId, depth: depth + 1 });
      }
    }
  }

  return -1; // Return -1 if the target feature is not reachable from the start
};

const fitViewOptions = {
  padding: 1/3
};
 
const FeaturesFlow = ({ features, onChange }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const proOptions = { hideAttribution: true };

  const nodeTypes = useMemo(() => ({
    featureNode: FeatureNode,
  }), []);

  const edgeTypes = useMemo(() => ({
    custom: (edgeProps) => <FeatureEdge {...edgeProps} onDelete={handleEdgeDelete} />,
  }), []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleEdgeDelete = async (edgeId) => {
    setEdges((eds) => eds.filter((e) => e.id !== edgeId));
    let edgeIdSplit = edgeId.split('-');
    let parentId = edgeIdSplit[0];
    let childId = edgeIdSplit[1];
    let parent = features.find(feature => feature._id === parentId);
    if (parent) {
      const updatedChildren = parent.children.filter(child => child !== childId);
      onChange(parentId, 'children', updatedChildren);
    }
  };

  const onConnect = useCallback(
    (params) => {
      setEdges((eds) => addEdge({ ...params, type: 'custom' }, eds));
      const { source: parentFeatureId, target: childFeatureId } = params;
      const parentFeature = features.find(feature => feature._id === parentFeatureId);

      if (parentFeature) {
        const updatedChildren = parentFeature.children ? [...parentFeature.children, childFeatureId] : [childFeatureId];
        onChange(parentFeatureId, 'children', updatedChildren);
      }
    },
    [setEdges, features, onChange],
  );  

  useEffect(() => {
    if (features && features.length > 0) {
      const spacingBetweenNodes = 50;

      const horizontalPadding = 50;
      const fontSize = 16;
      const estimatedCharacterWidth = fontSize * 0.6;

      const getNodeWidth = (featureName) => {
        return featureName.length * estimatedCharacterWidth + horizontalPadding;
      };
  
      const allChildIds = new Set(features.flatMap(feature => feature.children || []));
  
      const parentChildEdges = features.flatMap(feature => {
        return (feature.children || []).map(childId => ({
          id: `${feature._id}-${childId}`,
          source: feature._id,
          target: childId,
          type: "custom",
          markerEnd: { type: MarkerType.ArrowClosed },
        }));
      });
  
      const homeEdges = features
        .filter(feature => !allChildIds.has(feature._id))
        .map(feature => ({
          id: `Start-${feature._id}`,
          source: "Start",
          target: feature._id,
          type: "custom",
          markerEnd: { type: MarkerType.ArrowClosed },
        }));
  
      const allEdges = [...parentChildEdges, ...homeEdges];
  
      const nodesByDepth = {};
      const maxDepth = Math.max(...features.map(feature => calculateDepthOfFeature("Start", feature._id, allEdges)));
  
      nodesByDepth[1] = features
        .filter(feature => calculateDepthOfFeature("Start", feature._id, allEdges) === 1)
        .map(feature => feature._id);

      // Adding through parents maintains x-order + prevents edge overlap
      for (let depth = 2; depth <= maxDepth; depth++) {
        nodesByDepth[depth] = nodesByDepth[depth - 1]
          .map(parentId => {
            const parentFeature = features.find(feature => feature._id === parentId);
            return parentFeature && parentFeature.children ? parentFeature.children : [];
          })
          .flat();
      }

      const start = {
        id: "Start",
        type: 'featureNode',
        position: { x: -(4 * fontSize * 1.2 + horizontalPadding) / 2, y: 0 },
        data: { label: "Start", id: "Start", hasTargetHandle: false },
      };
  
      let allNodes = [start];
  
      for (let depth = 0; depth <= maxDepth; depth++) {
        const siblings = nodesByDepth[depth];
        let startX = 0;
  
        siblings && siblings.forEach((featureId, index) => {
          const feature = features.find(feature => feature._id === featureId);
          const nodeWidth = getNodeWidth(feature.name);
          const xPosition = startX;
  
          allNodes.push({
            id: feature._id,
            type: 'featureNode',
            position: {
              x: xPosition,
              y: depth * 150
            },
            data: { label: feature.name, id: feature._id, hasTargetHandle: true },
          });
  
          // Update startX for the next node
          startX += nodeWidth + (index < siblings.length - 1 ? spacingBetweenNodes : 0);
        });
  
        // Centering the nodes at each depth level
        const totalWidth = startX;
        allNodes.forEach(node => {
          if (calculateDepthOfFeature("Start", node.id, allEdges) === depth) {
            node.position.x -= totalWidth / 2;
          }
        });
      }
  
      setNodes(allNodes);
      setEdges(allEdges);
    }
  }, [features, setNodes, setEdges]);

  return (
    <div 
      className='input-background input-container' 
      style={{ width: '100%', height: '50vh', marginTop:'40px' }}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        deleteKeyCode={9999}
        proOptions={proOptions}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onConnect={onConnect}
        fitView
        fitViewOptions={fitViewOptions}
      >
        <ControlsStyled />
        {/* <MiniMapStyled /> */}
        <Background variant="dots" gap={20} size={1} />
      </ReactFlow>
    </div>
  );
}

export default FeaturesFlow;
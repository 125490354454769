import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import Loader from '../../components/utility/Loader';
import Message from '../../components/utility/Message';
import FloatingLabelInput from '../../components/forms/FloatingLabelInput';
import SelectionDropdown from '../../components/forms/SelectionDropdown';
import { toast } from 'react-toastify';
import { 
  useGetOrganizationDetailsQuery, 
  useUpdateOrganizationMutation,
  useGetOrganizationStatusOptionsQuery,
} from '../../slices/organizationApiSlice';
import { useGetOrganizationUsersQuery } from '../../slices/userOrganizationAssociationApiSlice';
import { 
  useGetOrganizationPipelineAssociationsQuery, 
  useCreatePipelineAssociationMutation,
  useDeletePipelineAssociationMutation,
} from '../../slices/pipelines/pipelineAssociationSlice';
import { useGetAllPipelinesQuery } from '../../slices/pipelines/pipelineSlice';

const OrganizationEditScreen = () => {
  const { id: organizationId } = useParams();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [status, setStatus] = useState('');

  const { data: statusOptions, loadingStatuses } = useGetOrganizationStatusOptionsQuery();
  const { data: organization, isLoading, error } = useGetOrganizationDetailsQuery(organizationId);
  const { data: users } = useGetOrganizationUsersQuery(organizationId);
  const [updateOrganization] = useUpdateOrganizationMutation();

  // Organization pipeline associations
  const [initialPipelines, setInitialPipelines] = useState([]);
  const [selectedPipelines, setSelectedPipelines] = useState([]);
  const { data: pipelines } = useGetAllPipelinesQuery();
  const { data: pipelineAssociations } = useGetOrganizationPipelineAssociationsQuery(organizationId);
  const [createPipelineAssociation] = useCreatePipelineAssociationMutation();
  const [deletePipelineAssociation] = useDeletePipelineAssociationMutation();

  useEffect(() => {
    document.title = `${name || "Organizations"}`;
  }, []);

  useEffect(() => {
    if (organization) {
      setName(organization.name);
      setEmail(organization.email);
      setPhone(organization.phone);
      setAddress(organization.address);
      setStatus(organization.status);
    }
  }, [organization]);

  useEffect(() => {
    if (pipelineAssociations) {
      const associationsPipelines = pipelineAssociations.map(assoc => assoc.pipeline);
      setSelectedPipelines(associationsPipelines);
      setInitialPipelines(associationsPipelines);
    }
  }, [pipelineAssociations]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await updateOrganization({ id: organizationId, name, email, phone, address, status }).unwrap();
      await updatePipelineAssociations();
      toast.success('Organization updated');
    } catch (error) {
      toast.error(error?.data?.message || error.error || 'An error occurred');
    }
  };

  const updatePipelineAssociations = async () => {
    // Create new associations
    for (const pipelineId of selectedPipelines) {
      if (!initialPipelines.includes(pipelineId)) {
        try {
          await createPipelineAssociation({ pipelineId, organizationId }).unwrap();
        } catch (error) {
          console.error('Failed to create association:', error);
        }
      }
    }

    // Delete removed associations
    for (const pipelineId of initialPipelines) {
      if (!selectedPipelines.includes(pipelineId)) {
        try {
          const assocToDelete = pipelineAssociations.find(assoc => assoc.pipeline === pipelineId);
          if (assocToDelete) {
            await deletePipelineAssociation(assocToDelete._id);
          } else {
            console.error('Association not found');
          }
        } catch (error) {
          console.error('Failed to delete association:', error);
        }
      }
    }
  };

  return (
    <div>
      <Link to='/admin/organizations' className="link-button">
        ← Go Back
      </Link>
      <div className='text-center'>
        <h1>Edit Organization</h1>
        {isLoading ? (
            <Loader />
        ) : error ? (
            <Message variant='danger'>{error?.data?.message || error.error}</Message>
        ) : (
            <Form onSubmit={submitHandler}>
            <FloatingLabelInput
                id='name'
                type='name'
                value={name}
                onChange={setName}
                placeholder='Name'
            />
            <FloatingLabelInput
                id='email'
                type='email'
                value={email}
                onChange={setEmail}
                placeholder='Email'
            />
            <FloatingLabelInput
                id='phone'
                type='phone'
                value={phone}
                onChange={setPhone}
                placeholder='Phone'
            />
            <FloatingLabelInput
                id='address'
                type='text'
                value={address}
                onChange={setAddress}
                placeholder='Address'
            />
            {!loadingStatuses && (
              <SelectionDropdown
                  label='Status'
                  value={status}
                  options={statusOptions}
                  onChange={(e) => setStatus(e.target.value)}
              />
            )}

            <SelectionDropdown
              label="Pipelines"
              value={selectedPipelines}
              onChange={(e) => setSelectedPipelines(Array.from(e.target.selectedOptions, option => option.value))}
              options={pipelines?.map((pipeline) => ({
                label: pipeline.name,
                value: pipeline._id,
              }))}
              multiple={true}
            />

            <div className="floating-label-group floating-input mt-2">
              <ul style={{ listStyleType: 'none', paddingBottom: 15 }}>
                {users?.map((user) => (
                  <li key={user.id}>{user.name} - {user.email}</li>
                ))}
              </ul>
            </div>

            <div className='d-flex justify-content-center'>
                <button className="action-button" type='submit'>
                    Update
                </button>
            </div>
            </Form>
        )}
      </div>
    </div>
  );
};

export default OrganizationEditScreen;

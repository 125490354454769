import React, { useState, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaCaretDown, FaBars, FaTimes } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { useLogoutMutation } from '../../slices/usersApiSlice';
import { useLogout } from '../../logout';
import ActionDropdown from '../forms/ActionDropdown';
import { toast } from 'react-toastify';

const UserMenu = ({ userInfo, logoutHandler, closeMenu }) => {
  const navigate = useNavigate();

  return (
    <ActionDropdown
      label={
        <label>
          {userInfo.name} <FaCaretDown style={{ marginLeft: '5px' }} />
        </label>
      }
      actions={[
        ['Profile', () => {
          closeMenu();
          navigate('/profile');
        }],
        ['Logout', () => {
          logoutHandler();
          closeMenu();
        }]
      ]}
      enableHover={true}
    />
  );
};

const ProjectsMenu = ({ userInfo, closeMenu }) => {
  const navigate = useNavigate();

  if (userInfo.status !== 'active') {
    return null;
  }

  return (
    <Link to='/pipelines' onClick={closeMenu}>
      <div className="action-dropdown-label input" style={{ padding:10 }}>
        <label>
          Dashboard
        </label>
      </div>
    </Link>
  );
};

const AdminMenu = ({ userInfo, closeMenu }) => {
  const navigate = useNavigate();

  if (!userInfo.isAdmin) {
    return null;
  }

  return (
    <ActionDropdown
      label={
        <label>
          Admin <FaCaretDown style={{ marginLeft: '5px' }} />
        </label>
      }
      actions={[
        ['Users', () => {
          closeMenu();
          navigate('/admin/users');
        }],
        ['Organizations', () => {
          closeMenu();
          navigate('/admin/organizations');
        }],
        ['AI Prompts', () => {
          closeMenu();
          navigate('/admin/prompts');
        }],
        // ['Pipelines', () => {
        //   closeMenu();
        //   navigate('/admin/pipelines');
        // }],
        ['Blocks', () => {
          closeMenu();
          navigate('/admin/blocks');
        }],
        ['Jobs', () => {
          closeMenu();
          navigate('/admin/jobs');
        }],
        ['UI Components', () => {
          closeMenu();
          navigate('/admin/uicomponents');
        }]
      ]}
      enableHover={true}
    />
  );
};

const Navbar = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [logoutApiCall] = useLogoutMutation();
  const [scrolled, setScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navbarRef = useRef(); // Ref for the Navbar
  const logout = useLogout();

  // Function to close the menu
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 100;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const logoutHandler = async () => {
    try {
      await logoutApiCall().unwrap();
      navigate('/login');
      dispatch(logout());
    } catch (error) {
      toast.error(error || error.error || error.message || 'An error occurred');
    }
  };

  return (
    <header ref={navbarRef} className={`navbar ${scrolled ? 'scrolled' : ''}`}>
      <Container>
        <div
          className='navbar-logo' 
          onClick={() => {
            closeMenu();
            navigate('/');
          }}
        />
        <div className="menu-icon" onClick={toggleMenu}>
          {isMenuOpen ? <FaTimes /> : <FaBars />} {/* Show X or hamburger icon */}
        </div>
        <div className={`button-group ${isMenuOpen ? 'active' : ''}`}>
          {userInfo ? (
            <>
              <ProjectsMenu userInfo={userInfo} closeMenu={closeMenu} />
              <AdminMenu userInfo={userInfo} closeMenu={closeMenu} />
              <UserMenu userInfo={userInfo} logoutHandler={logoutHandler} closeMenu={closeMenu} />
            </>
          ) : (
            location.pathname === '/login' ? (
              <Link to='/register' className='action-button'>
                Sign Up
              </Link>
            ) : (
              <Link to='/login' className='action-button'>
                Log In
              </Link>
            )
          )}
        </div>

      </Container>
    </header>
  );  
};

export default Navbar;

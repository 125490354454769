import React, { PureComponent } from 'react';
import {
    ResponsiveContainer,
    ComposedChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    Label,
    Tooltip,
    Legend,
} from 'recharts';
import AudiencesTooltip from './AudiencesTooltip';
import { audiencesChartLegendPayload } from './HomeChartData';
import { formatValue } from '../../formatter';

const labelColor = 'rgba(255, 255, 255, 0.65)';

const truncateTickFormatter = (tick, maxChars = 20) => {
    return tick.length > maxChars ? `${tick.substring(0, maxChars)}...` : tick;
};

class AudiencesChart extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: window.innerWidth,
            maxTickChars: 20, // Default value
            tickFontSize: '12px', // Default value
            tickAngle: 0 // Default angle
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.updateTickStyle();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({ screenWidth: window.innerWidth }, () => {
            this.updateTickStyle();
        });
    }

    updateTickStyle = () => {
        const { data } = this.props;
        const { screenWidth } = this.state;
    
        // Flatten the data to get all characters
        const allChars = data.flatMap(item => item.name.split(''));
        const totalCharsLength = allChars.length;
    
        const maxTickChars = this.calculateMaxTickChars(screenWidth, totalCharsLength);
        const tickFontSize = this.calculateTickFontSize(screenWidth, totalCharsLength);
        const tickAngle = this.calculateTickAngle(screenWidth, totalCharsLength);
    
        this.setState({ maxTickChars, tickFontSize, tickAngle });
    }    

    calculateTickAngle = (screenWidth, totalCharsLength) => {
        const charDensity = totalCharsLength / screenWidth;
    
        if (charDensity > 0.15) {
            return 45;
        } else if (charDensity > 0.10) {
            return 30;
        } else {
            return 0;
        }
    }
    
    calculateMaxTickChars = (screenWidth, totalCharsLength) => {
        const avgCharWidth = 1;
        const availableWidthPerChar = screenWidth / totalCharsLength / avgCharWidth;
    
        return availableWidthPerChar;
    }
    
    calculateTickFontSize = (screenWidth, totalCharsLength) => {
        const charDensity = totalCharsLength / screenWidth;
    
        if (charDensity > 0.10) {
            return '10px';
        } else if (charDensity > 0.05) {
            return '12px';
        } else {
            return '14px';
        }
    }    
    
    render() {
        const { data, showStartrAI = false } = this.props;
        const { screenWidth, maxTickChars, tickFontSize, tickAngle } = this.state;
        const isMobile = screenWidth < 768;

        return (
            <div style={{ width: '100%', marginTop:(isMobile ? '10px' : '50px'), height:(isMobile ? '900px' : '25vh') }}>
                <ResponsiveContainer>
                    <ComposedChart
                        layout={isMobile ? 'vertical' : 'horizontal'}
                        data={data}
                        margin={isMobile ? {
                            top: 40,
                            right: 30,
                            bottom: 40,
                            left: -30,
                        } : {
                            top: 15,
                            right: 15,
                            bottom: 15,
                            left: 15,
                        }}
                    >
                        {isMobile ? (
                                <>
                                    <defs>
                                        <linearGradient id="colorPv" x1="1" y1="1" x2="0" y2="1">
                                            <stop offset="5%" stopColor="rgba(50, 87, 127, 0.75)" />
                                            <stop offset="95%" stopColor="rgba(15, 26, 38, 0.5)" />
                                        </linearGradient>
                                    </defs>
                                    <YAxis 
                                        dataKey={"name"} 
                                        type={"category"}
                                        axisLine={false}
                                        tickLine={false}
                                        tick={{ fill: labelColor }}
                                        tickSize={20}
                                        angle={-90}
                                        textAnchor='middle'
                                    />
                                    <XAxis 
                                        xAxisId={"bottom"}
                                        dataKey={"people"} 
                                        type={"number"}
                                        axisLine={false}
                                        tickLine={false}
                                        tick={{ fill: labelColor }}
                                        tickSize={20}
                                        tickFormatter={(value) => formatValue(value, 'compact')}
                                    >
                                        <Label 
                                            value={"People"} 
                                            position={"bottom"}
                                            style={{ textAnchor: 'middle', fill: '#FFFFFF' }}
                                            offset={20}
                                        />
                                    </XAxis>
                                    <XAxis 
                                        xAxisId={"top"}
                                        dataKey={"spending"} 
                                        type={"number"}
                                        axisLine={false}
                                        tickLine={false}
                                        tick={{ fill: labelColor }}
                                        tickSize={20}
                                        orientation={"top"}
                                        tickFormatter={(value) => `${formatValue(value, 'money')}`}
                                    >
                                        <Label 
                                            value={"Similar spending ($/month)"} 
                                            position={"top"}
                                            style={{ textAnchor: 'middle', fill: '#FFFFFF' }}
                                            offset={20}
                                        />
                                    </XAxis>
                                    <Bar
                                        xAxisId={"bottom"}
                                        dataKey={"people"}
                                        name={"People (thousands)"}
                                        fill="url(#colorPv)"
                                        barSize={1000}
                                        radius={5}
                                        padding={{ bottom:'50px' }}
                                    />
                                    <Line 
                                        xAxisId={"top"}
                                        type="monotone"
                                        dataKey={"spending"}
                                        name={"Similar product spending ($/M)"}
                                        stroke="#65AFFE"
                                        strokeWidth={2}
                                    />
                                </>
                            ) : (
                                <>
                                    <Legend 
                                        payload={audiencesChartLegendPayload} 
                                        wrapperStyle={{ marginTop: -45 }}
                                        verticalAlign='top'
                                    />
                                    <defs>
                                        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="rgba(50, 87, 127, 0.75)" />
                                            <stop offset="95%" stopColor="rgba(15, 26, 38, 0.5)" />
                                        </linearGradient>
                                    </defs>
                                    <XAxis 
                                        dataKey={"name"} 
                                        type={"category"}
                                        axisLine={false}
                                        tickLine={false}
                                        tick={{ fill: labelColor, fontSize: tickFontSize, angle: tickAngle }}
                                        tickFormatter={(tick) => truncateTickFormatter(tick, maxTickChars)}
                                        tickSize={20}
                                    />
                                    <YAxis 
                                        yAxisId="right" 
                                        orientation="right" 
                                        type={"number"}
                                        dataKey={"spending"}
                                        axisLine={false}
                                        tickLine={false}
                                        tick={{ fill: labelColor }}
                                        tickFormatter={(value) => `${formatValue(value, 'money')}`}
                                    >
                                        <Label 
                                            value={"Similar spending"} 
                                            angle={90} 
                                            position={"insideRight"}
                                            style={{ textAnchor: 'middle', fill: '#FFFFFF' }}
                                            offset={isMobile ? 0 : 0}
                                        />
                                    </YAxis>
                                    <YAxis 
                                        yAxisId="left" 
                                        type={"number"}
                                        dataKey={null}
                                        axisLine={false}
                                        tickLine={false}
                                        tick={{ fill: labelColor }}
                                        angle={isMobile ? -90 : 0} 
                                        tickFormatter={(value) => formatValue(value, 'compact')}
                                    >
                                        <Label 
                                            value={"People"}
                                            angle={-90}
                                            position={"insideLeft"}
                                            style={{ textAnchor: 'middle', fill: '#FFFFFF' }}
                                            offset={-10}
                                        />
                                    </YAxis>
                                    <Bar
                                        yAxisId={"left"}
                                        dataKey={"people"}
                                        name={"People (thousands)"}
                                        fill="url(#colorPv)"
                                        barSize={200}
                                        radius={5}
                                    />
                                    <Line 
                                        yAxisId={"right"}
                                        type="monotone"
                                        dataKey={"spending"}
                                        name={"Similar product spending ($/M)"}
                                        stroke="#65AFFE"
                                        strokeWidth={2}
                                    />
                                </>
                            )
                        }
                        <Tooltip content={<AudiencesTooltip showStartrAI={showStartrAI} />} />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        );
    }
}

export default AudiencesChart;
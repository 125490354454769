import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Form, Button, InputGroup, FormControl } from 'react-bootstrap';
import Message from '../../../components/utility/Message';
import Loader from '../../../components/utility/Loader';
import FloatingLabelInput from '../../../components/forms/FloatingLabelInput';
import SelectionDropdown from '../../../components/forms/SelectionDropdown';
import { toast } from 'react-toastify';
import {
  useGetUIComponentByIdQuery,
  useUpdateUIComponentMutation,
  useGetUIComponentTypesQuery,
} from '../../../slices/pipelines/uiComponentSlice';

const UIComponentEditScreen = () => {
  const { id: componentId } = useParams();
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [dataKeys, setDataKeys] = useState(['']);
  const [options, setOptions] = useState({});

  const {
    data: component,
    isLoading,
    error,
    refetch,
  } = useGetUIComponentByIdQuery(componentId);

  const { data: componentTypes } = useGetUIComponentTypesQuery();
  const [updateUIComponent, { isLoading: isUpdating }] = useUpdateUIComponentMutation();

  useEffect(() => {
    if (component) {
      setName(component.name);
      setType(component.type);
      setDataKeys(component.dataKeys.length > 0 ? component.dataKeys : ['']);
      const loadedOptions = {};
      for (const [key, value] of Object.entries(component.options || {})) {
        loadedOptions[key] = typeof value === 'object' ? JSON.stringify(value) : value;
      }
      setOptions(loadedOptions);
    }
  }, [component]);

  const handleDataKeyChange = (index, value) => {
    const updatedDataKeys = [...dataKeys];
    updatedDataKeys[index] = value;
    setDataKeys(updatedDataKeys);
  };

  const addDataKey = () => {
    setDataKeys([...dataKeys, '']);
  };

  const removeDataKey = (index) => {
    const updatedDataKeys = dataKeys.filter((_, idx) => idx !== index);
    setDataKeys(updatedDataKeys);
  };

  const handleOptionChange = (key, value) => {
    setOptions({ ...options, [key]: value });
  };

  const addOption = () => {
    setOptions({ ...options, '': '' });
  };

  const removeOption = (keyToRemove) => {
    const updatedOptions = { ...options };
    delete updatedOptions[keyToRemove];
    setOptions(updatedOptions);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const submittedOptions = {};
    for (const [key, value] of Object.entries(options)) {
      try {
        submittedOptions[key] = JSON.parse(value);
      } catch {
        submittedOptions[key] = value;
      }
    }
    try {
      await updateUIComponent({
        id: componentId,
        name,
        type,
        dataKeys: dataKeys.filter(key => key.trim() !== ''),
        options: submittedOptions,
      });
      toast.success('UI Component updated successfully');
      refetch();
    } catch (error) {
      toast.error(error?.data?.message || error.message);
    }
  };

  return (
    <div>
      <Link to='/admin/uicomponents' className='link-button'>
        ← Go Back
      </Link>
      <div className='text-center'>
        <h1>Edit UI Component</h1>
        {isUpdating && <Loader />}
        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error?.data?.message || error.message}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <FloatingLabelInput
              id='name'
              type='text'
              label='Name'
              value={name}
              onChange={setName}
              placeholder='Name'
            />
            {!isLoading && componentTypes && 
              <SelectionDropdown
                label="Type"
                value={type}
                options={componentTypes.map(type => ({ label: type, value: type }))}
                onChange={(e) => setType(e.target.value)}
              />
            }
            <h5>Data Keys</h5>
            {dataKeys.map((dataKey, index) => (
              <InputGroup key={index} className="floating-label-group">
                <Form.Control
                  type="text"
                  value={dataKey}
                  onChange={(e) => handleDataKeyChange(index, e.target.value)}
                  placeholder="Output"
                  className='floating-input'
                  style={{ 'padding':15 }}
                />
                <Button variant="outline-danger" onClick={() => removeDataKey(index)} style={{ 'margin':2 }}>
                  Remove
                </Button>
              </InputGroup>
            ))}
            <Button onClick={addDataKey} className="action-button mb-3">
              Add Data Key
            </Button>

            <h5>Options</h5>
            {Object.entries(options).map(([key, value], index) => (
              <InputGroup className="floating-label-group" key={index}>
              <FormControl
                placeholder="Key"
                aria-label="Option key"
                className='floating-input'
                style={{ 'padding':15 }}
                value={key}
                onChange={(e) => {
                  const newOptions = { ...options };
                  delete newOptions[key];
                  newOptions[e.target.value] = value;
                  setOptions(newOptions);
                }}
              />
              <FormControl
                placeholder="Value"
                aria-label="Option value"
                value={value}
                className='floating-input'
                style={{ 'padding':15 }}
                onChange={(e) => handleOptionChange(key, e.target.value)}
              />
              <Button variant="outline-danger" onClick={() => removeOption(key)} style={{ 'margin':2 }}>
                Remove
              </Button>
            </InputGroup>
          ))}
          <Button onClick={addOption} className="action-button mb-3">
            Add Option
          </Button>

          <div>
            <Button variant="primary" type="submit" className="action-button">
              Update
            </Button>
          </div>
        </Form>
      )}
    </div>
  </div>
);
};

export default UIComponentEditScreen;

import { apiSlice } from './apiSlice';
import { MEMBERSHIPS_URL } from '../constants';

export const userOrgAssociationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserAssociations: builder.query({
      query: (userId) => `${MEMBERSHIPS_URL}/user/${userId}`,
      providesTags: ['UserOrganizationAssociation'],
    }),
    getOrganizationUsers: builder.query({
      query: (organizationId) => `${MEMBERSHIPS_URL}/organization/${organizationId}`,
      providesTags: ['UserOrganizationAssociation'],
    }),
    createUserOrganizationAssociation: builder.mutation({
      query: (associationData) => ({
        url: MEMBERSHIPS_URL,
        method: 'POST',
        body: associationData,
      }),
      invalidatesTags: ['UserOrganizationAssociation'],
    }),
    updateUserOrganizationAssociation: builder.mutation({
      query: ({ id, ...updateData }) => ({
        url: `${MEMBERSHIPS_URL}/${id}`,
        method: 'PUT',
        body: updateData,
      }),
      invalidatesTags: ['UserOrganizationAssociation'],
    }),
    deleteUserOrganizationAssociation: builder.mutation({
      query: (id) => ({
        url: `${MEMBERSHIPS_URL}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['UserOrganizationAssociation'],
    }),
  }),
});

export const {
  useGetUserAssociationsQuery,
  useGetOrganizationUsersQuery,
  useCreateUserOrganizationAssociationMutation,
  useUpdateUserOrganizationAssociationMutation,
  useDeleteUserOrganizationAssociationMutation,
} = userOrgAssociationsApiSlice;

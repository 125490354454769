import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import PrivateRoute from './components/routes/PrivateRoute';
import AdminRoute from './components/routes/AdminRoute';
import HomeScreen from './screens/HomeScreen';
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';
import ProfileScreen from './screens/ProfileScreen';
import UserListScreen from './screens/admin/UserListScreen';
import UserEditScreen from './screens/admin/UserEditScreen';
import ProjectListScreen from './screens/project/ProjectListScreen';
import ProjectScreen from './screens/project/ProjectScreen';
import PromptListScreen from './screens/admin/PromptListScreen';
import PromptEditScreen from './screens/admin/PromptEditScreen';
import OrganizationEditScreen from './screens/admin/OrganizationEditScreen';
import OrganizationListScreen from './screens/admin/OrganizationListScreen';
import PipelineListScreen from './screens/admin/pipelines/PipelineListScreen';
import PipelineEditScreen from './screens/admin/pipelines/PipelineEditScreen';
import BlockListScreen from './screens/admin/pipelines/BlockListScreen';
import BlockEditScreen from './screens/admin/pipelines/BlockEditScreen';
import JobListScreen from './screens/admin/pipelines/JobListScreen';
import JobEditScreen from './screens/admin/pipelines/JobEditScreen';
import UIComponentListScreen from './screens/admin/pipelines/UIComponentListScreen';
import UIComponentEditScreen from './screens/admin/pipelines/UIComponentEditScreen';
import PipelineInstanceListScreen from './screens/pipelines/PipelineInstanceListScreen';
import PipelineInstanceScreen from './screens/pipelines/PipelineInstanceScreen';
import store from './store';
import { Provider } from 'react-redux';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import './assets/styles/main.css';
import ProjectLayout from './layouts/ProjectLayout';
import DefaultLayout from './layouts/DefaultLayout';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App />}>
      {/* Default layout */}
      <Route element={<DefaultLayout />}>
        <Route index element={<HomeScreen />} />
        <Route path='login' element={<LoginScreen />} />
        <Route path='register' element={<RegisterScreen />} />
        <Route element={<PrivateRoute />}>
          <Route path='profile' element={<ProfileScreen />} />
        </Route>
        {/* Admin users */}
        <Route path='admin' element={<AdminRoute />}>
          <Route path='users' element={<UserListScreen />} />
          <Route path='users/:id/edit' element={<UserEditScreen />} />
          <Route path='prompts' element={<PromptListScreen />} />
          <Route path='prompts/:id/edit' element={<PromptEditScreen />} />
          <Route path='organizations' element={<OrganizationListScreen />} />
          <Route path='organizations/:id/edit' element={<OrganizationEditScreen />} />
          <Route path='blocks' element={<BlockListScreen />} />
          <Route path='blocks/:id/edit' element={<BlockEditScreen />} />
          <Route path='jobs' element={<JobListScreen />} />
          <Route path='jobs/:id/edit' element={<JobEditScreen />} />
          <Route path='uicomponents' element={<UIComponentListScreen />} />
          <Route path='uicomponents/:id/edit' element={<UIComponentEditScreen />} />
        </Route>
      </Route>

      {/* Main Dashboard */}
      <Route element={<PrivateRoute />}>
        <Route element={<ProjectLayout />}>
          <Route path='projects'>
            <Route index element={<ProjectListScreen />} />
            <Route path=':id/edit' element={<ProjectScreen />} />
          </Route>
          <Route path='pipelines'>
            <Route index element={<PipelineInstanceListScreen />} />
            <Route path=':pipelineId/instance/:instanceId/edit' element={<PipelineInstanceScreen />} />
          </Route>
          {/* Admin users */}
          <Route path='admin' element={<AdminRoute />}>
            <Route path='pipelines' element={<AdminRoute />}>
              <Route index element={<PipelineListScreen />} />
              <Route path=':id/edit' element={<PipelineEditScreen />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <PayPalScriptProvider deferLoading={true}>
          <RouterProvider router={router} />
        </PayPalScriptProvider>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);

reportWebVitals();
import React, { useEffect } from 'react';
import { Button, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Message from '../../components/utility/Message';
import Loader from '../../components/utility/Loader';
import {
  useGetOrganizationsQuery,
  useCreateOrganizationMutation,
  useDeleteOrganizationMutation,
} from '../../slices/organizationApiSlice';
import Delete from '../../components/utility/Delete';

const OrganizationListScreen = () => {
  const navigate = useNavigate();

  const { data: organizations, refetch, isLoading, error } = useGetOrganizationsQuery();
    const [deleteOrganization] = useDeleteOrganizationMutation();
  const [createOrganization] = useCreateOrganizationMutation();

  useEffect(() => {
    document.title = "Organizations";
  }, []);

  const deleteHandler = async (id) => {
    try {
        await deleteOrganization(id);
        refetch();
    } catch (error) {
        toast.error(error?.data?.message || error.error);
    }
};

  const handleNew = async () => {
    try {
      const response = await createOrganization({ name: 'New Organization', email: 'email@domain.com' });
      // Assume the response includes the organization ID
      const organizationId = response.data._id;
      navigate(`/admin/organizations/${organizationId}/edit`);
    } catch (error) {
      toast.error(error?.data?.message || error.error || 'An error occurred while creating the organization.');
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>Organizations</h1>
        <button className="action-button" onClick={handleNew}>
          + New
        </button>
      </div>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <Table bordered hover responsive className='table-sm'>
          <thead>
            <tr>
              <th>ID</th>
              <th>NAME</th>
              <th>EMAIL</th>
              <th>PHONE</th>
              <th>ADDRESS</th>
              <th>STATUS</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {organizations?.map((organization) => (
              <tr key={organization._id} onClick={() => navigate(`/admin/organizations/${organization._id}/edit`)}>
                <td>{organization._id}</td>
                <td>{organization.name}</td>
                <td>{organization.email}</td>
                <td>{organization.phone}</td>
                <td>{organization.address}</td>
                <td>{organization.status.charAt(0).toUpperCase() + organization.status.slice(1)}</td>
                <td><Delete onDelete={() => deleteHandler(organization._id)} /></td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default OrganizationListScreen;
import React from 'react';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa6';

const SortButton = ({ sortField, currentSortField, sortOrder, onSortChange }) => {
  const isSelected = sortField === currentSortField;
  const sortIcon = isSelected && sortOrder === 'desc' ? <FaArrowUp /> : <FaArrowDown />;
  
  return (
    <button
      className={`subtle-button ${isSelected ? 'selected' : ''}`}
      onClick={() => onSortChange(sortField)}
    >
      {sortIcon}
    </button>
  );
};

export default SortButton;

import React, { useState } from 'react';
import { 
  BaseEdge, 
  EdgeLabelRenderer, 
  getBezierPath 
} from 'reactflow';

const FeatureEdge = ({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, style = {}, markerEnd, onDelete }) => {
  const [isSelected, setIsSelected] = useState(false);
  const [edgePath, labelX, labelY] = getBezierPath({ sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition });

  const onButtonClick = () => {
    onDelete(id);
  };

  const toggleSelect = () => {
    setIsSelected(!isSelected);
  };

  const sourceId = id.split('-')[0];
  const canDelete = sourceId !== 'Start';

  return (
    <>
      <BaseEdge 
        path={edgePath} 
        interactionWidth={20} 
        markerEnd={markerEnd}
        style={{...style, position: 'absolute', pointerEvents: 'all'}} 
        onClick={toggleSelect} 
      />
      {canDelete && 
        <EdgeLabelRenderer>
          <div
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              fontSize: 12,
              pointerEvents: 'all',
            }}
            className="nodrag nopan"
          >
            <button className="edgebutton" onClick={onButtonClick}>
              x
            </button>
          </div>
        </EdgeLabelRenderer>
      }
    </>
  );
};

export default FeatureEdge;

import { useDispatch } from 'react-redux';
import { logout } from './slices/authSlice';
import { apiSlice } from './slices/apiSlice';

export const useLogout = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    // Dispatch the logout action to clear auth state and local storage
    dispatch(logout());

    // Reset API slice state (clearing RTK Query cache)
    dispatch(apiSlice.util.resetApiState());
  };

  return handleLogout;
};
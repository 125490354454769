import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  useGetUspsQuery,
  useAddUspMutation,
  useUpdateUspMutation,
  useDeleteUspMutation,
} from '../slices/projectsApiSlice';

export const useUsps = (projectId) => {
  const { data: usps, error, isLoading, refetch } = useGetUspsQuery(projectId);
  const [addUsp] = useAddUspMutation();
  const [updateUsp] = useUpdateUspMutation();
  const [deleteUsp] = useDeleteUspMutation();

  const [localUsps, setLocalUsps] = useState([]);
  const [focusedUspId, setFocusedUspId] = useState(null);

  useEffect(() => {
    if (usps) {
      setLocalUsps(usps);
    }
  }, [usps]);

  const handleAddUsp = async (usp) => {
    const tempUspId = Date.now().toString();
    const tempUsp = {
      name: 'New USP',
      _id: tempUspId,
      isNew: true
    };

    setLocalUsps(prev => [...prev, tempUsp]);
    setFocusedUspId(tempUspId);

    try {
      const response = await addUsp({ projectId, ...tempUsp }).unwrap();
      setLocalUsps(prev => prev.map(usp => 
        usp._id === tempUspId ? { ...response, isNew: true } : usp
      ));
      setFocusedUspId(response._id);
    } catch (error) {
      setLocalUsps(prev => prev.filter(usp => usp._id !== tempUspId));
      toast.error(error?.data?.message || error.error || 'An error occurred');
    }
  };

  const handleUpdateUsp = async (uspId, field, value) => {
    const updatedUsps = localUsps.map(usp => {
        if (usp._id === uspId) {
          const updatedUsp = { ...usp, [field]: value, isNew: undefined };
          delete updatedUsp.isNew;
          return updatedUsp;
        }
        return usp;
      });
  
      setLocalUsps(updatedUsps);
  
      try {
        const updatedUspData = updatedUsps.find(u => u._id === uspId);
        await updateUsp({ projectId, uspId, ...updatedUspData }).unwrap();
      } catch (error) {
        toast.error(error?.data?.message || error.error || 'An error occurred');
      }
  };

  const handleDeleteUsp = async (uspId) => {
    setLocalUsps(prev => prev.filter(u => u._id !== uspId));
    try {
      await deleteUsp({ projectId, uspId }).unwrap();
    } catch (error) {
      toast.error(error?.data?.message || error.error || 'An error occurred');
    }
  };

  return {
    localUsps,
    focusedUspId,
    setLocalUsps,
    setFocusedUspId,
    handleAddUsp,
    handleUpdateUsp,
    handleDeleteUsp,
    error,
    isLoading,
    refetch
  };
};

import { 
  useGetUIComponentTypesQuery,
} from '../../slices/pipelines/uiComponentSlice';

const useUIComponentSetup = (component, block, handleBlockChange) => {
    // Queries
    const { data: uiComponentTypes } = useGetUIComponentTypesQuery();

    // Handlers
    const addUIComponent = (block, handleBlockChange) => {
      const newComponent = { tempId: `temp-ui-${Date.now()}`, name: '', dataKeys: [], options: [] };
      handleBlockChange(block._id || block.tempId, 'uiComponents', [...block.uiComponents, newComponent]);
    };

    const handleUIComponentChange = (attribute, newValue) => {
      let updatedComponents = block.uiComponents.map((c) => {
        if ((c._id && c._id === component._id) || (c.tempId && c.tempId === component.tempId)) {
          return { ...c, [attribute]: newValue };
        }
        return c;
      });
    
      handleBlockChange(block._id || block.tempId, 'uiComponents', updatedComponents);
    };
  
    const moveUIComponent = (direction) => {
      let currentIndex = block.uiComponents.findIndex(c => (c._id && c._id === component._id) || (c.tempId && c.tempId === component.tempId));
      let newPosition = currentIndex + direction;
    
      // Boundary check
      if (newPosition < 0 || newPosition >= block.uiComponents.length) return;
    
      let updatedComponents = [...block.uiComponents];
      [updatedComponents[currentIndex], updatedComponents[newPosition]] = [updatedComponents[newPosition], updatedComponents[currentIndex]];
      handleBlockChange(block._id || block.tempId, 'uiComponents', updatedComponents);
    };    

    const removeUIComponent = () => {
      let updatedUIComponents = [];
    
      if (!component.tempId) {
        updatedUIComponents = block.uiComponents.map(c => 
          (c.tempId || c._id) === (component.tempId || component._id) ? { ...c, isDeleted: true } : c
        );
      } else {
        updatedUIComponents = block.uiComponents.filter(c => (c.tempId || c._id) !== (component.tempId || component._id));
      }
    
      handleBlockChange(block._id || block.tempId, 'uiComponents', updatedUIComponents);
    };    

    return {
      addUIComponent,
      handleUIComponentChange,
      moveUIComponent,
      removeUIComponent,
      uiComponentTypes,
    };
};

export default useUIComponentSetup;
import React, { useState, useEffect, useRef } from 'react';
import Delete from '../../utility/Delete';
import ShortInput from '../../forms/ShortInput';
import MultiLineInput from '../../forms/MultiLineInput';

const AudienceCard = ({ item, onChange, onDelete, autoFocus }) => {
    const [name, setName] = useState((item && item.name) || '');
    const [description, setDescription] = useState((item && item.description) || '');
    const [size, setSize] = useState((item && item.size) || 0);
    const [similarSpending, setSimilarSpending] = useState((item && item.similarSpending) || 0);
    
    const nameInputRef = useRef(null);

    useEffect(() => {
        if (autoFocus && nameInputRef.current) {
            nameInputRef.current.focus();
            nameInputRef.current.select();
        }
    }, [autoFocus]);

    const handleNameChange = (e) => {
        setName(e.target.value);
        onChange(item._id, 'name', e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
        onChange(item._id, 'description', e.target.value);
    };

    const handleSizeChange = (e) => {
        const newSize = parseInt(e.target.value, 10) || 0;
        setSize(newSize);
        onChange(item._id, 'size', newSize);
    };

    const handleSimilarSpendingChange = (e) => {
        const newSpending = parseInt(e.target.value, 10) || 0;
        setSimilarSpending(newSpending);
        onChange(item._id, 'similarSpending', newSpending);
    };

    return (
        <div className="input-background grid-card">
            <ShortInput
                ref={nameInputRef}
                placeholder='Audience Name'
                className="item-name"
                value={name}
                onChange={handleNameChange}
            />
            <MultiLineInput
                placeholder='Description'
                className="item-description"
                value={description}
                onChange={handleDescriptionChange}
            />
            <div className='d-flex flex-columns'>
                <div>
                    <h6 style={{ fontStyle:'normal', margin: "10px 0px 0px 10px" }}>
                        Size
                    </h6>
                    <ShortInput
                        type="number"
                        placeholder='Size'
                        style={{ marginRight:'5px', width:'150px' }}
                        value={size}
                        onChange={handleSizeChange}
                    />
                </div>
                <div>
                    <h6 style={{ fontStyle:'normal', margin: "10px 0px 0px 10px" }}>
                        Similar Spending
                    </h6>
                    <ShortInput
                        type="money"
                        placeholder='Similar Spending'
                        style={{ width:'150px' }}
                        value={similarSpending}
                        onChange={handleSimilarSpendingChange}
                    />
                </div>
            </div>

            <div style={{ margin:'0px 0px 5px 5px' }}>
                <Delete onDelete={ () => onDelete(item._id) } />
            </div>
        </div>
    );
};

export default AudienceCard;
import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Form, Table, Container } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useProjectApi } from '../../hooks/useProject';
import EmojiPicker from 'emoji-picker-react';
import GenerateButton from '../../components/pipelines/GenerateButton';
import Message from '../../components/utility/Message';
import Loader from '../../components/utility/Loader';
import ParagraphInput from '../../components/forms/ParagraphInput';
import Grid from '../../components/pipelines/grid/DynamicGrid'
import AudienceCard from '../../components/project/grid/AudiencesCard';
import AudiencesChart from '../../components/charts/AudiencesChart';
import UspCard from '../../components/project/grid/UspCard';
import FeatureCard from '../../components/project/grid/FeaturesCard';
import CompetitorCell from '../../components/project/CompetitorCell';
import FeaturesFlow from '../../components/project/FeaturesFlow';
import ShortInput from '../../components/forms/ShortInput';
import useWebSocket from '../../hooks/useWebSocket';
import { useAudiences } from '../../hooks/useAudiences';
import { useCompetitors } from '../../hooks/useCompetitors';
import { useUsps } from '../../hooks/useUsps';
import { useFeatures } from '../../hooks/useFeatures';
import EmojiPickerComponent from '../../components/pipelines/EmojiPickerComponent';

const ProjectEditScreen = () => {
  const { id: projectId } = useParams();
  const socket = useWebSocket();
  const { project, isLoading: isLoadingProject, error: projectError, updateProject } = useProjectApi(projectId);
  const { localAudiences, focusedAudienceId, setLocalAudiences, handleAddAudience, handleUpdateAudience, handleDeleteAudience, error: audienceError, isLoading: isLoadingAudiences, refetch: refetchAudiences } = useAudiences(projectId);
  const { localCompetitors, focusedCompetitorId, setLocalCompetitors, handleAddCompetitor, handleUpdateCompetitor, handleDeleteCompetitor, error: competitorError, isLoading: isLoadingCompetitors, refetch: refetchCompetitors } = useCompetitors(projectId);
  const { localUsps, focusedUspId, setLocalUsps, handleAddUsp, handleUpdateUsp, handleDeleteUsp, error: uspError, isLoading: isLoadingUsps, refetch: refetchUsps } = useUsps(projectId);
  const { localFeatures, focusedFeatureId, setLocalFeatures, handleAddFeature, handleUpdateFeature, handleDeleteFeature, error: featureError, isLoading: isLoadingFeatures, refetch: refetchFeatures } = useFeatures(projectId);

  // State declarations
  const [name, setName] = useState('');
  const [problem, setProblem] = useState('');
  const [emoji, setEmoji] = useState('');

  const [isSaving, setIsSaving] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [pendingUpdates, setPendingUpdates] = useState({ project: {}, entities: [] });

  const [showEmojis, setShowEmojis] = useState(false);
  const emojiPickerRef = useRef(null);
  const emojiButtonRef = useRef(null);
  const nameRef = useRef(null);

  // AI State declarations
  const [isGeneratingAudiences, setIsGeneratingAudiences] = useState(false);
  const [isGeneratingCompetitors, setIsGeneratingCompetitors] = useState(false);
  const [isGeneratingUsps, setIsGeneratingUsps] = useState(false);
  const [isGeneratingFeatures, setIsGeneratingFeatures] = useState(false);

  const error = projectError?.data?.message || projectError?.error || 
                audienceError?.data?.message || audienceError?.error ||               
                competitorError?.data?.message || competitorError?.error || 
                uspError?.data?.message || uspError?.error || 
                featureError?.data?.message || featureError?.error;

  const audienceTableData = localAudiences.map(audience => {
    return {
        name: audience.name,
        spending: audience.similarSpending,
        people: audience.size,
        description: audience.description
      };
  });

  useEffect(() => {
    document.title = `${name || "Projects"}`;
  }, [name]);

  const onEmojiClick = (event) => {
    updateProjectDetail('emoji', event.emoji);
    setShowEmojis(!showEmojis);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target) &&
          emojiButtonRef.current && !emojiButtonRef.current.contains(event.target)) {
        setShowEmojis(false);
      }
    };
  
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [emojiPickerRef, emojiButtonRef]);  
  
  // Handlers
  const handleFocus = () => {
    setIsInputFocused(true);
  };
  
  const handleBlur = () => {
    setIsInputFocused(false);
  };

  // Use for initial load
  useEffect(() => {
    if ((!isInputFocused || (name !== '' && !name)) && project && !isLoadingProject && !isSaving) {
      setName(project.name);
      setProblem(project.problem);
      setEmoji(project.emoji);
    }
  }, [project]); // eslint-disable-line react-hooks/exhaustive-deps

  // When a text input us changed
  const updateProjectDetail = (field, value) => {
    const updateField = {
      name: setName,
      problem: setProblem,
      emoji: setEmoji,
    };

    if (field==='name') {
      adjustInputWidth();
    }
    
    if (field==='name' || field==='problem' || field==='emoji') {
      updateField[field](value);
    }
    
    // Queue for backend update
    setPendingUpdates(prev => ({
      ...prev,
      project: { ...prev.project, [field]: value },
    }));
  };

  const queueEntityUpdate = (entityType, entityId, changes) => {
    switch (entityType) {
      case 'audiences':
        setLocalAudiences(current => current.map(item => item._id === entityId ? { ...item, ...changes } : item));
        break;
      case 'competitors':
        setLocalCompetitors(current => current.map(item => item._id === entityId ? { ...item, ...changes } : item));
        break;
      case 'usps':
        setLocalUsps(current => current.map(item => item._id === entityId ? { ...item, ...changes } : item));
        break;
      case 'features':
        setLocalFeatures(current => current.map(item => item._id === entityId ? { ...item, ...changes } : item));
        break;
      default:
        console.warn(`Unhandled entity type: ${entityType}`);
    }

    setPendingUpdates(prev => {
      const entityTypeUpdates = prev.entities[entityType] || {};
      const existingUpdates = entityTypeUpdates[entityId] || {};
  
      const updatedEntityUpdates = {
        ...existingUpdates,
        ...changes,
      };
  
      const newEntities = {
        ...prev.entities,
        [entityType]: {
          ...entityTypeUpdates,
          [entityId]: updatedEntityUpdates,
        },
      };
  
      return {
        ...prev,
        entities: newEntities,
      };
    });
  };

  // Send queued updates to the backend
  const sendUpdates = async () => {
    if (Object.keys(pendingUpdates.project).length > 0) {
      try {
        await updateProject({ projectId, ...pendingUpdates.project });
      } catch (error) {
        toast.error(`Error updating project details: ${error.message}`);
      }
    }
  
    for (const entityType in pendingUpdates.entities) {
      const entitiesByType = pendingUpdates.entities[entityType];
  
      for (const entityId in entitiesByType) {
        const updates = entitiesByType[entityId];
  
        for (const [field, value] of Object.entries(updates)) {
          try {
            const updateFunctionMap = {
              audiences: handleUpdateAudience,
              competitors: handleUpdateCompetitor,
              usps: handleUpdateUsp,
              features: handleUpdateFeature,
            };
  
            const updateFunction = updateFunctionMap[entityType];
  
            if (updateFunction) {
              await updateFunction(entityId, field, value);
            }
          } catch (error) {
            toast.error(`Error updating ${entityType.slice(0, -1)}: ${error.message}`);
          }
        }
      }
    }
  
    // Clear pending updates after processing
    setIsSaving(false);
    setPendingUpdates({ project: {}, entities: {} });
  };

  // Automatically send updates after a delay
  useEffect(() => {
    const timer = setTimeout(() => {
      const hasProjectUpdates = Object.keys(pendingUpdates.project).length > 0;
      const hasEntityUpdates = Object.keys(pendingUpdates.entities).some(entityType => Object.keys(pendingUpdates.entities[entityType]).length > 0);
  
      if (hasProjectUpdates || hasEntityUpdates) {
        setIsSaving(true);
        sendUpdates();
      }
    }, 500);
    
    return () => clearTimeout(timer);
  }, [pendingUpdates]); // eslint-disable-line react-hooks/exhaustive-deps

  // Send any pending updates before unmount
  useEffect(() => {
    return () => {
      if (Object.keys(pendingUpdates.project).length > 0 || pendingUpdates.entities.length > 0) {
        sendUpdates();
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const adjustInputWidth = () => {
    if (nameRef.current) {
      const context = document.createElement('canvas').getContext('2d');
      context.font = '30px Montserrat';
      const textWidth = context.measureText(name || 'Project name').width;
      nameRef.current.style.width = `${textWidth + 40}px`; // 40px for padding
    }
  };

  useEffect(() => {
    adjustInputWidth();
  }, [name]);

  // Clean up websocket listeners to prevent memory leaks
  useEffect(() => {
    return () => {
      if (socket) {
        socket.off('queryResponse');
        socket.off('queryError');
      }
    };
  }, [socket]);

  // Generate all handlers

  const [isGeneratingAll, setIsGeneratingAll] = useState(false);
  
  const disableGenerateAll = isGeneratingAudiences || isGeneratingCompetitors || 
                             isGeneratingUsps || isGeneratingFeatures || isGeneratingAll ||
                             !problem || problem.length === 0;

  const handleGenerateAll = async () => {
    setIsGeneratingAll(true);
    try {
      await onClickGenerateAudiences();
      await onClickGenerateCompetitors();
      await onClickGenerateUsps();
      await onClickGenerateFeatures();
    } catch (error) {
      toast.error('Error generating all: ' + error.message);
    }
    setIsGeneratingAll(false);
  }

  const disableGenerateAudiences = isGeneratingAudiences || isGeneratingCompetitors || 
                                    isGeneratingUsps || isGeneratingFeatures || isGeneratingAll ||
                                    !problem || problem.length === 0;

  const handleGenerateAudiences = () => {
    return new Promise((resolve, reject) => {
      socket.emit('queryOpenAI', { type: 'audience_analysis', projectId: projectId });

      socket.once('queryResponse', (response) => {
        if (response.error) {
          reject(new Error(response.error.data?.message));
        } else {
          resolve(response);
        }
      });

      socket.once('queryError', (error) => {
        reject(new Error(error));
      });
    });
  };

  const onClickGenerateAudiences = async () => {
    setIsGeneratingAudiences(true);
    try {
      try {
        const generatedAudiences = await handleGenerateAudiences();
        setLocalAudiences(prev => [...prev, ...generatedAudiences]);
        refetchAudiences();
      } catch (error) {
        toast.error('Error generating audiences: ' + error);
      }
    } finally {
      setIsGeneratingAudiences(false);
    }
  };

  const disableGenerateCompetitors = isGeneratingAudiences || isGeneratingCompetitors || 
                                      isGeneratingUsps || isGeneratingFeatures || isGeneratingAll ||
                                      !problem || problem.length === 0 ||
                                      !localAudiences || localAudiences.length === 0;

  const handleGenerateCompetitors = () => {
    return new Promise((resolve, reject) => {
      socket.emit('queryOpenAI', { type: 'competitor_analysis', projectId: projectId });
      
      socket.once('queryResponse', (response) => {
        if (response.error) {
          reject(new Error(response.error.data?.message));
        } else {
          resolve(response);
        }
      });

      socket.once('queryError', (error) => {
        reject(new Error(error));
      });
    });
  };

  const onClickGenerateCompetitors = async () => {
    setIsGeneratingCompetitors(true);
    try {
      try {
        const generatedCompetitors = await handleGenerateCompetitors();
        setLocalCompetitors(prev => [...prev, ...generatedCompetitors]);
        refetchCompetitors();
      } catch (error) {
        toast.error('Error generating competitors: ' + error);
      }
    } finally {
      setIsGeneratingCompetitors(false);
    }
  };

  const disableGenerateUsps = isGeneratingAudiences || isGeneratingCompetitors || 
                              isGeneratingUsps || isGeneratingFeatures || isGeneratingAll ||
                              !problem || problem.length === 0 ||
                              !localAudiences || localAudiences.length === 0 ||
                              !localCompetitors || localCompetitors.length === 0;

  const handleGenerateUsps = () => {
    return new Promise((resolve, reject) => {
      socket.emit('queryOpenAI', { type: 'usp_analysis', projectId: projectId });

      socket.once('queryResponse', (response) => {
        if (response.error) {
          reject(new Error(response.error.data?.message));
        } else {
          resolve(response);
        }
      });

      socket.once('queryError', (error) => {
        reject(new Error(error));
      });
    });
  };

  const onClickGenerateUsps = async () => {
    setIsGeneratingUsps(true);
    try {
      try {
        const generatedUsps = await handleGenerateUsps();
        setLocalUsps(prev => [...prev, ...generatedUsps]);
        refetchUsps();
      } catch (error) {
        toast.error('Error generating usps: ' + error);
      }
    } finally {
      setIsGeneratingUsps(false);
    }
  };

  const disableGenerateFeatures = isGeneratingAudiences || isGeneratingCompetitors || 
                                  isGeneratingUsps || isGeneratingFeatures || isGeneratingAll ||
                                  !problem || problem.length === 0 ||
                                  !localAudiences || localAudiences.length === 0 ||
                                  !localCompetitors || localCompetitors.length === 0 ||
                                  !localUsps || localUsps.length === 0;

  const handleGenerateFeatures = () => {
    return new Promise((resolve, reject) => {
      socket.emit('queryOpenAI', { type: 'feature_analysis', projectId: projectId });

      socket.once('queryResponse', (response) => {
        if (response.error) {
          reject(new Error(response.error.data?.message));
        } else {
          resolve(response);
        }
      });

      socket.once('queryError', (error) => {
        reject(new Error(error));
      });
    });
  };

  const onClickGenerateFeatures = async () => {
    setIsGeneratingFeatures(true);
    try {
      try {
        const generatedFeatures = await handleGenerateFeatures();
        setLocalFeatures(prev => [...prev, ...generatedFeatures]);
        refetchFeatures();
      } catch (error) {
        toast.error('Error generating features: ' + error);
      }
    } finally {
      setIsGeneratingFeatures(false);
    }
  };

  return (
    <Container className='project-layout'>
      {error && <Message variant='danger'>{error}</Message>}
      <Form>
        {isLoadingProject ? <Loader /> : 
          <div>
            {/* Navigation */}
            <div className='project-section-title'>
              <Link to='/projects' className="link-button">Home</Link>
              <span style={{ fontSize: '14px' }}> / {name} </span>
              {isSaving ? <span className='save-text'> Saving changes</span> : <></>}
              {isLoadingProject ? <span className='save-text'> Loading project</span> : <></>}
            </div>

            {/* Emoji */}
            <EmojiPickerComponent
              onEmojiSelect={(emoji) => updateProjectDetail('emoji', emoji)}
              triggerClass='emoji-lg'
              selectedEmoji={emoji}
            />

            {/* Name */}
            <div className='d-flex align-items-center justify-content-between'>
              <ShortInput 
                ref={nameRef}
                className='project-title'
                value={name || ''}
                style={{ margin: '5px 0px'}}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={(e) => updateProjectDetail('name', e.target.value)}
                placeholder='Project name'
              />
              <GenerateButton 
                  onClick={handleGenerateAll}
                  isDisabled={disableGenerateAll}
                  isLoading={isGeneratingAll}
                  defaultText='Generate all'
              />
            </div>
            
            {/* Problem */}
            <div className='project-section-title'>
              <h3>Problem</h3>
              <h6>What is the problem?</h6>
            </div>
            <ParagraphInput
              id='problem'
              value={problem}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={(e) => updateProjectDetail('problem', e.target.value)}
              placeholder='Problem'
            />
          </div>
        }

        {/* Audiences */}
        <div className='project-section-title d-flex align-items-center justify-content-between'>
          <div>
            <h3>Audiences</h3>
            <h6>Who has the problem? (Note: generated statistics are estimates)</h6>
          </div>
          <GenerateButton
            onClick={onClickGenerateAudiences}
            isDisabled={disableGenerateAudiences}
            isLoading={isGeneratingAudiences}
          />
        </div>
        <div className="input-container">
          {isLoadingAudiences ? <Loader /> : 
            <div>
              <Grid
                  items={localAudiences}
                  focusedItemId={focusedAudienceId}
                  onChange={(itemId, fieldName, newValue) => {
                    queueEntityUpdate('audiences', itemId, { [fieldName]: newValue });
                  }}
                  onDelete={handleDeleteAudience}
                  onAdd={handleAddAudience}
                  CardComponent={AudienceCard}
              />
              {localAudiences.length > 0 && 
                <div style={{ marginTop:'40px' }}>
                  <div className='input-background table-container d-flex flex-column align-items-center'>
                    <h4 style={{ marginTop:'5px' }}>Audience Demographics</h4>
                    <AudiencesChart data={audienceTableData} />
                  </div>
                </div>
              }
            </div>
          }
        </div>



        {/* Competitors */}
        <div className='project-section-title d-flex justify-content-between align-items-center'>
          <div>
            <h3>Competitors</h3>
            <h6>Who has tried to fix problem? (Note: generated statistics are estimates)</h6>
          </div>
          <GenerateButton 
            onClick={onClickGenerateCompetitors}
            isDisabled={disableGenerateCompetitors}
            isLoading={isGeneratingCompetitors}
          />
        </div>
        <div className="input-container">
          <div className='input-background table-container'>
            {isLoadingCompetitors ? <Loader /> : 
              <Table bordered responsive className='no-action-table'>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th></th>
                    <th>Market Share</th>
                    <th>Revenue</th>
                    <th>Head Count</th>
                    <th>Valuation</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {localCompetitors.map((competitor, index) => (
                    <CompetitorCell
                      key={competitor._id}
                      competitor={competitor}
                      onChange={(itemId, fieldName, newValue) => {
                        queueEntityUpdate('competitors', itemId, { [fieldName]: newValue });
                      }}
                      onDelete={handleDeleteCompetitor}
                      autoFocus={focusedCompetitorId && competitor._id === focusedCompetitorId}
                    />
                  ))}
                </tbody>
              </Table>
            }
            <button className='subtle-button' type="button" onClick={handleAddCompetitor}>+ Add new</button>
          </div>
        </div>

        {/* Unique selling proposition */}
        <div className='project-section-title d-flex justify-content-between align-items-center'>
          <div>
            <h3>Unique Selling Proposition</h3>
            <h6>What unique value does your product provide?</h6>
          </div>
          <GenerateButton 
            onClick={onClickGenerateUsps}
            isDisabled={disableGenerateUsps}
            isLoading={isGeneratingUsps}
          />
        </div>
        <div className="input-container">
          {isLoadingUsps ? <Loader /> : 
            <div>
              <Grid
                  items={localUsps}
                  audiences={localAudiences}
                  focusedItemId={focusedUspId}
                  onChange={(itemId, fieldName, newValue) => {
                    queueEntityUpdate('usps', itemId, { [fieldName]: newValue });
                  }}
                  onDelete={handleDeleteUsp}
                  onAdd={handleAddUsp}
                  CardComponent={UspCard}
              />
            </div>
          }
        </div>
        
        {/* Features */}
        <div className='project-section-title d-flex justify-content-between align-items-center'>
          <div>
            <h3>Features</h3>
            <h6>How will your product solve the problem?</h6>
          </div>
          <GenerateButton 
            onClick={onClickGenerateFeatures}
            isDisabled={disableGenerateFeatures}
            isLoading={isGeneratingFeatures}
          />
        </div>
        <div className="input-container">
          {isLoadingFeatures ? <Loader /> : 
            <div>
              <Grid
                  items={localFeatures}
                  focusedItemId={focusedFeatureId}
                  onChange={(itemId, fieldName, newValue) => {
                    queueEntityUpdate('features', itemId, { [fieldName]: newValue });
                  }}
                  onDelete={handleDeleteFeature}
                  onAdd={handleAddFeature}
                  CardComponent={FeatureCard}
              />
              {localFeatures.length > 0 && 
                <FeaturesFlow 
                  features={localFeatures} 
                  onChange={(itemId, fieldName, newValue) => {
                    queueEntityUpdate('features', itemId, { [fieldName]: newValue });
                  }}
                />
              }
            </div>
          }
        </div>
      </Form>
    </Container>
  );
};

export default ProjectEditScreen;
import { apiSlice } from './../apiSlice';
import { UI_COMPONENTS_URL } from '../../constants';

export const uiComponentsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUIComponents: builder.query({
      query: () => `${UI_COMPONENTS_URL}`,
      providesTags: ['UIComponent'],
    }),
    getUIComponentsByBlock: builder.query({
      query: (blockId) => `${UI_COMPONENTS_URL}/block/${blockId}`,
      providesTags: ['UIComponent'],
    }),
    getUIComponentById: builder.query({
      query: (id) => `${UI_COMPONENTS_URL}/${id}`,
      providesTags: ['UIComponent'],
    }),
    createUIComponent: builder.mutation({
      query: (componentData) => ({
        url: UI_COMPONENTS_URL,
        method: 'POST',
        body: componentData,
      }),
      invalidatesTags: ['UIComponent'],
    }),
    updateUIComponent: builder.mutation({
      query: ({ id, ...updateData }) => ({
        url: `${UI_COMPONENTS_URL}/${id}`,
        method: 'PUT',
        body: updateData,
      }),
      invalidatesTags: ['UIComponent'],
    }),
    deleteUIComponent: builder.mutation({
      query: (id) => ({
        url: `${UI_COMPONENTS_URL}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['UIComponent'],
    }),
    getUIComponentTypes: builder.query({
      query: () => `${UI_COMPONENTS_URL}/types`,
    }),
  }),
});

export const {
  useGetUIComponentsQuery,
  useGetUIComponentsByBlockQuery,
  useGetUIComponentByIdQuery,
  useCreateUIComponentMutation,
  useUpdateUIComponentMutation,
  useDeleteUIComponentMutation,
  useGetUIComponentTypesQuery,
} = uiComponentsApiSlice;
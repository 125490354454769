import { apiSlice } from '../apiSlice';
import { PIPELINE_ASSOCIATIONS_URL } from '../../constants';

export const pipelineAssociationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPipelineAssociations: builder.query({
      query: (pipelineId) => `${PIPELINE_ASSOCIATIONS_URL}/pipeline/${pipelineId}`,
      providesTags: ['PipelineAssociation'],
    }),
    getUserPipelineAssociations: builder.query({
        query: (userId) => `${PIPELINE_ASSOCIATIONS_URL}/user/${userId}`,
        providesTags: ['PipelineAssociation'],
    }),
    getOrganizationPipelineAssociations: builder.query({
        query: (organizationId) => `${PIPELINE_ASSOCIATIONS_URL}/organization/${organizationId}`,
        providesTags: ['PipelineAssociation'],
    }),
    createPipelineAssociation: builder.mutation({
      query: (associationData) => ({
        url: PIPELINE_ASSOCIATIONS_URL,
        method: 'POST',
        body: associationData,
      }),
      invalidatesTags: ['PipelineAssociation'],
    }),
    updatePipelineAssociation: builder.mutation({
      query: ({ id, ...updateData }) => ({
        url: `${PIPELINE_ASSOCIATIONS_URL}/${id}`,
        method: 'PUT',
        body: updateData,
      }),
      invalidatesTags: ['PipelineAssociation'],
    }),
    deletePipelineAssociation: builder.mutation({
      query: (id) => ({
        url: `${PIPELINE_ASSOCIATIONS_URL}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PipelineAssociation'],
    }),
  }),
});

export const {
  useGetPipelineAssociationsQuery,
  useGetUserPipelineAssociationsQuery,
  useGetOrganizationPipelineAssociationsQuery,
  useCreatePipelineAssociationMutation,
  useUpdatePipelineAssociationMutation,
  useDeletePipelineAssociationMutation,
} = pipelineAssociationsApiSlice;
const formatCompact = (value) => {
    const formatWithOptionalDecimal = (number, divisor, suffix) => {
        const dividedNumber = number / divisor;
        // Extracting the significant decimal digit
        const significantDecimal = Math.round(dividedNumber * 10) % 10;

        return significantDecimal !== 0
            ? `${dividedNumber.toFixed(1)}${suffix}`
            : `${Math.round(dividedNumber)}${suffix}`;
    };

    const num = Number(value);
    if (isNaN(num)) return "0"; // Return "0" if value is not a number

    if (num >= 1000000000000) {
        return formatWithOptionalDecimal(num, 1000000000000, 'T'); // Trillions
    } else if (num >= 1000000000) {
        return formatWithOptionalDecimal(num, 1000000000, 'B'); // Billions
    } else if (num >= 1000000) {
        return formatWithOptionalDecimal(num, 1000000, 'M'); // Millions
    } else if (num >= 1000) {
        return formatWithOptionalDecimal(num, 1000, 'K'); // Thousands
    }

    // Handle values less than 1000 with a fixed two decimal places
    return num % 1 !== 0 ? `${num.toFixed(2)}` : `${Math.round(num)}`;
};

const formatNumber = (value) => {
    const num = Number(value);
    if (isNaN(num)) return "0";
    return num.toLocaleString();
};

export const formatValue = (value, type) => {
    switch (type) {
        case 'percent':
            return `${value}%`;
        case 'money':
            return `$${formatCompact(value)}`;
        case 'number':
            return formatNumber(value);
        case 'compact':
            return formatCompact(value);
        default:
            return value;
    }
};

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { setCredentials, logout } from './slices/authSlice';
import { useProfileMutation } from './slices/usersApiSlice';
import { ToastContainer, toast } from 'react-toastify';
import { debounce } from 'lodash';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const dispatch = useDispatch();
  const [profile] = useProfileMutation();

  useEffect(() => {
    const expirationTime = localStorage.getItem('expirationTime');
    if (expirationTime) {
      const currentTime = new Date().getTime();

      if (currentTime > expirationTime) {
        dispatch(logout());
        return;
      }
    }

    profile().unwrap()
      .then((updatedUserInfo) => {
        dispatch(setCredentials({ ...updatedUserInfo }));
      })
      .catch((error) => console.error('Error fetching profile:', error));
  }, [dispatch, profile]);

  const debouncedToast = debounce(() => {
    toast.info('For the best experience, we recommend using this application on a larger screen.', {
      position: "top-center",
      autoClose: 30000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }, 100); // 100 ms window for debouncing

  useEffect(() => {
    const mobileWidthThreshold = 768;
    if (window.innerWidth <= mobileWidthThreshold) {
      debouncedToast();
    }
  }, []);

  return (
    <>
      <ToastContainer />
      <Outlet />
    </>
  );
};

export default App;
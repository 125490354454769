import { useEffect, useState, useRef } from 'react';
import { InputGroup, FormControl, Table } from 'react-bootstrap';
import { IoSearchOutline } from "react-icons/io5";
import { FaEllipsisVertical, FaXmark } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import Message from '../../components/utility/Message';
import Loader from '../../components/utility/Loader';
import ActionDropdown from '../../components/forms/ActionDropdown';
import {
  useDeleteProjectMutation,
  useGetProjectsQuery,
  useDuplicateProjectMutation,
} from '../../slices/projectsApiSlice';
import { toast } from 'react-toastify';
import SortButton from '../../components/utility/SortButton';
import { useGetPipelineInstancesQuery } from '../../slices/pipelines/pipelineInstanceSlice';

const PROJECT_LIMIT = 20;

const ProjectListScreen = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [sortField, setSortField] = useState('dateUpdated'); // 'name' or 'dateUpdated'
  const [sortOrder, setSortOrder] = useState('desc'); // 'asc' or 'desc'
  const [hasMore, setHasMore] = useState(false);
  const [showBottomLoading, setShowBottomLoading] = useState(false);
  const isRequestPending = useRef(false);
  const [projects, setProjects] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const { data, refetch, isLoading, error } = useGetProjectsQuery({ page, limit: PROJECT_LIMIT, sortField, sortOrder, search: searchQuery });
  const [deleteProject] = useDeleteProjectMutation();
  const [duplicateProject] = useDuplicateProjectMutation();
  const [deletingProjects, setDeletingProjects] = useState(new Set());
  const [isDuplicating, setIsDuplicating] = useState(false);

  const { data: pipelineInstanceData } = useGetPipelineInstancesQuery(); // Add limits and pagination

  useEffect(() => {
    document.title = "Home";
  }, []);
  
  useEffect(() => {
    setIsDuplicating(false);
    if (data && data.projects) {
      setProjects(prevProjects => {
        isRequestPending.current = true;
        const projectMap = new Map(prevProjects.map(project => [project.id, project]));
        data.projects.forEach(project => {
          projectMap.set(project.id, project);
        });
        setHasMore(data.currentPage < data.totalPages);
        isRequestPending.current = false;
        setShowBottomLoading(false);
        return Array.from(projectMap.values());
      });
    }
  }, [data, isLoading, error]);

  useEffect(() => {
    const handleScroll = () => {
      const nearBottom = window.innerHeight + window.scrollY >= document.documentElement.offsetHeight - 100; // 100px threshold
      if (nearBottom && hasMore && !isRequestPending.current) {
        loadMoreProjects();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadMoreProjects = () => {
    if (!isRequestPending.current && hasMore) {
      setShowBottomLoading(true);
      isRequestPending.current = true;
      setPage(prevPage => prevPage + 1);
    }
  };

  // Sorting handlers
  const toggleSortByField = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('desc');
    }
    setProjects([]);
    setHasMore(true);
    setPage(1);
    refetch();
  };

  const handleDuplicate = async (projectId) => {
    try {
      setIsDuplicating(true);
      const response = await duplicateProject(projectId);
  
      if (response.error) {
        const errorMessage = response.error.data?.message || 'Error duplicating project';
        toast.error(errorMessage);
        setIsDuplicating(false);
        return;
      }
      
      refetch();

      if (response.data && response.data._id) {
        const projectId = response.data._id;
        navigate(`/projects/${projectId}/edit`);
      } else {
        toast.error('Unable to load new project, please try again.');
      }
    } catch (error) {
      toast.error(error.message || 'An unexpected error occurred');
    } finally {
      setIsDuplicating(false);
    }
  };

  const handleDelete = async (projectId) => {
    if (window.confirm('Are you sure? This action is not reversible.')) {
      try {
        setDeletingProjects(prev => new Set([...prev, projectId]));
        await deleteProject(projectId);
        setProjects(prevProjects => prevProjects.filter(project => project.id !== projectId));
        refetch();
      } catch (error) {
        toast.error(error?.data?.message || error.error);
      } finally {
        setDeletingProjects(prev => {
          const newSet = new Set(prev);
          newSet.delete(projectId);
          return newSet;
        });
      }
    }
  };
  
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setProjects([]);
    setHasMore(true);
    setPage(1);
    refetch();
  };

  const clearSearch = () => {
    setSearchQuery('');
    setProjects([]);
    setHasMore(true);
    setPage(1);
    refetch();
  };

  return (
    <div>
      <div className="mb-3 d-flex align-items-center justify-content-between">
        <div className="user-info">
            <h2 style={{ margin:0 }}>Projects</h2>
            <p className="coming-soon">Deprecated</p>
          </div>
        <InputGroup className="search-input-group">
          <IoSearchOutline className="search-icon" />
          <FormControl
              type="text"
              placeholder="Search"
              className="form-control"
              value={searchQuery}
              onChange={handleSearchChange}
          />
          {searchQuery && <FaXmark onClick={clearSearch} className="clear-icon" />}
        </InputGroup>
      </div>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <div>
          <Table bordered hover responsive>
            <thead>
              <tr>
                <th>
                  <div className='d-flex align-items-center justify-content-start'>
                    <span>Name</span>
                    <SortButton 
                      sortField="name"
                      currentSortField={sortField}
                      sortOrder={sortOrder}
                      onSortChange={toggleSortByField}
                    />
                  </div>
                </th>
                <th className="min-width-column">
                  <div className='d-flex align-items-center justify-content-start'>
                    <span>Last Modified</span>
                    <SortButton 
                      sortField="dateUpdated"
                      currentSortField={sortField}
                      sortOrder={sortOrder}
                      onSortChange={toggleSortByField}
                    />
                  </div>
                </th>
                <th className="min-width-column"></th>
              </tr>
            </thead>
            <tbody> 
              {projects.map((project) => (
                <tr key={project.id} onClick={() => navigate(`${project.id}/edit`)}>
                  <td >
                    <div className='d-flex align-items-center justify-content-start' style={{paddingLeft:"12px"}}>
                      {deletingProjects.has(project.id) ? <Loader isLarge={false} /> : <span className='emoji-sm'>{project.emoji || '➕'}</span>}
                      {project.name}
                    </div>
                  </td>
                  <td className="min-width-column">{new Date(project.dateUpdated).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</td>
                  <td>
                    <div className='d-flex justify-content-end' style={{ paddingRight:"12px" }}>
                      <ActionDropdown 
                        label={<FaEllipsisVertical />}
                        enableHover={true}
                        actions={[
                          ['Duplicate', () => handleDuplicate(project.id)],
                          ['Delete', () => handleDelete(project.id)], 
                        ]}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="d-flex align-center justify-content-center" style={{height:'50px'}}>
            {(showBottomLoading || isDuplicating) && <Loader isLarge={false} />}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectListScreen;
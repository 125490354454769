import React from 'react';
import ActionDropdown from '../../forms/ActionDropdown';
import { formatValue } from '../../../formatter';

function extractVariables(template) {
    const regex = /\$\{(\w+)\}/g; // Regex to match variables inside ${}
    const variables = [];
    let match;

    while ((match = regex.exec(template)) !== null) {
        variables.push(match[1]); // match[1] is the capturing group in the regex
    }

    return variables;
}

const interpolateDescription = (template, variables) => {
    return template?.replace(/\$\{(\w+)\}/g, (_, key) => variables[key] ? formatValue(variables[key], 'compact') : '');
};

const DynamicList = ({
  list,
  data,
  onAddItem,
  onDeleteItem,
}) => {
  const dataKey = list['data']; // "audience"
  const titleKey = list['title']; // "name"
  const descriptionFormat = list['description']; // "${size}M people | ${spend}/month"
  // const descriptionVariables = extractVariables(descriptionFormat); // ['size', 'spend']
  const availableItems = data[dataKey]; // [{ name: 'Audience 1', size: 1000000, spend: 10000 }, ...]
  const selectedItems = data[titleKey]; // [{ _id: '1', name: 'Audience 1', size: 1000000, spend: 10000 }, ...] 

  const totalSize = selectedItems?.reduce((acc, item) => acc + item.size, 0);
  const totalSpend = selectedItems?.reduce((acc, item) => acc + item.spend, 0);

  const totalDescription = interpolateDescription(descriptionFormat, { size: totalSize, spend: totalSpend });


  const handleAddItem = (item) => {
    onAddItem(item);
  };

  const handleDeleteItem = (id) => {
    onDeleteItem(id);
  };

  return (
    <>
      {availableItems && (
        <div>
          <div className='d-flex align-items-center' style={{ margin: "10px 0px 0px 10px" }}>
            <h6 style={{ fontStyle:'normal', margin: "0" }}>
            {data[titleKey]}
            </h6>
            <ActionDropdown
            label={
              <div className="fade-btn" style={{ fontSize:'1.5rem' }}>
                +
              </div>
            }
            padding={0}
            actions={availableItems?.map(item => {
              return [item[titleKey], () => handleAddItem(item)];
            })}
            />
          </div>
          <ul className='audience-list'>
            {selectedItems.map((item) => (
            <li key={item._id} className="audience-item">
              <div>
                <h5>{item[titleKey]}</h5>
                <h6>{interpolateDescription(descriptionFormat, item)}</h6>
              </div>
              <button 
                className="fade-btn"
                type='button'
                onClick={() => handleDeleteItem(item._id)}
              >
                ✕
              </button>
            </li>
            ))}
          </ul>
          <h6 style={{ fontStyle:'normal', margin: "10px 0px 10px 10px" }}>
            Total: {totalDescription}
          </h6>
        </div>
      )}
    </>
  );
};

export default DynamicList;

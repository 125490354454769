import { 
  useGetJobActionsQuery,
} from '../../slices/pipelines/jobSlice';
import { useGetPromptsQuery } from '../../slices/openAiApiSlice';

const useJobSetup = (job, jobIndex, block, handleBlockChange) => {
  // Queries
  const { data: jobActions } = useGetJobActionsQuery();
  const { data: prompts } = useGetPromptsQuery();
  
  // Job Handlers
  const handleJobChange = (attribute, newValue) => {
    let updatedJobs = [...block.jobs];
    updatedJobs[jobIndex] = { ...updatedJobs[jobIndex], [attribute]: newValue };
    handleBlockChange(block._id || block.tempId, 'jobs', updatedJobs);
  };

  const addJob = (block, handleBlockChange) => {
    const newJob = { tempId: `temp-job-${Date.now()}`, name: '', parameters: [], outputs: [] };
    handleBlockChange(block._id || block.tempId, 'jobs', [...block.jobs, newJob]);
  };

  const moveJob = (direction) => {
    let updatedJobs = [...block.jobs];
    let position = jobIndex + direction;
    if (position < 0 || position >= updatedJobs.length) return;

    [updatedJobs[jobIndex], updatedJobs[position]] = [updatedJobs[position], updatedJobs[jobIndex]];
    handleBlockChange(block._id || block.tempId, 'jobs', updatedJobs);
  };

  const removeJob = () => {
    let updatedJobs = [];
  
    if (job.hasOwnProperty('tempId')) {
      // If job has a tempId, remove it directly
      updatedJobs = block.jobs.filter((_, index) => index !== jobIndex);
    } else {
      // Otherwise, mark the job as deleted
      updatedJobs = block.jobs.map((job, index) => 
        index === jobIndex ? { ...job, isDeleted: true } : job
      );
    }
  
    handleBlockChange(block._id || block.tempId, 'jobs', updatedJobs);
  };
  
  return {    
    addJob,
    handleJobChange,
    moveJob,
    removeJob,
    jobActions,
    prompts,
  };
};

export default useJobSetup;
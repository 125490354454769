import React, { useCallback } from "react";
import SelectionDropdown from "../../../../components/forms/SelectionDropdown";
import { InputGroup, FormControl, Button } from 'react-bootstrap';
import Delete from "../../../../components/utility/Delete";
import FloatingLabelInput from "../../../../components/forms/FloatingLabelInput";
import useJobSetup from "../../../../hooks/pipeline-setup/useJobSetup";
import useDictionaryHandlers from "../../../../hooks/pipeline-setup/useDictionaryHandlers";

const JobComponent = React.memo(({
  job,
  jobIndex,
  block,
  handleBlockChange,
}) => {
  const { 
    handleJobChange,
    moveJob,
    removeJob,
    jobActions,
    prompts,
  } = useJobSetup(job, jobIndex, block, handleBlockChange);

  const { 
    addDictionaryEntry,
    editDictionaryKey,
    editDictionaryValue,
    removeDictionaryEntry
  } = useDictionaryHandlers();

  const setParameters = useCallback((newParameters) => handleJobChange('parameters', newParameters), [handleJobChange]);
  const setOutputs = useCallback((newOutputs) => handleJobChange('outputs', newOutputs), [handleJobChange]);

  const onAddParameter = useCallback(() => addDictionaryEntry(job.parameters, setParameters), [job.parameters, setParameters, addDictionaryEntry]);
  const onAddOutput = useCallback(() => addDictionaryEntry(job.outputs, setOutputs), [job.outputs, setOutputs, addDictionaryEntry]);

  return (
    <div className='detail-background table-container mb-3'>
      <div className='d-flex justify-content-between'>
        <div style={{width:"80%"}}>
          <FloatingLabelInput 
            placeholder="Job Name" 
            value={job.name} 
            onChange={(newValue) => handleJobChange('name', newValue)}
          />

          {jobActions && (
            <SelectionDropdown 
              label="Job Action" 
              value={job.action} 
              options={jobActions.map(action => ({ label: action, value: action }))} 
              onChange={(e) => handleJobChange('action', e.target.value)}
            />
          )}

          {prompts && (
            <SelectionDropdown 
              label="Prompt" 
              value={job.prompt} 
              options={prompts.map(prompt => ({ label: prompt.name, value: prompt._id }))}
              onChange={(e) => handleJobChange('prompt', e.target.value)}
            />
          )}
        </div>

        <div>
          <Button className="m-1" variant="dark" onClick={() => moveJob(-1)}>↑</Button>
          <Button className="m-1" variant="dark" onClick={() => moveJob(1)}>↓</Button>
        </div>
      </div>
      <div>
        <div className="d-flex align-items-center justify-content-between my-3">
          <h3 className="m-2">Job Parameters</h3>
          <Button className="action-button" onClick={onAddParameter}>Add new</Button>
        </div>
        {job.parameters.map((entry, _) => (
          <InputGroup key={`${job._id || job.tempId}-param-${entry.id}`} className="mb-2">
            <FormControl
              placeholder="Key"
              defaultValue={entry.key}
              className='floating-input'
              style={{ 'padding':15 }} 
              onChange={(e) => editDictionaryKey(job.parameters, setParameters, entry.id, e.target.value)}
            />
            <FormControl
              placeholder="Value"
              defaultValue={entry.value}
              className='floating-input'
              style={{ 'padding':15 }} 
              onChange={(e) => editDictionaryValue(job.parameters, setParameters, entry.id, e.target.value)}
            />
            <Button variant="outline-danger" onClick={() => removeDictionaryEntry(job.parameters, setParameters, entry.id)}>
              Remove
            </Button>
          </InputGroup>
        ))}
      </div>

      <div>
        <div className="d-flex align-items-center justify-content-between my-3">
          <h3 className="m-2">Job Outputs</h3>
          <Button className="action-button" onClick={onAddOutput}>Add new</Button>
        </div>
        {job.outputs.map((entry, _) => (
          <InputGroup key={`${job._id || job.tempId}-output-${entry.id}`} className="mb-2">
            <FormControl
              placeholder="Key"
              defaultValue={entry.key}
              className='floating-input'
              style={{ 'padding':15 }} 
              onChange={(e) => editDictionaryKey(job.outputs, setOutputs, entry.id, e.target.value)}
            />
            <FormControl
              placeholder="Value"
              defaultValue={entry.value}
              className='floating-input'
              style={{ 'padding':15 }} 
              onChange={(e) => editDictionaryValue(job.outputs, setOutputs, entry.id, e.target.value)}
            />
            <Button variant="outline-danger" onClick={() => removeDictionaryEntry(job.outputs, setOutputs, entry.id)}>
              Remove
            </Button>
          </InputGroup>
        ))}
      </div>

      <Delete onDelete={() => removeJob()} />
    </div>
  );
});

export default JobComponent;

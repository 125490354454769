import { apiSlice } from './apiSlice';
import { ORGANIZATIONS_URL } from '../constants';

export const organizationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizations: builder.query({
      query: () => ({
        url: ORGANIZATIONS_URL,
      }),
      providesTags: ['Organization'],
    }),
    getOrganizationDetails: builder.query({
      query: (id) => ({
        url: `${ORGANIZATIONS_URL}/${id}`,
      }),
      providesTags: ['Organization'],
    }),
    createOrganization: builder.mutation({
      query: (data) => ({
        url: ORGANIZATIONS_URL,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Organization'],
    }),
    updateOrganization: builder.mutation({
      query: ({ id, ...organizationData }) => ({
        url: `${ORGANIZATIONS_URL}/${id}`,
        method: 'PUT',
        body: organizationData,
      }),
      invalidatesTags: ['Organization'],
    }),
    deleteOrganization: builder.mutation({
        query: (organizationId) => ({
          url: `${ORGANIZATIONS_URL}/${organizationId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Organization'],
    }),
    getOrganizationStatusOptions: builder.query({
      query: (data) => ({
        url: `${ORGANIZATIONS_URL}/status-options`,
        method: 'GET',
        body: data,
      }),
    }),
  }),
});

export const {
  useGetOrganizationsQuery,
  useGetOrganizationDetailsQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useDeleteOrganizationMutation,
  useGetOrganizationStatusOptionsQuery,
} = organizationApiSlice;
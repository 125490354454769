import { useEffect, useState, useRef } from 'react';
import { InputGroup, FormControl, Table } from 'react-bootstrap';
import { IoSearchOutline } from "react-icons/io5";
import { FaEllipsisVertical, FaXmark } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import Message from '../../components/utility/Message';
import Loader from '../../components/utility/Loader';
import ActionDropdown from '../../components/forms/ActionDropdown';
import SortButton from '../../components/utility/SortButton';
import { 
  useDeletePipelineInstanceMutation,
  useGetPipelineInstancesQuery,
  useDuplicatePipelineInstanceMutation,
} from '../../slices/pipelines/pipelineInstanceSlice';
import { toast } from 'react-toastify';

const PIPELINE_LIMIT = 20;

const PipelineInstanceListScreen = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [sortField, setSortField] = useState('updatedAt'); // Adjust according to your pipeline model attributes
  const [sortOrder, setSortOrder] = useState('desc'); // 'asc' or 'desc'
  const [hasMore, setHasMore] = useState(false);
  const [showBottomLoading, setShowBottomLoading] = useState(false);
  const isRequestPending = useRef(false);
  const [instances, setInstances] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const { data, refetch, isLoading, error } = useGetPipelineInstancesQuery({ page, limit: PIPELINE_LIMIT, sortField, sortOrder, search: searchQuery });
  const [deletePipelineInstance] = useDeletePipelineInstanceMutation();
  const [duplicatePipelineInstance] = useDuplicatePipelineInstanceMutation();
  const [deletingInstances, setDeletingInstances] = useState(new Set());
  const [isDuplicating, setIsDuplicating] = useState(false);

  useEffect(() => {
    document.title = "Pipelines";
  }, []);

  useEffect(() => {
    setIsDuplicating(false);
    if (data) {
      setInstances(prevInstances => {
        isRequestPending.current = true;
        const instanceMap = new Map(prevInstances.map(instance => [instance._id, instance]));
        data?.instances?.forEach(instance => {
          instanceMap.set(instance._id, instance);
        });
        setHasMore(data.currentPage < data.totalPages);
        isRequestPending.current = false;
        setShowBottomLoading(false);
        return Array.from(instanceMap.values());
      });
    }
  }, [data, isLoading, error]);

  useEffect(() => {
    const handleScroll = () => {
      const nearBottom = window.innerHeight + window.scrollY >= document.documentElement.offsetHeight - 100; // 100px threshold
      if (nearBottom && hasMore && !isRequestPending.current) {
        setPage(prevPage => prevPage + 1);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore]);

  const toggleSortByField = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('desc');
    }
    setInstances([]);
    setHasMore(true);
    setPage(1);
    refetch();
  };

  const handleDuplicate = async (pipelineId, instanceId) => {
    try {
      setIsDuplicating(true);
      const response = await duplicatePipelineInstance(instanceId);
  
      if (response.error) {
        toast.error(response.error.data?.message || 'Error duplicating pipeline');
        setIsDuplicating(false);
        return;
      }

      refetch();
      
      if (response.data && response.data._id) {
        navigate(`/pipelines/${pipelineId._id}/instance/${response.data._id}/edit`);
      } else {
        toast.error('Unable to load new pipeline, please try again.');
      }
    } catch (error) {
      toast.error(error.message || 'An unexpected error occurred');
    } finally {
      setIsDuplicating(false);
    }
  };

  const handleDelete = async (instanceId) => {
    if (window.confirm('Are you sure? This action is not reversible.')) {
      try {
        setDeletingInstances(prev => new Set([...prev, instanceId]));
        await deletePipelineInstance(instanceId);
        setInstances(prevInstances => prevInstances.filter(instance => instance._id !== instanceId));
        refetch();
      } catch (error) {
        toast.error(error?.data?.message || error.error);
    } finally {
      setDeletingInstances(prev => {
        const newSet = new Set(prev);
        newSet.delete(instanceId);
        return newSet;
      });
    }
  }
};

const handleSearchChange = (e) => {
  setSearchQuery(e.target.value);
  setInstances([]);
  setHasMore(true);
  setPage(1);
  refetch();
};

const clearSearch = () => {
  setSearchQuery('');
  setInstances([]);
  setHasMore(true);
  setPage(1);
  refetch();
};

return (
  <div>
    <div className="mb-3 d-flex align-items-center justify-content-between">
      <h2>Pipelines</h2>
      <InputGroup className="search-input-group">
        <IoSearchOutline className="search-icon" />
        <FormControl
            type="text"
            placeholder="Search"
            className="form-control"
            value={searchQuery}
            onChange={handleSearchChange}
        />
        {searchQuery && <FaXmark onClick={clearSearch} className="clear-icon" />}
      </InputGroup>
    </div>
    {isLoading ? (
      <Loader />
    ) : error ? (
      <Message variant='danger'>{error?.data?.message || error.error}</Message>
    ) : (
      <div>
        <Table bordered hover responsive>
          <thead>
            <tr>
              <th>
                <div className='d-flex align-items-center justify-content-start'>
                  <span>Name</span>
                  <SortButton 
                    sortField="name"
                    currentSortField={sortField}
                    sortOrder={sortOrder}
                    onSortChange={toggleSortByField}
                  />
                </div>
              </th>
              <th className="min-width-column">
                <div className='d-flex align-items-center justify-content-start'>
                  <span>Type</span>
                  <SortButton 
                    sortField="type"
                    currentSortField={sortField}
                    sortOrder={sortOrder}
                    onSortChange={toggleSortByField}
                  />
                </div>
              </th>
              <th className="min-width-column">
                <div className='d-flex align-items-center justify-content-start'>
                  <span>Last Modified</span>
                  <SortButton 
                    sortField="updatedAt"
                    currentSortField={sortField}
                    sortOrder={sortOrder}
                    onSortChange={toggleSortByField}
                  />
                </div>
              </th>
              <th className="min-width-column"></th>
            </tr>
          </thead>
          <tbody> 
            {instances.map((instance) => (
              <tr key={instance._id} onClick={() => navigate(`/pipelines/${instance.pipeline._id}/instance/${instance._id}/edit`)}>
                <td>
                  <div className='d-flex align-items-center justify-content-start' style={{ paddingLeft: "12px" }}>
                    {deletingInstances.has(instance._id) ? <Loader isLarge={false} /> : instance.name}
                  </div>
                </td>
                <td className="min-width-column"><div style={{ 'marginRight':50 }}>{instance.pipeline.name}</div></td>
                <td className="min-width-column">{new Date(instance.updatedAt).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</td>
                <td>
                  <div className='d-flex justify-content-end' style={{ paddingRight: "12px" }}>
                    <ActionDropdown 
                      label={<FaEllipsisVertical />}
                      enableHover={true}
                      actions={[
                        ['Duplicate', () => handleDuplicate(instance._id)],
                        ['Delete', () => handleDelete(instance._id)], 
                      ]}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="d-flex align-center justify-content-center" style={{ height: '50px' }}>
          {(showBottomLoading || isDuplicating) && <Loader isLarge={false} />}
        </div>
      </div>
    )}
  </div>
);
};

export default PipelineInstanceListScreen;


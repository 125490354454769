import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import ShortInput from '../../../../components/forms/ShortInput';
import MultiLineInput from '../../../../components/forms/MultiLineInput';
import NewGridCard from '../../../../components/pipelines/grid/NewCard';
import Delete from '../../../../components/utility/Delete';
import useModelSetup from '../../../../hooks/pipeline-setup/useModelSetup';

const ModelSection = React.memo(({ 
  models, 
  setModels,
}) => {
  const { addModel, removeModel, handleModelKeyChange, handleModelValueChange } = useModelSetup(models, setModels);
  const [isCollapsed, setIsCollapsed] = useState(true);
  
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className='my-3'>
      <div className='d-flex justify-content-between align-items-center mb-3'>
        <div>
          <h2>Models</h2>
          <h6>Models to reference for parsing and data processing.</h6>
        </div>
        <div onClick={toggleCollapse} style={{ cursor: 'pointer' }}>
          {isCollapsed ? <FaChevronUp /> : <FaChevronDown />}
        </div>
      </div>
      {!isCollapsed && (
        <>
          {models.map((model) => (
            <div key={`model-${model._id}`} className="detail-background mb-3" style={{ padding: 10 }}>
              <ShortInput
                placeholder={"Model Key"}
                value={model.key}
                onChange={(e) => handleModelKeyChange(model.key, e.target.value)}
              />
              <MultiLineInput
                placeholder={"Model Value"} 
                value={model.value} 
                onChange={(e) => handleModelValueChange(model.key, e.target.value)}
              />
              <Delete onDelete={() => removeModel(model.key)} />
            </div>
          ))}
          <NewGridCard onAdd={addModel} isWide/>
        </>
      )}
    </div>
  )
});

export default ModelSection;
import React, { useState, useEffect, useRef } from 'react';
import Delete from '../../utility/Delete';
import ActionDropdown from '../../forms/ActionDropdown';
import ShortInput from '../../forms/ShortInput';
import MultiLineInput from '../../forms/MultiLineInput';
import { formatValue } from '../../../formatter';

const UspCard = ({ item, allAudiences, onChange, onDelete, autoFocus }) => {
    const [name, setName] = useState((item && item.name) || '');
    const [description, setDescription] = useState((item && item.description) || '');
    const [audiencesIds, setAudiencesIds] = useState((item && item.audiences) || []);
    const [audiences, setAudiences] = useState([]);
    const [availableAudiences, setAvailableAudiences] = useState([]);

    const totalSize = audiences.reduce((sum, audience) => sum + audience.size, 0);
    const avgSpend = totalSize === 0 ? 0 : parseFloat(audiences.reduce((sum, audience) => sum + (audience.size/totalSize * audience.similarSpending), 0).toFixed(2));

    const nameInputRef = useRef(null);

    useEffect(() => {
        if (Array.isArray(allAudiences)) {
            const available = allAudiences.filter(audience => !audiencesIds.includes(audience._id));
            setAvailableAudiences(available);
            setAudiences(allAudiences.filter(audience => audiencesIds.includes(audience._id)));
        }
    }, [item, allAudiences, audiencesIds])

    useEffect(() => {
        if (autoFocus && nameInputRef.current) {
            nameInputRef.current.focus();
            nameInputRef.current.select();
        }
    }, [autoFocus]);

    const handleNameChange = (e) => {
        setName(e.target.value);
        onChange(item._id, 'name', e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
        onChange(item._id, 'description', e.target.value);
    };

    const handleAddAudienceToUsp = (audienceId) => {
        const newAudienceIds = [audienceId, ...audiencesIds]
        setAudiencesIds(newAudienceIds);
        onChange(item._id, 'audiences', newAudienceIds);
    };

    const handleDeleteAudience = (audienceId) => {
        const newAudienceIds = audiencesIds.filter(id => id !== audienceId);
        setAudiencesIds(newAudienceIds);
        onChange(item._id, 'audiences', newAudienceIds);
    };

    return (
        <div className="input-background grid-card">
            <ShortInput
                placeholder='USP Name'
                className="item-name"
                value={name}
                onChange={handleNameChange}
                ref={nameInputRef}
            />
            <MultiLineInput
                placeholder='Description'
                className="item-description"
                value={description}
                onChange={handleDescriptionChange}
            />
            <div className='d-flex align-items-center' style={{ margin: "10px 0px 0px 10px" }}>
                <h6 style={{ fontStyle:'normal', margin: "0" }}>
                    Audiences
                </h6>
                <ActionDropdown
                    label={
                        <div className="fade-btn" style={{ fontSize:'1.5rem' }}>
                            +
                        </div>
                    }
                    padding={0}
                    actions={availableAudiences && availableAudiences.map(audience => {
                        return [audience.name, () => handleAddAudienceToUsp(audience._id)];
                    })}
                >
                </ActionDropdown>
            </div>
            <ul className='audience-list'>
                {audiences && audiences.map((audience) => (
                <li key={audience._id} className="audience-item">
                    <div>
                        <h5>{audience.name}</h5>
                        <h6>{formatValue(audience.size, 'compact')} people | {formatValue(audience.similarSpending, 'money')}/month</h6>
                    </div>
                    <button 
                        className="fade-btn"
                        type='button'
                        onClick={() => handleDeleteAudience(audience._id)}
                    >
                        ✕
                    </button>
                </li>
                ))}
            </ul>
            <h6 style={{ fontStyle:'normal', margin: "10px 0px 10px 10px" }}>
                Total: {formatValue(totalSize, 'compact')} people, {formatValue(avgSpend, 'money')}/month
            </h6>
            <div style={{ margin: '0px 0px 5px 5px' }}>
                <Delete onDelete={() => onDelete(item._id)} />
            </div>
        </div>
    );
};

export default UspCard;
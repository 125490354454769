import React, { useState, useEffect, useRef } from 'react';
import { useGetSpecificLimitsQuery } from '../../slices/limitsSlice';

const ParagraphInput = ({ 
    id,
    value,
    onFocus,
    onBlur,
    onChange,
    placeholder,
}) => {
    // API Hooks
    const { data: limits, error, isLoading } = useGetSpecificLimitsQuery(['MAX_PARAGRAPH_LENGTH']);

    // States
    const [inputValue, setInputValue] = useState(value || '');
    const [showLimitReached, setShowLimitReached] = useState(false);
    const textareaRef = useRef(null);

    const handleChange = (e) => {
        const newValue = e.target.value;
        if (newValue.length > limits?.MAX_PARAGRAPH_LENGTH) {
            setShowLimitReached(true);
        } else if (onChange) {
            setInputValue(newValue);
            setShowLimitReached(false);
            onChange(e);
        }
    };
    
    const handleBlur = (e) => {
        setShowLimitReached(false);
        if (onBlur) {
            onBlur(e);
        }
    };

    const adjustTextAreaHeight = () => {
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = 'inherit';
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    };

    useEffect(() => {
        window.addEventListener('resize', adjustTextAreaHeight);
        return () => {
            window.removeEventListener('resize', adjustTextAreaHeight);
        };
    }, []);

    useEffect(() => {
        adjustTextAreaHeight();
    }, [inputValue]);

    useEffect(() => {
        setInputValue(value || '');
    }, [value]);

    return (
        <div className="input-container">
            <textarea
                ref={textareaRef}
                id={id}
                className={`input-background paragraph-input ${showLimitReached ? 'limit-exceeded' : ''}`}
                value={inputValue}
                onFocus={onFocus}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={placeholder}
            />
            {showLimitReached && (
                <p className="error-message">
                    You cannot exceed {limits?.MAX_PARAGRAPH_LENGTH} characters.
                </p>
            )}
        </div>
    );
};

export default ParagraphInput;

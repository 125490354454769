import { apiSlice } from './apiSlice';
import { PROJECTS_URL } from '../constants';

export const projectApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProjects: builder.query({
      query: ({ page = 1, limit = 20, sortField = 'dateUpdated', sortOrder = 'desc', search = '' }) => {
        const queryString = new URLSearchParams({
          page,
          limit,
          sortField,
          sortOrder,
          search
        }).toString();
    
        return `${PROJECTS_URL}?${queryString}`;
      },
      providesTags: ['Project'],
    }),
    getProject: builder.query({
      query: (projectId) => `${PROJECTS_URL}/${projectId}`,
      providesTags: ['Project'],
    }),
    addProject: builder.mutation({
      query: (project) => ({
        url: PROJECTS_URL,
        method: 'POST',
        body: project,
      }),
      invalidatesTags: ['Project'],
    }),
    updateProject: builder.mutation({
      query: ({ id, ...project }) => ({
        url: `${PROJECTS_URL}/${id}`,
        method: 'PUT',
        body: project,
      }),
      invalidatesTags: ['Project'],
    }),
    deleteProject: builder.mutation({
      query: (projectId) => ({
        url: `${PROJECTS_URL}/${projectId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Project'],
    }),
    duplicateProject: builder.mutation({
      query: (projectId) => ({
        url: `${PROJECTS_URL}/${projectId}/duplicate`,
        method: 'POST',
      }),
    }),
    // Audience endpoints
    getAudiences: builder.query({
      query: (projectId) => `/api/projects/${projectId}/audiences`,
      providesTags: ['Audience'],
    }),
    addAudience: builder.mutation({
      query: ({ projectId, ...audienceData }) => ({
        url: `${PROJECTS_URL}/${projectId}/audiences`,
        method: 'POST',
        body: audienceData,
      }),
    }),
    updateAudience: builder.mutation({
      query: ({ projectId, audienceId, ...audienceData }) => ({
        url: `${PROJECTS_URL}/${projectId}/audiences/${audienceId}`,
        method: 'PUT',
        body: audienceData,
      }),
    }),
    deleteAudience: builder.mutation({
      query: ({ projectId, audienceId }) => ({
        url: `${PROJECTS_URL}/${projectId}/audiences/${audienceId}`,
        method: 'DELETE',
      }),
    }),
    // Competitor endpoints
    getCompetitors: builder.query({
      query: (projectId) => `/api/projects/${projectId}/competitors`,
      providesTags: ['Competitor'],
    }),
    addCompetitor: builder.mutation({
      query: ({ projectId, ...competitorData }) => ({
        url: `${PROJECTS_URL}/${projectId}/competitors`,
        method: 'POST',
        body: competitorData,
      }),
    }),
    updateCompetitor: builder.mutation({
      query: ({ projectId, competitorId, ...competitorData }) => ({
        url: `${PROJECTS_URL}/${projectId}/competitors/${competitorId}`,
        method: 'PUT',
        body: competitorData,
      }),
    }),
    deleteCompetitor: builder.mutation({
      query: ({ projectId, competitorId }) => ({
        url: `${PROJECTS_URL}/${projectId}/competitors/${competitorId}`,
        method: 'DELETE',
      }),
    }),
    // Usps endpoints
    getUsps: builder.query({
      query: (projectId) => `/api/projects/${projectId}/usps`,
      providesTags: ['Usp'],
    }),
    addUsp: builder.mutation({
      query: ({ projectId, ...uspData }) => ({
        url: `${PROJECTS_URL}/${projectId}/usps`,
        method: 'POST',
        body: uspData,
      }),
    }),
    updateUsp: builder.mutation({
      query: ({ projectId, uspId, ...uspData }) => ({
        url: `${PROJECTS_URL}/${projectId}/usps/${uspId}`,
        method: 'PUT',
        body: uspData,
      }),
    }),
    deleteUsp: builder.mutation({
      query: ({ projectId, uspId }) => ({
        url: `${PROJECTS_URL}/${projectId}/usps/${uspId}`,
        method: 'DELETE',
      }),
    }),    
    // Feature endpoints
    getFeatures: builder.query({
      query: (projectId) => `/api/projects/${projectId}/features`,
      providesTags: ['Feature'],
    }),
    addFeature: builder.mutation({
      query: ({ projectId, ...featureData }) => ({
        url: `${PROJECTS_URL}/${projectId}/features`,
        method: 'POST',
        body: featureData,
      }),
    }),
    updateFeature: builder.mutation({
      query: ({ projectId, featureId, ...featureData }) => ({
        url: `${PROJECTS_URL}/${projectId}/features/${featureId}`,
        method: 'PUT',
        body: featureData,
      }),
    }),
    deleteFeature: builder.mutation({
      query: ({ projectId, featureId }) => ({
        url: `${PROJECTS_URL}/${projectId}/features/${featureId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useGetProjectQuery,
  useAddProjectMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  useDuplicateProjectMutation,
  // Audience hooks
  useGetAudiencesQuery,
  useAddAudienceMutation,
  useUpdateAudienceMutation,
  useDeleteAudienceMutation,
  // Competitor hooks
  useGetCompetitorsQuery,
  useAddCompetitorMutation,
  useUpdateCompetitorMutation,
  useDeleteCompetitorMutation,
  // Competitor hooks
  useGetUspsQuery,
  useAddUspMutation,
  useUpdateUspMutation,
  useDeleteUspMutation,
  // Feature hooks
  useGetFeaturesQuery,
  useAddFeatureMutation,
  useUpdateFeatureMutation,
  useDeleteFeatureMutation,
} = projectApiSlice;

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IoIosArrowRoundForward } from "react-icons/io";
import { Container } from 'react-bootstrap';
import AudiencesChart from '../components/charts/AudiencesChart';
import { homeData } from '../components/charts/HomeChartData';

const HomeActionButton = ({ userInfo }) => {
  let buttonText = "Join now";
  let isDisabled = false;
  let showArrow = true;
  let linkPath = '/register';

  if (userInfo) {
      if (userInfo.status === 'waitlist') {
          buttonText = "You're on the waitlist";
          isDisabled = true;
          showArrow = false;
      } else {
          buttonText = "Go to dashboard";
          linkPath = '/pipelines';
      }
  }

  useEffect(() => {
    document.title = "Startr";
  }, []);

  return (
    <Link to={linkPath} className={`secondary-button hue ${isDisabled ? 'disabled' : ''}`}>
      <h3 style={{ margin:'0' }}>{buttonText}</h3>
      {showArrow && <IoIosArrowRoundForward className="arrow-icon" />}
    </Link>
  );
};

const HomeScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);

  return (
    <Container className='home' style={{ textAlign:'center' }}>
      <h1>Ideas to impact</h1>
      <h4 style={{ marginBottom: '4vh' }}>AI-powered market insights, feature innovation, and<br/>competitor analysis.</h4>
      <HomeActionButton userInfo={userInfo} />
      <Container className='chart'>
        <h4>Audience Demographics</h4>
        <AudiencesChart data={homeData} showStartrAI={true} />
      </Container>
    </Container>
  );
};

export default HomeScreen;

import React from 'react';
import { Handle } from 'reactflow';

const FeatureNode = ({ data }) => {
    return (
        <div className='node'>
            { data.hasTargetHandle && 
                <Handle type="target" position="top" id={`${data.id}_target`} style={{ opacity: 0.2, borderRadius: 20 }} />
            }
            <Handle type="source" position="bottom" id={`${data.id}_source`} style={{ opacity: 0.2, borderRadius: 20 }} />
            {data.label}
        </div>
    );
};

export default FeatureNode;
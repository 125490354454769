import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Sidebar from '../components/layout/Sidebar';
import { Outlet } from 'react-router-dom';
import { toggleSidebar } from '../slices/sidebarSlice';

const ProjectLayout = () => {
  const dispatch = useDispatch();
  const isSidebarExpanded = useSelector((state) => state.sidebar.isOpen);

  const handleToggleSidebar = () => dispatch(toggleSidebar());

  return (
    <div className="projects-layout">
      <Sidebar isExpanded={isSidebarExpanded} toggleSidebar={handleToggleSidebar} />
      <main className={`projects-content ${isSidebarExpanded ? '' : 'expanded'}`}>
        <Outlet />
      </main>
    </div>
  );
};

export default ProjectLayout;
import { apiSlice } from './../apiSlice';
import { PIPELINES_URL } from '../../constants';

export const pipelinesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllPipelines: builder.query({
      query: () => `${PIPELINES_URL}/all`,
      providesTags: ['Pipeline'],
    }),
    getPipelines: builder.query({
        query: () => `${PIPELINES_URL}`,
        providesTags: ['Pipeline'],
    }),
    getPipelinesByAssociation: builder.query({
      query: (contextId) => `${PIPELINES_URL}/association/${contextId}`,
      providesTags: ['Pipeline'],
    }),
    getPipelineById: builder.query({
      query: (id) => `${PIPELINES_URL}/${id}`,
      providesTags: ['Pipeline'],
    }),
    createPipeline: builder.mutation({
      query: (pipelineData) => ({
        url: PIPELINES_URL,
        method: 'POST',
        body: pipelineData,
      }),
      invalidatesTags: ['Pipeline'],
    }),
    updatePipeline: builder.mutation({
      query: ({ id, ...updateData }) => ({
        url: `${PIPELINES_URL}/${id}`,
        method: 'PUT',
        body: updateData,
      }),
      invalidatesTags: ['Pipeline'],
    }),
    deletePipeline: builder.mutation({
      query: (id) => ({
        url: `${PIPELINES_URL}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Pipeline'],
    }),
    getPipelineStatusOptions: builder.query({
      query: (data) => ({
        url: `${PIPELINES_URL}/status-options`,
        method: 'GET',
        body: data,
      }),
    }),
  }),
});

export const {
  useGetAllPipelinesQuery,
  useGetPipelinesQuery,
  useGetPipelineByIdQuery,
  useCreatePipelineMutation,
  useUpdatePipelineMutation,
  useDeletePipelineMutation,
  useGetPipelineStatusOptionsQuery,
} = pipelinesApiSlice;
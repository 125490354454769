import React, { useState, useEffect } from 'react';
import { useGetSpecificLimitsQuery } from '../../slices/limitsSlice';

const FloatingLabelInput = ({
    id,
    type,
    value = '',
    onBlur,
    onChange,
    placeholder,
    isExternallyValid = true,
    errorMessage = '',
}) => {
    // API Hooks
    const { data: limits } = useGetSpecificLimitsQuery(['MAX_SHORT_INPUT_LENGTH']);
    const limit = limits?.MAX_SHORT_INPUT_LENGTH || 50;

    // States
    const [displayValue, setDisplayValue] = useState(value);
    const [isValid, setIsValid] = useState(true);
    const [showLimitReached, setShowLimitReached] = useState(false);

    // Validate input
    const inputIsValid = (input) => {
        switch (type) {
            case 'email':
                return /^\S+@\S+\.\S+$/.test(input);
            case 'phone':
                return !input || /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(input);
            default:
                return true;
        }
    };

    // Format phone number
    const formatPhoneNumber = (input) => {
        const currentValue = input.replace(/[^\d]/g, '');
        if (currentValue.length === 0) return '';
        if (currentValue.length < 4) return `(${currentValue}`;
        if (currentValue.length < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6)}`;
    };

    // Handle input change
    const handleChange = (e) => {
        const newValue = e.target.value;

        if (type === 'phone') {
            const { selectionStart } = e.target;

            const numericValue = newValue.replace(/[^\d]/g, '');
            if (numericValue.length <= 10) {
                const formattedValue = formatPhoneNumber(numericValue);
                let correctedPosition = selectionStart + (formattedValue.length - newValue.length);
                setIsValid(inputIsValid(numericValue));
                setDisplayValue(formattedValue);
                onChange && onChange(numericValue);
                requestAnimationFrame(() => {
                    e.target.setSelectionRange(correctedPosition, correctedPosition);
                });
            }
        } else {
            setShowLimitReached(newValue.length > limit);
            setIsValid(inputIsValid(newValue));
            if (!showLimitReached) {
                setDisplayValue(newValue);
                onChange && onChange(newValue);
            }
        }
    };

    // Handle key down events for specific deletions
    const handleKeyDown = (e) => {
        if (type !== 'phone' || e.key !== 'Backspace') {
            return;
        }

        const { selectionStart, value } = e.target;
        let newCursorPosition = selectionStart - 1;
        let newValue = value.replace(/[^\d]/g, '');

        if (value[selectionStart - 1] === '-' && newValue.length > 5) {
            // Delete the 5th number
            newValue = newValue.slice(0, 5) + newValue.slice(6);
            newCursorPosition--;
        } else if ((value[selectionStart - 1] === ')' || value[selectionStart - 1] === ' ') && newValue.length > 3) {
            // Delete the 3rd number
            newValue = newValue.slice(0, 2) + newValue.slice(3);
            newCursorPosition--;
            if (value[selectionStart - 1] === ' ') newCursorPosition--;
        } else {
            return; // No special case, normal deletion will handle it
        }

        e.preventDefault(); // Prevent the default deletion
        const formattedValue = formatPhoneNumber(newValue);
        setIsValid(inputIsValid(newValue));
        setDisplayValue(formattedValue);
        onChange && onChange(newValue);

        // Correct the cursor position
        requestAnimationFrame(() => {
            e.target.setSelectionRange(newCursorPosition, newCursorPosition);
        });
    };

    // Handle input blur
    const handleBlur = (e) => {
        setShowLimitReached(false);
        onBlur && onBlur(e);
    };

    // Update state when value prop changes
    useEffect(() => {
        setDisplayValue(type === 'phone' ? formatPhoneNumber(value) : value);
    }, [value, type]);

    return (
        <div>
            <div className="floating-label-group">
                <input
                    id={id}
                    className={`floating-input ${(isValid && isExternallyValid) ? '' : 'invalid'} ${showLimitReached ? 'limit-exceeded' : ''}`}
                    type={type}
                    name={id}
                    value={displayValue}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    placeholder=' '
                />
                <label className="floating-label" htmlFor={id}>{placeholder}</label>
            </div>
            {errorMessage && (
                <p className="error-message">
                    {errorMessage}
                </p>
            )}
            {showLimitReached && (
                <p className="error-message">
                    You cannot exceed {limit} characters.
                </p>
            )}
        </div>
    );
};

export default FloatingLabelInput;

import React, { useState, useRef, useEffect } from 'react';
import EmojiPicker from 'emoji-picker-react';

const EmojiPickerComponent = ({ onEmojiSelect, triggerClass, selectedEmoji, defaultEmoji = '➕' }) => {
  const [showEmojis, setShowEmojis] = useState(false);
  const emojiPickerRef = useRef(null);
  const emojiButtonRef = useRef(null);

  const onEmojiClick = (event) => {
    onEmojiSelect(event.emoji);
    setShowEmojis(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target) &&
          emojiButtonRef.current && !emojiButtonRef.current.contains(event.target)) {
        setShowEmojis(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <button
        id='emoji-button'
        type='button'
        onClick={() => setShowEmojis(!showEmojis)}
        ref={emojiButtonRef}
        className={triggerClass}
      >
        {selectedEmoji || defaultEmoji}
      </button>
      {showEmojis &&
        <div ref={emojiPickerRef} style={{ position: 'absolute', zIndex: 1000 }}>
          <EmojiPicker 
            onEmojiClick={onEmojiClick}
            lazyLoadEmojis={true} 
            theme='dark' 
            skinTonesDisabled={true}
          />
        </div>
      }
    </>
  );
};

export default EmojiPickerComponent;

import { BsStars } from "react-icons/bs";
import { formatValue } from "../../formatter";

const AudiencesTooltip = ({ active, payload, label, showStartrAI }) => {
    if (active && payload && payload.length) {
        return (
            <div className='audience-tooltip'>
                {showStartrAI ?
                    (
                        <div className='header'>
                            <BsStars style={{ marginRight:'5px' }} />
                            <h3>StartrAI</h3>
                        </div>
                    ) : (
                        <h3>Description</h3>
                    )
                }
                <p>{payload[0].payload.description}</p>
                <h6>{label} | People: {formatValue(payload[0].value, 'compact')} | Spending: {formatValue(payload[1].value, 'money')}/M</h6>
            </div>
        );
    }

    return null;
};

export default AudiencesTooltip;
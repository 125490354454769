import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Message from '../../components/utility/Message';
import Loader from '../../components/utility/Loader';
import Delete from '../../components/utility/Delete';
import {
  useDeleteUserMutation,
  useAddMutation,
  useGetUsersQuery,
} from '../../slices/usersApiSlice';

const UserListScreen = () => {
  const navigate = useNavigate();

  const { data: users, refetch, isLoading, error } = useGetUsersQuery();
  const [deleteUser] = useDeleteUserMutation();
  const [addUser] = useAddMutation();

  useEffect(() => {
    document.title = "Users";
  }, []);

  const deleteHandler = async (id) => {
    try {
      await deleteUser(id);
      refetch();
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };

  const handleNew = async () => {
    try {
      const response = await addUser();
      const userId = response.data._id;
      navigate(`/admin/users/${userId}/edit`);
    } catch (error) {
      toast.error(error?.data?.message || error.error || 'An error occurred while creating the user');
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>Users</h1>
        <button className="action-button" onClick={handleNew} >
          + New
        </button>
      </div>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <Table bordered hover responsive className='table-sm'>
          <thead>
            <tr>
              <th>ID</th>
              <th>NAME</th>
              <th>EMAIL</th>
              <th>PHONE</th>
              <th>ADMIN</th>
              <th>STATUS</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user._id} onClick={() => navigate(`/admin/users/${user._id}/edit`)}>
                <td>{user._id}</td>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.phone}</td>
                <td>
                  {user.isAdmin ? (
                    <FaCheck style={{ color: 'green' }} />
                  ) : (
                    <FaTimes style={{ color: 'red' }} />
                  )}
                </td>
                <td>{user.status && (user.status.charAt(0).toUpperCase() + user.status.slice(1))}</td>
                <td>{!user.isAdmin && <Delete onDelete={ () => deleteHandler(user._id) } />}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default UserListScreen;
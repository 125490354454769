import { FaPlus } from 'react-icons/fa';

const NewGridCard = ({ onAdd, isWide }) => {
    const handleAddFeature = () => {
        onAdd();
    };

    const wideStyle = {
        width: '100%',
        minHeight: 125,
        backgroundColor: 'var(--detail-background)'
    };

    const className = 'input-background new-grid-card';

    return (
        <button 
            type='button'
            className='input-background new-grid-card'
            style={isWide ? wideStyle : {}}
            onClick={handleAddFeature}
        >
            <FaPlus className="new-feature-icon" />
        </button>
    );
};

export default NewGridCard;

import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import Message from '../../components/utility/Message';
import Loader from '../../components/utility/Loader';
import FloatingLabelInput from '../../components/forms/FloatingLabelInput';
import ParagraphInput from '../../components/forms/ParagraphInput';
import { toast } from 'react-toastify';
import { 
    useGetPromptQuery,
    useUpdatePromptMutation,
    useQueryOpenAIMutation,
    useFormattedPromptMutation,
} from '../../slices/openAiApiSlice';

const PromptEditScreen = () => {
  const { id: promptId } = useParams();

  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [text, setText] = useState('');
  const [regex, setRegex] = useState('');
  const [testProjectId, setTestProjectId] = useState('');
  const [testResponse, setTestResponse] = useState('');
  
  // Update prompt
  const { data: prompt, isLoading, error } = useGetPromptQuery(promptId);
  const [updatePrompt, { isLoading: isUpdating }] = useUpdatePromptMutation();
  
  // Format prompt
  const [formattedPrompt, setFormattedPrompt] = useState('');
  const [formatPrompt, { data: formattedPromptData, isLoading: isLoadingFormattedPrompt }] = useFormattedPromptMutation();

  // Test prompt
  const [apiResponse, setApiResponse] = useState('');
  const [queryOpenAI, { data: queryData, isLoading: queryingOpenAI }] = useQueryOpenAIMutation(); // TODO: Switch to websockets

  useEffect(() => {
    document.title = `${name || "Prompts"}`;
  }, []);

  useEffect(() => {
    if (prompt) {
      setName(prompt.name);
      setType(prompt.type);
      setText(prompt.text);
      setRegex(prompt.regex);
      setTestProjectId(prompt.testProjectId);
      setTestResponse(prompt.testResponse);
    }
  }, [prompt]);

  useEffect(() => {
    if (queryData) {
      setApiResponse(queryData);
    }
    if (formattedPromptData) {
      setFormattedPrompt(formattedPromptData.formattedPrompt);
    }
  }, [queryData, formattedPromptData]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await updatePrompt({ id: promptId, name, type, text, regex, testProjectId, testResponse }).unwrap();
      toast.success('Prompt updated successfully');
    } catch (error) {
      toast.error(error?.data?.message || error.error || 'An error occurred');
    }
  };

  const handleFormatPrompt = async () => {
    try {
      await formatPrompt({ id: promptId, projectId: testProjectId });
    } catch (error) {
      toast.error(error?.data?.message || error.error || 'Failed to format response');
    }
  }

  const handleTestFormat = async () => {
    try {
      await queryOpenAI({ type: type, promptId: promptId, projectId: testProjectId, testResponse: testResponse, tesingMode: true });
    } catch (error) {
      toast.error(error?.data?.message || error.error || 'Failed to query OpenAI');
    }
  };
  
  const handleTestPrompt = async () => {
    try {
      await queryOpenAI({ type: type, promptId: promptId, projectId: testProjectId, tesingMode: true });
    } catch (error) {
      toast.error(error?.data?.message || error.error || 'Failed to query OpenAI');
    }
  };

  return (
    <div>
      <Link to='/admin/prompts' className="link-button">
        ← Go Back
      </Link>
      <div className='text-center'>
        <h1>Edit Prompt</h1>
        {isUpdating && <Loader />}
        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error?.data?.message || error.error}</Message>
        ) : (
          <>
            <Form className="mb-5" onSubmit={submitHandler}>
                <FloatingLabelInput
                    id='name'
                    type='text'
                    value={name}
                    onChange={setName}
                    placeholder='Name'
                />

                <FloatingLabelInput
                    id='type'
                    type='text'
                    value={type}
                    onChange={setType}
                    placeholder='Type'
                />

                <FloatingLabelInput
                    id='test_project_id'
                    type='text'
                    value={testProjectId}
                    onChange={setTestProjectId}
                    placeholder='Test ProjectId'
                />

                <div className='d-flex flex-column align-items-start project-section-title'>
                    <h3>Prompt Format</h3>
                    <h6>Available fields: emoji, name, problem, audiences, competitors, usps, and features. Ex usage: $&#123;name&#125;</h6>
                </div>
                <ParagraphInput
                    id='format'
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    placeholder='text'
                />

                <div className='d-flex justify-content-center'>
                  <Button className="action-button" type='button' onClick={handleFormatPrompt}>
                    Get formatted prompt
                  </Button>
                </div>
                <h6>Inserts data from specified test project into prompt format.</h6>
                {isLoadingFormattedPrompt ? (
                  <Loader />
                ) : (
                  formattedPrompt && (
                    <div className="api-response-container">
                        <h4>Formatted Prompt:</h4>
                        <p className="api-response">{formattedPrompt}</p>
                    </div>
                  )
                )}

                <div className='d-flex flex-column align-items-start project-section-title'>
                    <h3>Regex</h3>
                    <h6>Format of the response data.</h6>
                    </div>
                <ParagraphInput
                    id='regex'
                    value={regex}
                    onChange={(e) => setRegex(e.target.value)}
                />

                <div className='d-flex flex-column align-items-start project-section-title'>
                    <h3>Test Response</h3>
                    <h6>Response to be tested against the regex.</h6>
                    </div>
                <ParagraphInput
                    label='test_response'
                    value={testResponse}
                    onChange={(e) => setTestResponse(e.target.value)}
                />

                <div className='d-flex justify-content-center'>
                  <Button className="action-button" type='submit' style={{marginRight:'15px'}}>
                      Update
                  </Button>
                  <Button className="action-button" type='button' onClick={handleTestFormat} style={{marginRight:'15px'}}>
                      Test format
                  </Button>
                  <Button className="action-button" type='button' onClick={handleTestPrompt}>
                      Test prompt
                  </Button>
                </div>
                <h6>
                  Test format: Attempts to parse test response data using the prompt's regex.<br/>
                  Test prompt: Queries OpenAI with the formatted prompt and returns data parsed using the prompt's regex.<br/>
                  Note: Actions performed on this page reference the specified project but do not modify it.
                </h6>
                {queryingOpenAI ? (
                  <Loader />
                ) : (
                  apiResponse && (
                  <div className="mt-3 api-response-container">
                      <h4>API Response:</h4>
                      <pre className="api-response">{JSON.stringify(apiResponse, null, 2)}</pre>
                  </div>
                  )
                )}
            </Form>
          </>
        )}
      </div>
    </div>
  );
};

export default PromptEditScreen;

import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import Message from '../../components/utility/Message';
import Loader from '../../components/utility/Loader';
import FloatingLabelInput from '../../components/forms/FloatingLabelInput';
import SelectionDropdown from '../../components/forms/SelectionDropdown';
import { toast } from 'react-toastify';
import { 
  useGetUserDetailsQuery, 
  useUpdateUserMutation, 
  useGetUserStatusOptionsQuery,
} from '../../slices/usersApiSlice';
import { useGetOrganizationsQuery } from '../../slices/organizationApiSlice';
import { 
  useGetUserAssociationsQuery,
  useCreateUserOrganizationAssociationMutation,
  useDeleteUserOrganizationAssociationMutation,
} from '../../slices/userOrganizationAssociationApiSlice';
import { useGetAllPipelinesQuery } from '../../slices/pipelines/pipelineSlice';
import { 
  useGetUserPipelineAssociationsQuery,
  useCreatePipelineAssociationMutation,
  useDeletePipelineAssociationMutation,
} from '../../slices/pipelines/pipelineAssociationSlice';

const UserEditScreen = () => {
  const { id: userId } = useParams();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [status, setStatus] = useState('');
  const [password, setPassword] = useState('');

  // User details
  const { data: statusOptions, loadingStatuses } = useGetUserStatusOptionsQuery();
  const { data: user, isLoading, error, refetch } = useGetUserDetailsQuery(userId);
  const [updateUser, { isLoading: loadingUpdate }] = useUpdateUserMutation();

  // User organization associations
  const [initialAssociations, setInitialAssociations] = useState([]);
  const [selectedOrganizations, setSelectedOrganizations] = useState([]);
  const { data: organizations } = useGetOrganizationsQuery();
  const { data: userOrgAssociations } = useGetUserAssociationsQuery(userId);
  const [createUserOrganizationAssociation] = useCreateUserOrganizationAssociationMutation();
  const [deleteUserOrganizationAssociation] = useDeleteUserOrganizationAssociationMutation();

  // User pipeline associations
  const [initialPipelines, setInitialPipelines] = useState([]);
  const [selectedPipelines, setSelectedPipelines] = useState([]);
  const { data: pipelines } = useGetAllPipelinesQuery();
  const { data: userPipelineAssociations } = useGetUserPipelineAssociationsQuery(userId);
  const [createUserPipelineAssociation] = useCreatePipelineAssociationMutation();
  const [deleteUserPipelineAssociation] = useDeletePipelineAssociationMutation();

  useEffect(() => {
    document.title = `${name || "Users"}`;
  }, []);

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
      setPhone(user.phone);
      setIsAdmin(user.isAdmin);
      setStatus(user.status);
      setPassword(user.password);
    }
  }, [user]);

  useEffect(() => {
    if (userOrgAssociations) {
      const associatedOrgIds = userOrgAssociations.map(assoc => assoc.organization._id);
      setSelectedOrganizations(associatedOrgIds);
      setInitialAssociations(associatedOrgIds);
    }
  }, [userOrgAssociations]);

  useEffect(() => {
    if (userPipelineAssociations) {
      const userPipelineAssociationsIds = userPipelineAssociations.map(assoc => assoc.pipeline);
      setSelectedPipelines(userPipelineAssociationsIds);
      setInitialPipelines(userPipelineAssociationsIds);
    }
  }, [userPipelineAssociations]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await updateUser({ userId, name, email, phone, password, status, isAdmin });
      await updateAssociations();
      await updatePipelineAssociations();
      toast.success('User updated successfully');
      refetch();
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };

  const updateAssociations = async () => {
    // Create new associations
    for (const orgId of selectedOrganizations) {
      if (!initialAssociations.includes(orgId)) {
        try {
          await createUserOrganizationAssociation({ userId, organization: orgId }).unwrap();
        } catch (error) {
          console.error('Failed to create association:', error);
        }
      }
    }

    // Delete removed associations
    for (const orgId of initialAssociations) {
      if (!selectedOrganizations.includes(orgId)) {
        try {
          const assocToDelete = userOrgAssociations.find(assoc => assoc.organization._id === orgId);
          if (assocToDelete) {
            await deleteUserOrganizationAssociation(assocToDelete._id);
          }
        } catch (error) {
          console.error('Failed to delete association:', error);
        }
      }
    }
  };

  const updatePipelineAssociations = async () => {
    // Create new associations
    for (const pipelineId of selectedPipelines) {
      if (!initialPipelines.includes(pipelineId)) {
        try {
          await createUserPipelineAssociation({ pipelineId, userId }).unwrap();
        } catch (error) {
          console.error('Failed to create association:', error);
        }
      }
    }

    // Delete removed associations
    for (const pipelineId of initialPipelines) {
      if (!selectedPipelines.includes(pipelineId)) {
        try {
          const assocToDelete = userPipelineAssociations.find(assoc => assoc.pipeline === pipelineId);
          if (assocToDelete) {
            await deleteUserPipelineAssociation(assocToDelete._id);
          }
        } catch (error) {
          console.error('Failed to delete association:', error);
        }
      }
    }
  };

  return (
    <div>
      <Link to='/admin/users' className="link-button">
        ← Go Back
      </Link>
      <div className='text-center'>
        <h1>Edit User</h1>
        {loadingUpdate && <Loader />}
        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error?.data?.message || error.error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <FloatingLabelInput
              id='name'
              type='name'
              value={name}
              onChange={setName}
              placeholder='Name'
            />

            <FloatingLabelInput
              id='email'
              type='email'
              value={email}
              onChange={setEmail}
              placeholder='Email'
            />

            <FloatingLabelInput
              id='phone'
              type='phone'
              value={phone}
              onChange={setPhone}
              placeholder='Phone'
            />

            <FloatingLabelInput
              id='password'
              type='text'
              value={password}
              onChange={setPassword}
              placeholder='Update password'
            />

            {!loadingStatuses && (
              <SelectionDropdown
                label='Status'
                value={status}
                options={statusOptions}
                onChange={(e) => setStatus(e.target.value)}
              />
            )}

            <SelectionDropdown
              label="Organizations"
              value={selectedOrganizations}
              onChange={(e) => setSelectedOrganizations(Array.from(e.target.selectedOptions, option => option.value))}
              options={organizations?.map((org) => ({
                label: org.name,
                value: org._id,
              }))}
              multiple={true}
            />

            <SelectionDropdown
              label="Pipelines"
              value={selectedPipelines}
              onChange={(e) => setSelectedPipelines(Array.from(e.target.selectedOptions, option => option.value))}
              options={pipelines?.map((pipeline) => ({
                label: pipeline.name,
                value: pipeline._id,
              }))}
              multiple={true}
            />

            <div>
              <input
                  type='checkbox'
                  label='Is Admin'
                  checked={isAdmin}
                  onChange={(e) => setIsAdmin(e.target.checked)}
              />
              <span> Is Admin</span>
            </div>

            <button className="action-button" type='submit'>
              Update
            </button>
          </Form>
        )}
      </div>
    </div>
  );
};

export default UserEditScreen;

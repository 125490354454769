import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  useGetPipelineByIdQuery,
  useUpdatePipelineMutation,
  useGetPipelineStatusOptionsQuery,
} from '../../slices/pipelines/pipelineSlice';
import { 
  useCreateBlockMutation,
  useUpdateBlockMutation, 
  useDeleteBlockMutation,
} from '../../slices/pipelines/blockSlice';
import { 
  useCreateJobMutation,
  useUpdateJobMutation,
  useDeleteJobMutation,
} from '../../slices/pipelines/jobSlice';
import { 
  useCreateUIComponentMutation,
  useUpdateUIComponentMutation,
  useDeleteUIComponentMutation,
} from '../../slices/pipelines/uiComponentSlice';

const usePipelineSetup = (pipelineId) => {
  // State
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [singleInstancePerAssociation, setSingleInstancePerAssociation] = useState(false);
  const [version, setVersion] = useState('');
  const [status, setStatus] = useState('');
  const [isPublic, setIsPublic] = useState(false);
  const [allowEmoji, setAllowEmoji] = useState(false);
  const [disableInteraction, setDisableInteraction] = useState(false);
  const [models, setModels] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [statusOptions, setStatusOptions] = useState('');

  // Mutations
  const [updatePipeline] = useUpdatePipelineMutation();

  const [createBlock] = useCreateBlockMutation();
  const [updateBlock] = useUpdateBlockMutation();
  const [deleteBlock] = useDeleteBlockMutation();

  const [createJob] = useCreateJobMutation();
  const [updateJob] = useUpdateJobMutation();
  const [deleteJob] = useDeleteJobMutation();

  const [createUIComponent] = useCreateUIComponentMutation();
  const [updateUIComponent] = useUpdateUIComponentMutation();
  const [deleteUIComponent] = useDeleteUIComponentMutation();

  // Queries
  const {
    data: pipeline,
    isLoading: isLoadingPipeline,
    error: pipelineError,
    refetch,
  } = useGetPipelineByIdQuery(pipelineId);
  const { data: statuses } = useGetPipelineStatusOptionsQuery();

  useEffect(() => {
    if (pipeline) {
      setName(pipeline.name || '');
      setDescription(pipeline.description || '');
      setSingleInstancePerAssociation(pipeline.singleInstancePerAssociation || false);
      setVersion(pipeline.version || '');
      setStatus(pipeline.status || '');
      setIsPublic(pipeline.isPublic || false);
      setAllowEmoji(pipeline.options?.allowEmoji || false);
      setDisableInteraction(pipeline.options?.disableInteraction || false);
      setModels(
        pipeline.models.map(model => ({
          ...model,
          value: JSON.stringify(model.value, null, 2)
        })) || []
      );
      setBlocks(pipeline.blocks || []);
    }
  }, [pipeline]);

  useEffect(() => {
    if (statuses) {
      setStatusOptions(statuses);
    }
  }, [statuses]);

  const handleCheckboxChange = (setter) => (e) => setter(e.target.checked);

  // Function to process new blocks
  async function processNewBlocks(blocks, pipelineId) {
    let updatedBlocks = [...blocks];
    for (const block of updatedBlocks.filter(b => b.tempId)) {
      let updatedBlock = await createAndUpdateBlock(block, pipelineId);
      if (updatedBlock) {
        updatedBlocks = updatedBlocks.map(b => b.tempId === block.tempId ? updatedBlock : b);
      } else {
        throw new Error('Failed to create a new block.');
      }
    }
    return updatedBlocks;
  }

  // Function to create and update a single block
  async function createAndUpdateBlock(block, pipelineId) {
    const jobIds = await processEntities(block.jobs, createJob);
    const uiComponentIds = await processEntities(block.uiComponents, createUIComponent);
  
    const blockData = {
      ...block,
      pipelineId,
      jobs: jobIds,
      uiComponents: uiComponentIds
    };
    const response = await createBlock(blockData);
  
    if (response && response.data) {
      return response.data;
    } else {
      toast.error('Failed to create a new block.');
      return null;
    }
  }

  // Process jobs or UI components
  async function processEntities(entities, createFunction) {
    const ids = [];
    for (const entity of entities) {
      const { tempId, ...entityData } = entity;
      const response = await createFunction(entityData);
      if (response && response.data && response.data._id) {
        ids.push(response.data._id);
      } else {
        toast.error(`Failed to create entity: ${JSON.stringify(entity)}`);
      }
    }
    return ids;
  }

  async function updateExistingBlocks(blocks) {
    for (const block of blocks.filter(block => !block.tempId)) {
      await updateBlockEntities(block, 'jobs');
      await updateBlockEntities(block, 'uiComponents');
      await updateBlock({ id: block._id, ...block });
    }
  }

  // Update jobs or UI components within a block
  async function updateBlockEntities(block, entityType) {
    for (const entity of block[entityType]) {
      if (entity.tempId) {
        let response;
        if (entityType === 'jobs') {
          response = await createJob({ blockId: block._id, ...entity });
        } else { // 'uiComponents'
          response = await createUIComponent({ blockId: block._id, ...entity });
        }

        if (response && response.data && response.data._id) {
          block[entityType] = block[entityType].map(e => e.tempId === entity.tempId ? response.data : e);
        }
      } else {
        if (entityType === 'jobs') {
          await updateJob({ id: entity._id, ...entity });
        } else { // 'uiComponents'
          await updateUIComponent({ id: entity._id, ...entity });
        }
      }
    }

    const deletedEntities = block[entityType].filter(e => e.isDeleted);
    for (const entity of deletedEntities) {
      if (entityType === 'jobs') {
        await deleteJob(entity._id);
      } else { // 'uiComponents'
        await deleteUIComponent(entity._id);
      }
    }
  }

  async function deleteBlocks(blocks) {
    const deletedBlocks = blocks.filter(block => block.isDeleted);
    for (const block of deletedBlocks) {
      await deleteBlock(block._id);
    }
  }

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      let updatedBlocks = await processNewBlocks(blocks, pipelineId);
      await updateExistingBlocks(blocks);
      await deleteBlocks(blocks);

      console.log('disableInteraction:', disableInteraction);
      await updatePipeline({
        id: pipelineId,
        name,
        description,
        singleInstancePerAssociation,
        version,
        status,
        isPublic,
        options: { allowEmoji, disableInteraction },
        models: models.map(model => ({
          ...model,
          value: JSON.parse(model.value)
        })),
        blocks: updatedBlocks.map(block => block._id),
      });
      toast.success('Pipeline updated successfully');
      refetch();
    } catch (error) {
      toast.error(error?.data?.message || error.message);
    }
  };

  return {
    name,
    setName,
    description,
    setDescription,
    singleInstancePerAssociation,
    setSingleInstancePerAssociation,
    version,
    setVersion,
    status,
    setStatus,
    isPublic,
    setIsPublic,
    allowEmoji,
    setAllowEmoji,
    disableInteraction,
    setDisableInteraction,
    models,
    setModels,
    blocks: blocks.filter(block => !block.isDeleted),
    setBlocks,
    statusOptions,
    isLoadingPipeline,
    pipelineError,
    handleCheckboxChange,
    submitHandler,
  };
};

export default usePipelineSetup;
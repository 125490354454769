import React from 'react';
import NewGridCard from './NewCard';

const calculateTotalSpend = (usp, audiences) => {
  const uspAudiences = audiences.filter(audience => usp && usp.audiences && usp.audiences.includes(audience._id));
  const totalSize = uspAudiences.reduce((sum, audience) => sum + audience.size, 0);
  const avgSpend = uspAudiences.reduce((sum, audience) => sum + (audience.size / totalSize) * audience.similarSpending, 0);
  return totalSize * avgSpend;
};

const Grid = ({ 
  items,
  audiences, // Deprecated
  list,
  focusedItemId,
  onChange,
  onDelete,
  onAdd,
  CardComponent,
  options,
  disabled,
  parseOptions
}) => {
  const safeItems = Array.isArray(items) ? items : [];
  const sortedItems = audiences ? [...safeItems].sort((a, b) => calculateTotalSpend(b, audiences) - calculateTotalSpend(a, audiences)) : safeItems;
  const displayItems = audiences ? sortedItems : safeItems;

  return (
    <div className="grid">
      {displayItems.map((item, index) => (
        <CardComponent
          key={item._id || `grid-${index}`}
          index={index}
          item={item}
          list={list}
          allAudiences={audiences} // audiences are used in usps, we need the option to add them as an object option
          onChange={onChange}
          onDelete={onDelete}
          autoFocus={focusedItemId && item._id === focusedItemId}
          options={options}
          disabled={disabled}
          parseOptions={parseOptions}
        />
      ))}
      {!disabled && <NewGridCard onAdd={onAdd} />}
    </div>
  );
};

export default Grid;
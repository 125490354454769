import { apiSlice } from './apiSlice';
import { LIMITS_URL } from '../constants';

export const configApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSpecificLimits: builder.query({
      query: (keys) => ({
        url: `${LIMITS_URL}?keys=${keys.join(',')}`,
      }),
    }),
  }),
});

export const {
  useGetSpecificLimitsQuery,
} = configApiSlice;
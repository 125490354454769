import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  useGetAudiencesQuery,
  useAddAudienceMutation,
  useUpdateAudienceMutation,
  useDeleteAudienceMutation,
} from '../slices/projectsApiSlice';

export const useAudiences = (projectId) => {
  const { data: audiences, error, isLoading, refetch } = useGetAudiencesQuery(projectId);
  const [addAudience] = useAddAudienceMutation();
  const [updateAudience] = useUpdateAudienceMutation();
  const [deleteAudience] = useDeleteAudienceMutation();

  const [localAudiences, setLocalAudiences] = useState([]);
  const [focusedAudienceId, setFocusedAudienceId] = useState(null);

  useEffect(() => {
    if (audiences) {
      setLocalAudiences(audiences);
    }
  }, [audiences]);

  const handleAddAudience = async (audience) => {
    const tempAudienceId = Date.now().toString();
    const tempAudience = {
      name: 'New Audience',
      _id: tempAudienceId,
      isNew: true,
    };

    setLocalAudiences((prev) => [...prev, tempAudience]);

    try {
      const response = await addAudience({ projectId, ...tempAudience }).unwrap();
      setLocalAudiences((prev) =>
        prev.map((aud) =>
          aud._id === tempAudienceId ? { ...response, isNew: undefined } : aud
        )
      );
      setFocusedAudienceId(response._id);
    } catch (error) {
      setLocalAudiences((prev) => prev.filter((aud) => aud._id !== tempAudienceId));
      toast.error(error?.data?.message || error.error || 'An error occurred');
    }
  };

  const handleUpdateAudience = async (audienceId, field, value) => {
    const updatedAudiences = localAudiences.map((audience) => {
      if (audience._id === audienceId) {
        return { ...audience, [field]: value, isNew: undefined };
      }
      return audience;
    });

    setLocalAudiences(updatedAudiences);

    try {
      await updateAudience({ projectId, audienceId, [field]: value }).unwrap(); // Make sure to adjust based on your actual API
    } catch (error) {
      toast.error(error?.data?.message || error.error || 'An error occurred');
    }
  };

  const handleDeleteAudience = async (audienceId) => {
    setLocalAudiences(prev => prev.filter(a => a._id !== audienceId));
    try {
      await deleteAudience({ projectId, audienceId }).unwrap();
    } catch (error) {
      toast.error(error?.data?.message || error.error || 'An error occurred');
    }
  };

  return {
    localAudiences,
    focusedAudienceId,
    setLocalAudiences,
    setFocusedAudienceId,
    handleAddAudience,
    handleUpdateAudience,
    handleDeleteAudience,
    error,
    isLoading,
    refetch
  };
};

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: localStorage.getItem('sidebarIsOpen')
    ? JSON.parse(localStorage.getItem('sidebarIsOpen'))
    : true,
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      const newState = !state.isOpen;
      state.isOpen = newState;
      localStorage.setItem('sidebarIsOpen', JSON.stringify(newState));
    },
    setSidebarState: (state, action) => {
      state.isOpen = action.payload;
      localStorage.setItem('sidebarIsOpen', JSON.stringify(action.payload));
    },
  },
});

export const { toggleSidebar, setSidebarState } = sidebarSlice.actions;

export default sidebarSlice.reducer;

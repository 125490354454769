import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Cell, LabelList, Legend, CartesianGrid } from 'recharts';

const GroupedBarChartVisualization = ({ distribution }) => {
  // Convert the distribution object into an array of objects suitable for recharts
  const data = Object.entries(distribution).map(([key, value]) => {
    const entry = { name: value.label };
    
    if (typeof value.value === 'object') {
      Object.entries(value.value).forEach(([subKey, subValue]) => {
        entry[subKey] = subValue;
      });
    } else {
      entry.value = value.value;
    }

    return entry;
  });

  // Extract all unique categories dynamically
  const categories = [...new Set(data.flatMap(Object.keys).filter(key => key !== 'name'))];

  // Calculate the total for percentage calculations
  const total = data.reduce((sum, entry) =>
    sum + categories.reduce((subSum, category) => subSum + (entry[category] || 0), 0), 0);

  const COLORS = [
    "#3965A6", // Darkest variation
    "#4F83C9", // Darker variation
    "var(--primary-color)",
    "#7CCBFF", // Lighter variation
    "#93E8FF", // Even lighter variation
    "#AAF7FF"  // Lightest variation
  ];

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const totalValue = payload.reduce((sum, entry) => sum + entry.value, 0);
      const percentage = ((totalValue / total) * 100).toFixed(2);
      return (
        <div className="custom-tooltip">
          <p className="tooltip-label"><strong>{label}</strong></p>
          {payload.map((entry, index) => (
            <p key={`value-${index}`} className="tooltip-value" style={{ color: entry.fill }}>
              {`${entry.name}: ${entry.value} (${((entry.value / total) * 100).toFixed(2)}%)`}
            </p>
          ))}
          <p className="tooltip-total">{`Total: ${totalValue} (${percentage}%)`}</p>
        </div>
      );
    }
    return null;
  };

  const calculateMinWidth = (numColumns, numCategories) => {
    const refWidth = 1200;
    const refColumns = 15;
    const refCategories = 5;
    return (refWidth / (refColumns * refCategories)) * (numColumns * numCategories);
  };

  const minWidth = calculateMinWidth(data.length, categories.length);

  return (
    <ResponsiveContainer width="100%" height={400} minWidth={minWidth}>
      <BarChart
        layout="horizontal"
        data={data}
        margin={{ top: 5, right: 10, left: 0, bottom: 50 }}
      >
        <Legend layout="horizontal" verticalAlign="top" align="right" />
        <XAxis
          dataKey="name"
          type="category"
          tick={{ fontSize: '14px', opacity: '0.8', fill: 'white' }}
          angle={-45} // Slant to the x-axis labels
          textAnchor="end" // Adjust text anchor for slanted labels
        />
        <YAxis
          type="number"
          tick={{ fontSize: '14px', opacity: '0.8', fill: 'white' }}
        />
        <Tooltip content={<CustomTooltip />} cursor={false} />
        {categories.map((category, index) => (
          <Bar
            key={category}
            dataKey={category}
            fill={COLORS[index % COLORS.length]}
            radius={[3, 3, 0, 0]}
          >
            {data.map((entry, idx) => (
              <Cell key={`cell-${category}-${idx}`} />
            ))}
            <LabelList
              dataKey={category}
              position="insideTop"
              formatter={(value) => value}
              style={{ fill: 'white', fontSize: '10px', whiteSpace: 'nowrap' }}
            />
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default GroupedBarChartVisualization;

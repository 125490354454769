// export const BASE_URL =
//   process.env.NODE_ENV === 'develeopment' ? 'http://localhost:4000' : '';
// export const BASE_URL = ''; // If using proxy
export const BASE_URL = '';
export const USERS_URL = '/api/users';
export const PROJECTS_URL = '/api/projects';
export const OPENAI_URL = '/api/prompts';
export const LIMITS_URL = '/api/limits';
export const ORGANIZATIONS_URL = '/api/organizations';
export const MEMBERSHIPS_URL = '/api/memberships';
export const PIPELINES_URL = '/api/pipelines';
export const BLOCKS_URL = `${PIPELINES_URL}/blocks`;
export const JOBS_URL = `${PIPELINES_URL}/jobs`;
export const UI_COMPONENTS_URL = `${PIPELINES_URL}/uiComponents`;
export const PIPELINE_INSTANCES_URL = `${PIPELINES_URL}/instances`;
export const PIPELINE_ASSOCIATIONS_URL = `${PIPELINES_URL}/associations`;
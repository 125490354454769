import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import FloatingLabelInput from '../../../../components/forms/FloatingLabelInput';
import SelectionDropdown from '../../../../components/forms/SelectionDropdown';
import { Form } from 'react-bootstrap';

const DetailSection = React.memo(({ 
  name, 
  setName, 
  description, 
  setDescription, 
  version, 
  setVersion, 
  status, 
  setStatus, 
  statusOptions, 
  singleInstancePerAssociation, 
  setSingleInstancePerAssociation, 
  isPublic, 
  setIsPublic, 
  allowEmoji, 
  setAllowEmoji, 
  disableInteraction,
  setDisableInteraction,
  handleCheckboxChange 
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className='my-3'>
      <div className='d-flex justify-content-between align-items-center mb-3'>
        <div>
          <h2>Details</h2>
          <h6>General information about the pipeline.</h6>
        </div>
        <div onClick={toggleCollapse} style={{ cursor: 'pointer' }}>
          {isCollapsed ? <FaChevronUp /> : <FaChevronDown />}
        </div>
      </div>
      {!isCollapsed && (
        <>
          <FloatingLabelInput
            id='name'
            type='text'
            label='Name'
            value={name}
            onChange={setName}
            placeholder='Name'
          />
          <FloatingLabelInput
            id='description'
            type='text'
            label='Description'
            value={description}
            onChange={setDescription}
            placeholder='Description'
          />
          <FloatingLabelInput
            id='version'
            type='text'
            label='Version'
            value={version}
            onChange={setVersion}
            placeholder='Version'
          />
          {statusOptions && (
          <SelectionDropdown
            label='Status'
            value={status}
            options={statusOptions}
            onChange={(e) => setStatus(e.target.value)}
          />
          )}
          <div className='m-2'>
            <Form.Check 
              type="checkbox"
              label="Single Instance Per Association" 
              className="floating-label-group checkbox"
              checked={singleInstancePerAssociation} 
              onChange={handleCheckboxChange(setSingleInstancePerAssociation)} 
            />
            <Form.Check 
              type="checkbox" 
              label="Public" 
              className="floating-label-group checkbox"
              checked={isPublic} 
              onChange={handleCheckboxChange(setIsPublic)} 
            />
            <Form.Check 
              type="checkbox" 
              label="Emoji" 
              className="floating-label-group checkbox"
              checked={allowEmoji} 
              onChange={handleCheckboxChange(setAllowEmoji)} 
            />
            <Form.Check 
              type="checkbox" 
              label="Disable header interaction" 
              className="floating-label-group checkbox"
              checked={disableInteraction} 
              onChange={handleCheckboxChange(setDisableInteraction)} 
            />
          </div>
        </>
      )}
    </div>
  )
});

export default DetailSection;
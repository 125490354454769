import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../components/utility/Loader';
import FloatingLabelInput from '../components/forms/FloatingLabelInput';
import ReCAPTCHA from "react-google-recaptcha";
import { useRegisterMutation } from '../slices/usersApiSlice';
import { setCredentials } from '../slices/authSlice';
import { toast } from 'react-toastify';

const RegisterScreen = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
  });
  const [formErrors, setFormErrors] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
  });
  const [recaptchaToken, setRecaptchaToken] = useState('');
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);
  const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const recaptchaRef = useRef();
  const [register, { isLoading }] = useRegisterMutation();
  
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const redirect = sp.get('redirect') || '/';

  useEffect(() => {
    document.title = "Register | Startr";
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, redirect, userInfo]);

  const validateField = (name, value) => {
    let error = '';
    switch (name) {
      case 'email':
        error = /^\S+@\S+\.\S+$/.test(value) ? '' : 'Invalid email format';
        break;
      case 'password':
        const { isValid, message } = validatePassword(value);
        error = isValid ? '' : message;
        break;
      case 'confirmPassword':
        error = value === formData.password ? '' : 'Passwords do not match';
        break;
      case 'name':
        error = value.trim() ? '' : 'Name is required';
        break;
      case 'phone':
        error = value && !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value) ? 'Invalid phone number' : '';
        break;
      default:
        break;
    }
    setFormErrors(prev => ({ ...prev, [name]: error }));
  };

  const validatePassword = (password) => {
    if (!password) return { isValid: false, message: 'Password is required' };
    if (password.length < 8) return { isValid: false, message: 'Password must be at least 8 characters long' };
    if (!/[A-Z]/.test(password)) return { isValid: false, message: 'Password must contain an uppercase letter' };
    if (!/[a-z]/.test(password)) return { isValid: false, message: 'Password must contain a lowercase letter' };
    if (!/\d/.test(password)) return { isValid: false, message: 'Password must contain a number' };
    if (!/[^\w\s]/.test(password)) return { isValid: false, message: 'Password must contain a special character' };
    return { isValid: true, message: '' };
  };

  const handleChange = (field, value) => {
    setFormData(prev => ({ ...prev, [field]: value }));
    validateField(field, value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    recaptchaRef.current.reset();
    // Validate all fields before submission
    Object.entries(formData).forEach(([f, v]) => validateField(f, v));
    
    // Check for any validation errors
    const hasErrors = Object.values(formErrors).some((error) => error !== '');
    if (hasErrors || !recaptchaToken) {
      if (!recaptchaToken) toast.error('Please complete the reCAPTCHA challenge.');
      else toast.error('Please correct the errors before submitting.');
      return;
    }

    try {
      const payload = { ...formData, recaptchaToken };
      const result = await register(payload).unwrap();
      dispatch(setCredentials(result));
      navigate(redirect);
    } catch (error) {
      toast.error(error?.data?.message || 'Registration failed. Please try again.');
    }
  };

  const onRecaptchaChange = (value) => {
    setRecaptchaToken(value);
  };

  return (
    <div className='text-center'>
      <h1>Sign Up</h1>
      <Form onSubmit={handleSubmit}>
        {/* Name Input */}
        <FloatingLabelInput
          id='name'
          type='text'
          name='name'
          value={formData.name}
          onChange={(value) => handleChange('name', value)}
          placeholder='Name'
          isExternallyValid={!formErrors.name}
          errorMessage={formErrors.name}
        />

        {/* Email Input */}
        <FloatingLabelInput
          id='email'
          type='email'
          name='email'
          value={formData.email}
          onChange={(value) => handleChange('email', value)}
          placeholder='Email'
          isExternallyValid={!formErrors.email}
          errorMessage={formErrors.email}
        />

        {/* Phone Input */}
        <FloatingLabelInput
          id='phone'
          type='phone'
          name='phone'
          onChange={(value) => handleChange('phone', value)}
          placeholder='Phone number (optional)'
          isExternallyValid={!formErrors.phone}
          errorMessage={formErrors.phone}
        />

        {/* Password Input */}
        <FloatingLabelInput
          id='password'
          type='password'
          name='password'
          value={formData.password}
          onChange={(value) => handleChange('password', value)}
          placeholder='Password'
          isExternallyValid={!formErrors.password}
          errorMessage={formErrors.password}
        />

        {/* Confirm Password Input */}
        <FloatingLabelInput
          id='confirmPassword'
          type='password'
          name='confirmPassword'
          value={formData.confirmPassword}
          onChange={(value) => handleChange('confirmPassword', value)}
          placeholder='Confirm password'
          isExternallyValid={!formErrors.confirmPassword}
          errorMessage={formErrors.confirmPassword}
        />

        {/* reCAPTCHA */}
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={RECAPTCHA_SITE_KEY}
          onChange={onRecaptchaChange}
          className='d-flex justify-content-center mt-4'
        />

        {/* Submit Button */}
        <button className="action-button" disabled={isLoading} type='submit'>
          Sign Up
        </button>

        {isLoading && <Loader />}
      </Form>
      <div className='py-3'>
        Have an account?{' '}
        <Link className='link-button' to={`/login?redirect=${redirect}`}>
          Log In
        </Link>
      </div>
    </div>
  );
};

export default RegisterScreen;